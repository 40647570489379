import {get,post,upload,delFun,put,replaceUrl,setHeader,setHeaderClear} from './getpost'  
// ==========================================================================
// 生成日期：2022-10-13 14:16:54
// ==========================================================================

// 不同的请求域名依靠replaceUrl来做，第二个参数来做平台标识

function thisReplaceUrl(url){
    return replaceUrl(url,'')
}


let TOOLFORM_SALTGET = "/tool/form_salt/" //通用工具 => 获取表单盐值，盐值有效时间一天


const  teaching_studio_tool = {
    /**
    * 通用工具=>获取表单盐值，盐值有效时间一天
    * @param string computer_code query true 前端生成的6位电脑识别码 
    *
    **/
    ToolForm_saltGet(data,bodyData){
        let url = thisReplaceUrl(TOOLFORM_SALTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('ToolForm_saltGet');
        //相关参数
        getParam.computer_code = data.computer_code  
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

}
export default  teaching_studio_tool;