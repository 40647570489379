const until = {
	setCookie(name, value) {
		var Days = 3650;
		var exp = new Date();
		exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
		document.cookie =
			name + "=" + escape(value) + ";path=/;expires=" + exp.toGMTString();
	},
	getCookie(name) {
		var arr,
			reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
		if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
		else return null;
	},
	delCookie(name) {
		var exp = new Date();
		exp.setTime(exp.getTime() - 1);
		var cval = this.getCookie(name);
		if (cval != null)
			document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
	},
	getParameter(variable) {
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			if (pair[0] == variable) {
				return pair[1];
			}
		}
		return false;
	},
	getNowDayTime() {
		let date = new Date().getTime();
		return date;
	},
	getAddDayTime(num) {
		let date = new Date().getTime() + num * 24 * 60 * 60 * 1000;
		return date;
	},
	guid() {
		function S4() {
			return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
		}
		return S4() + S4();
	},

	getAddDayFirst(num) {
		let date = new Date().getTime() + num * 24 * 60 * 60 * 1000;
		let ymdDate = new Date(date);
		let ym_year = ymdDate.getFullYear();
		let ym_month = ymdDate.getMonth() + 1;
		let ym_day = ymdDate.getDate();
		let firstDate = Date.parse(
			ym_year + "-" + ym_month + "-" + ym_day + " 00:01"
		);
		return firstDate;
	},

	getAddDayLast(num) {
		let date = new Date().getTime() + num * 24 * 60 * 60 * 1000;
		let ymdDate = new Date(date);
		let ym_year = ymdDate.getFullYear();
		let ym_month = ymdDate.getMonth() + 1;
		let ym_day = ymdDate.getDate();

		console.log(ymdDate);
		let firstDate = Date.parse(
			ym_year + "-" + ym_month + "-" + ym_day + " 23:59"
		);
		return firstDate;
	},

	getDayFirst(date) {
		let dateTime = this.formatDate(date) + " 00:01";
		console.log(dateTime);
		return new Date(dateTime);
	},
	getDayLast(date) {
		let dateTime = this.formatDate(date) + " 23:59";
		console.log(dateTime);
		return new Date(dateTime);
	},
	getNowDay() {
		let date = new Date().getTime();
		return this.formatDate(date);
	},
	getAddDay(num) {
		let date = new Date().getTime() + num * 24 * 60 * 60 * 1000;
		return this.formatDate(date);
	},
	getNowWeekBegin() {
		var now = new Date();
		var nowDayOfWeek = now.getDay();
		if (nowDayOfWeek == 0) {
			nowDayOfWeek = 7;
		}
		nowDayOfWeek--;
		console.log(nowDayOfWeek);
		var weekStartDate = this.getAddDay(-nowDayOfWeek);
		return weekStartDate;
	},

	getNewAddDay(oldDate, num) {
		let date = new Date(oldDate).getTime() + num * 24 * 60 * 60 * 1000;
		return this.formatDate(date);
	},
	getYMDNow() {
		let year = new Date().getFullYear();
		let month = new Date().getMonth() + 1;
		let day = new Date().getDate();
		let hour = new Date().getHours();
		let min = new Date().getMinutes();
		if (month < 10) {
			month = "0" + month;
		}
		if (day < 10) {
			day = "0" + day;
		}
		if (hour < 10) {
			hour = "0" + hour;
		}
		if (min < 10) {
			min = "0" + min;
		}
		return year + "-" + month + "-" + day + " " + hour + ":" + min;
	},
	//格式化日期：yyyy-MM-dd
	formatDate(date) {
		date = new Date(date);
		var myyear = date.getFullYear();
		var mymonth = date.getMonth() + 1;
		var myweekday = date.getDate();

		if (mymonth < 10) {
			mymonth = "0" + mymonth;
		}
		if (myweekday < 10) {
			myweekday = "0" + myweekday;
		}
		return myyear + "-" + mymonth + "-" + myweekday;
	},
	formatDateDay(date) {
		date = new Date(date);
		var myyear = date.getFullYear();
		var mymonth = date.getMonth() + 1;
		if (mymonth < 10) {
			mymonth = "0" + mymonth;
		}
		return myyear + "-" + mymonth;
	},
	formatDateMounth(date) {
		date = new Date(date);
		var myyear = date.getFullYear();
		var mymonth = date.getMonth() + 1;
		if (mymonth < 10) {
			mymonth = "0" + mymonth;
		}
		return myyear + "年" + mymonth + '月';
	},
	formatDateHour(date) {
		date = new Date(date);
		var myyear = date.getFullYear();
		var mymonth = date.getMonth() + 1;
		var myweekday = date.getDate();
		var myhour = date.getHours();
		let mymin = date.getMinutes();
		if (mymonth < 10) {
			mymonth = "0" + mymonth;
		}
		if (myweekday < 10) {
			myweekday = "0" + myweekday;
		}
		if (myhour < 10) {
			myhour = "0" + myhour;
		}
		if (mymin < 10) {
			mymin = "0" + mymin;
		}
		return (
			myyear + "-" + mymonth + "-" + myweekday + " " + myhour + ":" + mymin
		);
	},
	formatDateNoYear(date) {
		date = new Date(date);
		var mymonth = date.getMonth() + 1;
		var myweekday = date.getDate();
		var myhour = date.getHours();
		let mymin = date.getMinutes();
		if (mymonth < 10) {
			mymonth = "0" + mymonth;
		}
		if (myweekday < 10) {
			myweekday = "0" + myweekday;
		}
		if (myhour < 10) {
			myhour = "0" + myhour;
		}
		if (mymin < 10) {
			mymin = "0" + mymin;
		}
		return mymonth + "-" + myweekday + " " + myhour + ":" + mymin;
	},
	dateHour(date) {
		date = new Date(date);
		var myyear = date.getFullYear();
		var mymonth = date.getMonth() + 1;
		var myweekday = date.getDate();
		var myhour = date.getHours();
		let mymin = date.getMinutes();
		if (mymonth < 10) {
			mymonth = "0" + mymonth;
		}
		if (myweekday < 10) {
			myweekday = "0" + myweekday;
		}
		if (myhour < 10) {
			myhour = "0" + myhour;
		}
		if (mymin < 10) {
			mymin = "0" + mymin;
		}
		return myhour + ":" + mymin;
	},
	//计算两个时间之间的时间差 多少天时分秒
	intervalTime(startTime, endTime) {
		let timestamp = Date.parse(new Date()) / 1000; //计算当前时间戳 (毫秒级)
		let date1 = ""; //开始时间
		if (timestamp > startTime) {
			date1 = startTime;
		} else {
			date1 = timestamp; //开始时间
		}
		let date2 = endTime; //结束时间
		let date3 = (date2 - date1) * 1000; //时间差的毫秒数

		//计算出相差天数
		let days = Math.floor(date3 / (24 * 3600 * 1000));
		//计算出小时数

		let leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
		let hours = Math.floor(leave1 / (3600 * 1000));
		//计算相差分钟数
		let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
		let minutes = Math.floor(leave2 / (60 * 1000));

		//计算相差秒数

		let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
		let seconds = Math.round(leave3 / 1000);
		return hours + "小时" + minutes + "分" + seconds + "秒";
	},
	// 计算结束日期与当前时间相差天数
	DateDiffer(Date_end) {
		//date1结束时间
		let date1 = new Date(Date_end);
		//date2当前时间
		let date2 = new Date();
		date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
		date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
		const diff = date1.getTime() - date2.getTime(); //目标时间减去当前时间
		return diff / (24 * 60 * 60 * 1000); //计算当前时间与结束时间之间相差天数
	},
	DateMeet(Date_end,Date_begin) {
		//date1结束时间
		let date1 = new Date(Date_end);
		//date2当前时间
		let date2 = new Date(Date_begin);
		date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
		date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
		const diff = date1.getTime() - date2.getTime(); //目标时间减去当前时间
		return diff / (24 * 60 * 60 * 1000); //计算开始时间与结束时间之间相差天数
		
	},
	getTimeNow(time) {
		let time_str = until.formatDateHour(time * 1000)
		var now = new Date();
		var date = new Date(time_str);
		let hour = date.getHours()
		let min = date.getMinutes()
		if (hour < 10) {
			hour = "0" + hour;
		}
		if (min < 10) {
			min = "0" + min;
		}
		//计算时间间隔，单位为分钟
		var inter = parseInt((now.getTime() - date.getTime()) / 1000 / 60);
		if (inter == 0) {
			return "刚刚";
		}
		//多少分钟前
		else if (inter < 60) {
			return inter.toString() + "分钟前";
		}
		//多少小时前
		else if (inter < 60 * 24) {
			return parseInt(inter / 60).toString() + "小时前";
		}
		//本年度内，日期不同，取日期+时间  格式如  06-13 22:11
		else if (now.getFullYear() == date.getFullYear()) {
			return (date.getMonth() + 1).toString() + "-" +
				date.getDate().toString() + " " +
				hour + ":" +
				min;
		}
		else {
			return date.getFullYear().toString().substring(0, 4) + "-" +
				(date.getMonth() + 1).toString() + "-" +
				date.getDate().toString() + " " +
				hour + ":" +
				min;
		}

	},
	getWid() {
		let url = window.location.href.split("#")[0];
		let urlarry = url.split("/");
		let wid = "";
		urlarry.map((res, index) => {
			if (res == "wid") {
				wid = urlarry[index + 1];
			}
		});
		return wid;
	},
	getLocal(voiceData, id, time) {
		var voiceItem = { id: id, time: time };
		if (voiceData == null) {
			var loc = new Array();
			loc.push(voiceItem);
			localStorage.setItem("audioData", JSON.stringify(loc));
		} else {
			voiceData.push(voiceItem);
			localStorage.setItem("audioData", JSON.stringify(voiceData));
			console.log(localStorage.getItem("id"));
		}
	},
	getEditorOption() {
		return {
			modules: {
				toolbar: {
					container: [
						["bold", "italic", "underline"],
						[{ size: ["large","small", "huge"] }],
						// [{'size':  ['10px', '12px', '14px','16px','18px','20px','24px','26px','32px','48px'] }],
						[{ header: [false, 1, 2, 3, 4, 5, 6] }], // 标题
						[{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
						[{ font: ["微软雅黑"] }],
						["image"],
						[{ align: [] }], // 对齐方式
					],
					handlers: {
						image: (value) => {
							if (value) {
								var u = navigator.userAgent;
								var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
								var isAndroid =
									u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端

								if (isAndroid) {
									document.querySelector("#areaupload").click();

								} else {

									$(".editor_div").find("input").trigger("click");

								
								}

							} else {
							}
						},
					},
				},
			},
			placeholder: "请输入内容",
		};
	},
	getErrCode() {
		return [
			"Continue",
			"Switching Protocols",
			"Processing",
			"Early Hints",
			"Unassigned",
			"Multiple Choices",
			"Moved Permanently",
			"Found",
			"See Other",
			"Not Modified",
			"Use Proxy",
			"(Unused)",
			"Temporary Redirect",
			"Permanent Redirect",
			"Unassigned",
			"Bad Request",
			"Unauthorized",
			"Payment Required",
			"Forbidden",
			"Not Found",
			"Method Not Allowed",
			"Not Acceptable",
			"Proxy Authentication Required",
			"Request Timeout",
			"Conflict",
			"Gone",
			"Length Required",
			"Precondition Failed",
			"Content Too Large",
			"URI Too Long",
			"Unsupported Media Type",
			"Range Not Satisfiable",
			"Expectation Failed",
			"(Unused)",
			"Unassigned",
			"Misdirected Request",
			"Unprocessable Content",
			"Locked",
			"Failed Dependency",
			"Too Early",
			"Upgrade Required",
			"Unassigned",
			"Precondition Required",
			"Too Many Requests",
			"Unassigned",
			"Request Header Fields Too Large",
			"Unassigned",
			"Unavailable For Legal Reasons",
			"Unassigned",
			"Internal Server Error",
			"Not Implemented",
			"Bad Gateway",
			"Service Unavailable",
			"Gateway Timeout",
			"HTTP Version Not Supported",
			"Variant Also Negotiates",
			"Insufficient Storage",
			"Loop Detected",
			"Unassigned",
			"Not Extended (OBSOLETED)",
			"Network Authentication Required",
			"Unassigned",
		];
	},
	getNumTypeName() {
		return {
			web_office_send_num: {
				name: "在线协作",
				unit: "篇",
			},
			meeting_num: {
				name: "视频会议数量",
				unit: "次",
			},
			people_num: {
				name: "人员总数",
				unit: "人",
			},

			notice_num: {
				name: "公告数量",
				unit: "条",
			},

			activity_experience_num: {
				name: "活动心得",
				unit: "篇",
			},
			cloud_file_num: {
				name: "云盘文件",
				unit: "个",
			},
			studio_group_num: {
				name: "小组数量",
				unit: "组",
			},
			plan_num: {
				name: "计划总结",
				unit: "篇",
			},
			activity_num: {
				name: "活动总数",
				unit: "次",
			},
			rule_num: {
				name: "规章制度",
				unit: "篇",
			},
			study_note_num: {
				name: "学习笔记",
				unit: "篇",
			},
			study_num: {
				name: "在线学习资源",
				unit: "个",
			},
			live_num: {
				name: "直播讲座",
				unit: "场",
			},
			study_experience_num: {
				name: "在线学习资源心得",
				unit: "篇",
			},
			live_experience_num: {
				name: "讲座心得",
				unit: "篇",
			},
			research_num: {
				name: "科研课题",
				unit: "次",
			},
			cloud_file_share_num: {
				name: "文件共享",
				unit: "个",
			},
			notice_look_num: {
				name: "查看公告",
				unit: "篇",
			},
			research_main_num: {
				name: "主持科研课题",
				unit: "篇",
			},
			activity_main_num: {
				name: "主持活动数",
				unit: "次",
			},
			study_look_num: {
				name: "查看在线资源数量",
				unit: "篇",
			},
			live_look_num: {
				name: "观看直播数量",
				unit: "个",
			},
		};
	},
};
export default until;
