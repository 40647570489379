import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        isbtnShow: false,//暂停按钮的显示
        voice_url: '',
        showAudio: false,
        // 当前进度
        currentTime: 0,
        // 总进度
        allTime: 0,
        roadList:[],
        tabActive:0,
        voiceId:'',
    },
    mutations: {
        updateIsbtnShow: function (state, value) {
            state.isbtnShow = value
        },
        updateVoiveUrl: function (state, value) {
            state.voice_url = value
        },
        updateShowAudio: function (state, value) {
            state.showAudio = value
        },
        updateCurrentTime: function (state, value) {
            state.currentTime = value
        },
        updateAllTime: function (state, value) {
            state.allTime = value
            // console.log(state.allTime)
        },
        updateRoadList: function (state, value) {
            state.roadList = value
        },
        updateActive: function (state, value) {
            state.tabActive = value
        },
        updateVoiceId:function (state, value) {
            state.voiceId = value
        },
    },
    actions: {
    }
});