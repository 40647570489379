import {get,post,upload,delFun,put,replaceUrl,setHeader,setHeaderClear} from './getpost'  
// ==========================================================================
// 生成日期：2023-03-02 14:25:22
// ==========================================================================

// 不同的请求域名依靠replaceUrl来做，第二个参数来做平台标识

function thisReplaceUrl(url){
    return replaceUrl(url,'')
}


let API_NOLOGINWECHATCOULDSELF_CREATEGET = "/api_nologin/wechat/could/self_create" //公众号用户端接口 => 当前公众号是否能够自主注册与创建工作室

let API_NOLOGINLOGINPOSTSMSPOST = "/api_nologin/login/post/sms" //其他服务 => 手机端短信验证码，有效期为10分钟【同一个手机号，60秒内只能发送一次，每个小时5条，一天10条】

let API_NOLOGINWECHATJSSDKPOST = "/api_nologin/wechat/jssdk" //媒体文件上传JSSDK => 上传媒体文件

let API_NOLOGINMEDIAALIYUNFILEUPGET = "/api_nologin/media/aliyun/file/up" //媒体文件上传不需登录 => 阿里云文件直接上传
let API_NOLOGINMEDIAQINIUFILEUPGET = "/api_nologin/media/qiniu/file/up" //媒体文件上传不需登录 => 前端获取七牛上传的Token【废弃】
let API_NOLOGINMEDIAWECHATUPPOST = "/api_nologin/media/wechat/up" //媒体文件上传不需登录 => 微信端上传媒体文件

let API_NOLOGINLOGINUSERVAILDQR_CODEGET = "/api_nologin/login/user/vaild/qr_code" //扫码绑定 => 用户验证他的绑定二维码，此接口只要求用户已经过了网页授权获取openid接口，不要求用户具有登录信息

let API_NOLOGINLOGINPASSPORTPOST = "/api_nologin/login/passport" //用户微信登录 => 账密登录
let API_NOLOGINLOGINWECHAT_PLATFORMGET_URLGET = "/api_nologin/login/wechat_platform/get_url" //用户微信登录 => 微信网页授权登录获取跳转地址
let API_NOLOGINLOGINWECHAT_PLATFORMLOGIN_PASSPORTPOST = "/api_nologin/login/wechat_platform/login_passport" //用户微信登录 => 微信公众号环境下的登录
let API_NOLOGINLOGINWECHAT_PLATFORMOAUTH_REBACKGET = "/api_nologin/login/wechat_platform/oauth_reback" //用户微信登录 => 网页授权回调页面，前端不可调用

let API_NOLOGINLOGINSCAN_CODEGET = "/api_nologin/login/scan_code" //用户扫码登录 => 扫码登录的处理
let API_NOLOGINLOGINWECHAT_QRCODEGETCODEGET = "/api_nologin/login/wechat_qrcode/get/code" //用户扫码登录 => 获取PC登录二维码
let API_NOLOGINLOGINWECHAT_QRCODEISLOGINGET = "/api_nologin/login/wechat_qrcode/is/login" //用户扫码登录 => 获取码是否被扫码登录，通过token来获取，最快2秒一次

let API_NOLOGINLOGINSELFREGISTERUSERPOST = "/api_nologin/login/self/register/user" //用户自我注册 => 用户的自我注册，注意注册前，需要先进行网页授权，密码默认为手机号

let API_NOLOGINLIVE_DOINGHEARTGET = "/api_nologin/live_doing/heart" //直播开放的接口 => 专家维持在线心跳,不管是否在直播中,都需要维持心跳,心跳每10秒一次;确实2次,判断专家下线
let API_NOLOGINLIVE_DOINGQRCODE_INFOGET = "/api_nologin/live_doing/qrcode_info" //直播开放的接口 => 通过sn码获取直播信息
let API_NOLOGINLIVE_DOINGRTCGET = "/api_nologin/live_doing/rtc" //直播开放的接口 => 通过sn码获取RTC信息，因为会设计到uid，这里定义uid为:0
let API_NOLOGINLIVE_DOINGRTMGET = "/api_nologin/live_doing/rtm" //直播开放的接口 => 拉取直播的RTM信息，只要信息正确，总能拉取对应rtm信息，未结束的直播，这里定义uid为:0
let API_NOLOGINLIVE_DOINGSTATUSGET = "/api_nologin/live_doing/status" //直播开放的接口 => 直播讲座的状态

let API_NOLOGINACTIVITY_ASSESSLISTGET = "/api_nologin/activity_assess/list" //考核标准 => 获取考核标准下面的考核细则
let API_NOLOGINACTIVITY_ASSESS_AREALISTGET = "/api_nologin/activity_assess_area/list" //考核标准 => 获取所有的考核标准

let API_NOLOGINSTUDIO_OPENFUNC_DEFINEGET = "/api_nologin/studio_open/func_define" //非登录工作室接口 => 工作室的功能定义列表
let API_NOLOGINSTUDIO_OPENINFOGET = "/api_nologin/studio_open/info" //非登录工作室接口 => 工作室简介
let API_NOLOGINSTUDIO_OPENMEMBERSGET = "/api_nologin/studio_open/members" //非登录工作室接口 => 工作室成员、学员列表，如果工作室的成员是开放的话；这里输出的是成员、学员排列
let API_NOLOGINSTUDIO_OPENSTUDYCLASSGET = "/api_nologin/studio_open/study/class" //非登录工作室接口 => 开放的学习资源分类[由其下是否具有开放的资源计算而来]
let API_NOLOGINSTUDIO_OPENSTUDYINFOGET = "/api_nologin/studio_open/study/info" //非登录工作室接口 => 某个资源的详情
let API_NOLOGINSTUDIO_OPENSTUDYLISTGET = "/api_nologin/studio_open/study/list" //非登录工作室接口 => 某个分类下面的资源列表
let API_NOLOGINSTUDIO_OPENUSERINFOGET = "/api_nologin/studio_open/user/info" //非登录工作室接口 => 工作室、专家、主持人的工作室介绍
let API_NOLOGINSTUDIO_OPENUSERLISTGET = "/api_nologin/studio_open/user/list" //非登录工作室接口 => 工作室的主持人，专家


const  teaching_studio_api_nologin = {

    /**
    * 公众号用户端接口=>当前公众号是否能够自主注册与创建工作室
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Api_nologinWechatCouldSelf_createGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINWECHATCOULDSELF_CREATEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinWechatCouldSelf_createGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 其他服务=>手机端短信验证码，有效期为10分钟【同一个手机号，60秒内只能发送一次，每个小时5条，一天10条】
    * @param string app_id query true 公众号的APPID 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string phone_number formData true 二维码的Token 
    *
    **/
    Api_nologinLoginPostSmsPost(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINLOGINPOSTSMSPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinLoginPostSmsPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.phone_number  = data.phone_number    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 媒体文件上传JSSDK=>上传媒体文件
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string url formData true 需要验证的路由地址 
    *
    **/
    Api_nologinWechatJssdkPost(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINWECHATJSSDKPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinWechatJssdkPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.url  = data.url    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 媒体文件上传不需登录=>微信端上传媒体文件
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string media_id formData true 资源ID 
    * @param boolean is_cloud formData true 是否时云盘文件；true|false 
    * @param string media_type formData true 图片=>img,视屏=>video,语音=>voice 
    *
    **/
    Api_nologinMediaWechatUpPost(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINMEDIAWECHATUPPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinMediaWechatUpPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.media_id  = data.media_id    
        postParam.is_cloud  = data.is_cloud    
        postParam.media_type  = data.media_type    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 媒体文件上传不需登录=>阿里云文件直接上传
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Api_nologinMediaAliyunFileUpGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINMEDIAALIYUNFILEUPGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinMediaAliyunFileUpGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 媒体文件上传不需登录=>前端获取七牛上传的Token【废弃】
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Api_nologinMediaQiniuFileUpGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINMEDIAQINIUFILEUPGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinMediaQiniuFileUpGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 扫码绑定=>用户验证他的绑定二维码，此接口只要求用户已经过了网页授权获取openid接口，不要求用户具有登录信息
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string token query true 二维码的Token 
    *
    **/
    Api_nologinLoginUserVaildQr_codeGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINLOGINUSERVAILDQR_CODEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinLoginUserVaildQr_codeGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.token = data.token    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户微信登录=>账密登录
    * @param string app_id query true 公众号的APPID 
    * @param string passport formData true 账号 
    * @param string md5_password formData true 进行了md5后的密码 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    *
    **/
    Api_nologinLoginPassportPost(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINLOGINPASSPORTPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinLoginPassportPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.passport  = data.passport    
        postParam.md5_password  = data.md5_password    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 用户微信登录=>微信公众号环境下的登录
    * @param string app_id query true 公众号的APPID 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string passport formData true 账号 
    * @param string name formData true 用户姓名 
    *
    **/
    Api_nologinLoginWechat_platformLogin_passportPost(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINLOGINWECHAT_PLATFORMLOGIN_PASSPORTPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinLoginWechat_platformLogin_passportPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.passport  = data.passport    
        postParam.name  = data.name    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 用户微信登录=>微信网页授权登录获取跳转地址
    * @param string app_id query true 公众号APPID 
    *
    **/
    Api_nologinLoginWechat_platformGet_urlGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINLOGINWECHAT_PLATFORMGET_URLGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinLoginWechat_platformGet_urlGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户微信登录=>网页授权回调页面，前端不可调用
    * @param string state query true 公众号APPID 
    *
    **/
    Api_nologinLoginWechat_platformOauth_rebackGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINLOGINWECHAT_PLATFORMOAUTH_REBACKGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinLoginWechat_platformOauth_rebackGet');
        //相关参数
  
        getParam.state = data.state    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户扫码登录=>扫码登录的处理
    * @param string app_id query true 公众号的APPID 
    * @param string token query true 登录的TOKEN 
    *
    **/
    Api_nologinLoginScan_codeGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINLOGINSCAN_CODEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinLoginScan_codeGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.token = data.token    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户扫码登录=>获取码是否被扫码登录，通过token来获取，最快2秒一次
    * @param string app_id query true 公众号的APPID 
    * @param string token query true token值 
    *
    **/
    Api_nologinLoginWechat_qrcodeIsLoginGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINLOGINWECHAT_QRCODEISLOGINGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinLoginWechat_qrcodeIsLoginGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.token = data.token    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户扫码登录=>获取PC登录二维码
    * @param string app_id query true 公众号的APPID 
    *
    **/
    Api_nologinLoginWechat_qrcodeGetCodeGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINLOGINWECHAT_QRCODEGETCODEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinLoginWechat_qrcodeGetCodeGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户自我注册=>用户的自我注册，注意注册前，需要先进行网页授权，密码默认为手机号
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string avatar formData true 注册的头像 
    * @param string name formData true 用户姓名 
    * @param string sex formData true 用户性别[male,female] 
    * @param string phone_number formData true 手机号码 
    * @param string code formData true 手机验证码 
    *
    **/
    Api_nologinLoginSelfRegisterUserPost(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINLOGINSELFREGISTERUSERPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinLoginSelfRegisterUserPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.avatar  = data.avatar    
        postParam.name  = data.name    
        postParam.sex  = data.sex    
        postParam.phone_number  = data.phone_number    
        postParam.code  = data.code    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 直播开放的接口=>通过sn码获取直播信息
    * @param string invitation_code query true 二维码值 
    *
    **/
    Api_nologinLive_doingQrcode_infoGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINLIVE_DOINGQRCODE_INFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinLive_doingQrcode_infoGet');
        //相关参数
  
        getParam.invitation_code = data.invitation_code    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 直播开放的接口=>专家维持在线心跳,不管是否在直播中,都需要维持心跳,心跳每10秒一次;确实2次,判断专家下线
    * @param string invitation_code query true 二维码值 
    *
    **/
    Api_nologinLive_doingHeartGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINLIVE_DOINGHEARTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinLive_doingHeartGet');
        //相关参数
  
        getParam.invitation_code = data.invitation_code    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 直播开放的接口=>通过sn码获取RTC信息，因为会设计到uid，这里定义uid为:0
    * @param string invitation_code query true 二维码值 
    *
    **/
    Api_nologinLive_doingRtcGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINLIVE_DOINGRTCGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinLive_doingRtcGet');
        //相关参数
  
        getParam.invitation_code = data.invitation_code    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 直播开放的接口=>拉取直播的RTM信息，只要信息正确，总能拉取对应rtm信息，未结束的直播，这里定义uid为:0
    * @param string invitation_code query true 二维码值 
    *
    **/
    Api_nologinLive_doingRtmGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINLIVE_DOINGRTMGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinLive_doingRtmGet');
        //相关参数
  
        getParam.invitation_code = data.invitation_code    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 直播开放的接口=>直播讲座的状态
    * @param string invitation_code query true 二维码值 
    *
    **/
    Api_nologinLive_doingStatusGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINLIVE_DOINGSTATUSGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinLive_doingStatusGet');
        //相关参数
  
        getParam.invitation_code = data.invitation_code    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 考核标准=>获取所有的考核标准
    *
    **/
    Api_nologinActivity_assess_areaListGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINACTIVITY_ASSESS_AREALISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinActivity_assess_areaListGet');
        //相关参数
  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 考核标准=>获取考核标准下面的考核细则
    * @param integer area_id query true 标准id 
    *
    **/
    Api_nologinActivity_assessListGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINACTIVITY_ASSESSLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinActivity_assessListGet');
        //相关参数
  
        getParam.area_id = data.area_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 非登录工作室接口=>工作室、专家、主持人的工作室介绍
    * @param integer studio_id query true 工作室ID 
    * @param integer user_id query true 人员ID 
    *
    **/
    Api_nologinStudio_openUserInfoGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINSTUDIO_OPENUSERINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinStudio_openUserInfoGet');
        //相关参数
  
        getParam.studio_id = data.studio_id    
        getParam.user_id = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 非登录工作室接口=>某个资源的详情
    * @param integer studio_id query true 工作室ID 
    * @param integer study_id query true 资源ID 
    *
    **/
    Api_nologinStudio_openStudyInfoGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINSTUDIO_OPENSTUDYINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinStudio_openStudyInfoGet');
        //相关参数
  
        getParam.studio_id = data.studio_id    
        getParam.study_id = data.study_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 非登录工作室接口=>工作室简介
    * @param string app_id query true 公众号 
    * @param integer id query false 工作室ID 
    *
    **/
    Api_nologinStudio_openInfoGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINSTUDIO_OPENINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinStudio_openInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.id = typeof(data.id) !="undefined" ?  data.id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 非登录工作室接口=>工作室的主持人，专家
    * @param string app_id query true 公众号 
    * @param integer studio_id query false 工作室ID 
    *
    **/
    Api_nologinStudio_openUserListGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINSTUDIO_OPENUSERLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinStudio_openUserListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.studio_id = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 非登录工作室接口=>某个分类下面的资源列表
    * @param integer studio_id query true 工作室ID 
    * @param integer classes_id query true 分类id 
    *
    **/
    Api_nologinStudio_openStudyListGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINSTUDIO_OPENSTUDYLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinStudio_openStudyListGet');
        //相关参数
  
        getParam.studio_id = data.studio_id    
        getParam.classes_id = data.classes_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 非登录工作室接口=>工作室成员、学员列表，如果工作室的成员是开放的话；这里输出的是成员、学员排列
    * @param string app_id query true 公众号 
    * @param integer id query true 其他工作室ID 
    *
    **/
    Api_nologinStudio_openMembersGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINSTUDIO_OPENMEMBERSGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinStudio_openMembersGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 非登录工作室接口=>工作室的功能定义列表
    * @param integer studio_id query true 工作室ID 
    *
    **/
    Api_nologinStudio_openFunc_defineGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINSTUDIO_OPENFUNC_DEFINEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinStudio_openFunc_defineGet');
        //相关参数
  
        getParam.studio_id = data.studio_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 非登录工作室接口=>开放的学习资源分类[由其下是否具有开放的资源计算而来]
    * @param integer studio_id query true 工作室ID 
    *
    **/
    Api_nologinStudio_openStudyClassGet(data,bodyData){
        let url = thisReplaceUrl(API_NOLOGINSTUDIO_OPENSTUDYCLASSGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Api_nologinStudio_openStudyClassGet');
        //相关参数
  
        getParam.studio_id = data.studio_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

}
export default  teaching_studio_api_nologin;