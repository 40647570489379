import {get,post,upload,delFun,put,replaceUrl,setHeader,setHeaderClear} from './getpost'  
// ==========================================================================
// 生成日期：2023-05-29 21:54:14
// ==========================================================================

// 不同的请求域名依靠replaceUrl来做，第二个参数来做平台标识

function thisReplaceUrl(url){
    return replaceUrl(url,'')
}


let NOT_STUDIOSELF_STUDIOCREATEPOST = "/not_studio/self_studio/create" // => 新增工作室接口，一个最多在5个工作室内，自建的默认为免费版
let NOT_STUDIOSELF_STUDIOLOGINPOST = "/not_studio/self_studio/login" // => 登入某个工作室
let TEACHING_STUDIO_APIWEB_OFFICEWORKREFRESHPOST = "/teaching_studio_api/web_office/work/refresh" // => 在线协作文件的token有效期刷新

let IOT_AUTOCLOUD_FILEADDPOST = "/iot_auto/cloud_file/add" //云盘文件 => 新增
let IOT_AUTOCLOUD_FILEDELDELETE = "/iot_auto/cloud_file/del" //云盘文件 => 删除
let IOT_AUTOCLOUD_FILEINFOGET = "/iot_auto/cloud_file/info" //云盘文件 => 获取详情
let IOT_AUTOCLOUD_FILELISTGET = "/iot_auto/cloud_file/list" //云盘文件 => 获取列表
let IOT_AUTOCLOUD_FILEUPDATEPOST = "/iot_auto/cloud_file/update" //云盘文件 => 更新
let TEACHING_STUDIO_APICLOUD_FILEADDPOST = "/teaching_studio_api/cloud_file/add" //云盘文件 => 添加云盘文件，工作室内所有人有此权限
let TEACHING_STUDIO_APICLOUD_FILEDELDELETE = "/teaching_studio_api/cloud_file/del" //云盘文件 => 删除云盘文件，创建人有此权限
let TEACHING_STUDIO_APICLOUD_FILEFILEUPPOST = "/teaching_studio_api/cloud_file/file/up" //云盘文件 => 更新云盘文件：名称、文件类型，创建人有此权限
let TEACHING_STUDIO_APICLOUD_FILEOPENUPPOST = "/teaching_studio_api/cloud_file/open/up" //云盘文件 => 更新云盘文件开放状态：yes=>开放，no=>不开放，创建人有此权限
let TEACHING_STUDIO_APICLOUD_FILESTUDIOMINEPAGEGET = "/teaching_studio_api/cloud_file/studio/mine/page" //云盘文件 => 获取工作室内云盘文件分页列表(我自己的)，创建人有此权限
let TEACHING_STUDIO_APICLOUD_FILESTUDIOOPENPAGEGET = "/teaching_studio_api/cloud_file/studio/open/page" //云盘文件 => 获取系统云盘文件分页列表(开放的)，工作室内成员有此权限
let TEACHING_STUDIO_APICLOUD_FILEWEB_OFFICEGET = "/teaching_studio_api/cloud_file/web_office" //云盘文件 => 我的云盘里的文件发起了在线协作的文件，这里的对应的删除，只能删除协作记录

let TEACHING_STUDIO_APIMEETINGPEOPLEALLGET = "/teaching_studio_api/meeting/people/all" //会议人员 => 获取会议人员列表,会议中的人具有此权限
let TEACHING_STUDIO_APIMEETINGPEOPLECAN_VIDEOPUT = "/teaching_studio_api/meeting/people/can_video" //会议人员 => 更新会议人员是否可以视屏通话,主持人、创建人、会议主持人拥有此权限
let TEACHING_STUDIO_APIMEETINGPEOPLECAN_VOICEPUT = "/teaching_studio_api/meeting/people/can_voice" //会议人员 => 更新用户在会议里的权限：是否可以语音通话；主持人、创建人、会议主持人可以操作；其中自己可以关闭的语音通话
let TEACHING_STUDIO_APIMEETINGPEOPLEDELDELETE = "/teaching_studio_api/meeting/people/del" //会议人员 => 剔出会议中的一个人员,主持人、创建人、会议主持人拥有此权限
let TEACHING_STUDIO_APIMEETINGPEOPLEHEARTPUT = "/teaching_studio_api/meeting/people/heart" //会议人员 => 用户的在线心跳(20秒一次)；要求会议未处于结束状态
let TEACHING_STUDIO_APIMEETINGPEOPLEINMEETINGPUT = "/teaching_studio_api/meeting/people/in/meeting" //会议人员 => 人员进入会议
let TEACHING_STUDIO_APIMEETINGPEOPLEINVITEGET = "/teaching_studio_api/meeting/people/invite" //会议人员 => 获取邀请人员加入的链接，因为都是在微信里，应该是要生成微信的分享链接；同时也具备提醒进入会议的功能
let TEACHING_STUDIO_APIMEETINGPEOPLEINVITEINGET = "/teaching_studio_api/meeting/people/invite/in" //会议人员 => 通过邀请进入会议
let TEACHING_STUDIO_APIMEETINGPEOPLEOUTMEETINGPUT = "/teaching_studio_api/meeting/people/out/meeting" //会议人员 => 人员离开会议
let TEACHING_STUDIO_APIMEETINGPEOPLESTATUSGET = "/teaching_studio_api/meeting/people/status" //会议人员 => 获取会议内的人员状态[起码得有状态，虽是会议人员但是没有进入过不会有状态][人员列表，是否可以语音、视频，是否在线]最多30秒一次

let TEACHING_STUDIO_APISTUDYCLASSESADDPOST = "/teaching_studio_api/study/classes/add" //内部的、工作室的、学习资源 => 新增资源分类，主持人可以操作
let TEACHING_STUDIO_APISTUDYCLASSESDELDELETE = "/teaching_studio_api/study/classes/del" //内部的、工作室的、学习资源 => 删除资源分类，主持人和发布人可以操作并且其下没有资源时才可以操作
let TEACHING_STUDIO_APISTUDYCLASSESINFOGET = "/teaching_studio_api/study/classes/info" //内部的、工作室的、学习资源 => 资源详情，工作室开放
let TEACHING_STUDIO_APISTUDYCLASSESLISTGET = "/teaching_studio_api/study/classes/list" //内部的、工作室的、学习资源 => 学习资源分类列表，经过排序的
let TEACHING_STUDIO_APISTUDYCLASSESSORTPOST = "/teaching_studio_api/study/classes/sort" //内部的、工作室的、学习资源 => 更新排序，主持人可以操作
let TEACHING_STUDIO_APISTUDYCLASSESSTUDYLISTGET = "/teaching_studio_api/study/classes/study/list" //内部的、工作室的、学习资源 => 分类下所有的学习资源，按照发布时间排序的
let TEACHING_STUDIO_APISTUDYCLASSESUPDATEPOST = "/teaching_studio_api/study/classes/update" //内部的、工作室的、学习资源 => 更新资源分类，主持人和发布人可以操作
let TEACHING_STUDIO_APISTUDYDELEXPERIENCEDELETE = "/teaching_studio_api/study/del/experience" //内部的、工作室的、学习资源 => 删除一次心得,could delete self send;host can delete anyone send
let TEACHING_STUDIO_APISTUDYEXPERIENCERECORDGET = "/teaching_studio_api/study/experience/record" //内部的、工作室的、学习资源 => 某条心得的详情
let TEACHING_STUDIO_APISTUDYINFOADDPOST = "/teaching_studio_api/study/info/add" //内部的、工作室的、学习资源 => 新增一条学习资源，工作室成员都可以添加
let TEACHING_STUDIO_APISTUDYINFOCHANGE_CLASSESPOST = "/teaching_studio_api/study/info/change_classes" //内部的、工作室的、学习资源 => 更新资源的所属分类，主持人和发布人
let TEACHING_STUDIO_APISTUDYINFODELDELETE = "/teaching_studio_api/study/info/del" //内部的、工作室的、学习资源 => 删除一条学习资源，主持人与发布人，会删除签到记录与心得记录
let TEACHING_STUDIO_APISTUDYINFOEXPERIENCEGET = "/teaching_studio_api/study/info/experience" //内部的、工作室的、学习资源 => 某个资源的工作室成员的心得
let TEACHING_STUDIO_APISTUDYINFOGET = "/teaching_studio_api/study/info" //内部的、工作室的、学习资源 => 资源详情，并且会记录一次查看记录
let TEACHING_STUDIO_APISTUDYINFOOPEN_CLOSEPOST = "/teaching_studio_api/study/info/open_close" //内部的、工作室的、学习资源 => 资源开放，主持人和发布人
let TEACHING_STUDIO_APISTUDYINFOUPDATEPOST = "/teaching_studio_api/study/info/update" //内部的、工作室的、学习资源 => 更新一条学习资源，主持人和发布人
let TEACHING_STUDIO_APISTUDYINFOVIEW_RECORDGET = "/teaching_studio_api/study/info/view_record" //内部的、工作室的、学习资源 => 某个资源的工作室成员的查看记录
let TEACHING_STUDIO_APISTUDYNEWESTGET = "/teaching_studio_api/study/newest" //内部的、工作室的、学习资源 => 最新的资源，按照发布时间的降序排序
let TEACHING_STUDIO_APISTUDYSENDEXPERIENCEPOST = "/teaching_studio_api/study/send/experience" //内部的、工作室的、学习资源 => 发布一次心得
let TEACHING_STUDIO_APISTUDYUPDATEEXPERIENCEPOST = "/teaching_studio_api/study/update/experience" //内部的、工作室的、学习资源 => 更新一次心得,could update self send;host can update anyone send

let TEACHING_STUDIO_APIWEB_OFFICEDOCDELETE = "/teaching_studio_api/web_office/doc" //在线office => 删除一个协作文档，注意只会删除协作记录功能，不会对对应的云盘文件或者在线文件进行删除；注意只能删除我发起的文档
let TEACHING_STUDIO_APIWEB_OFFICEINFOGET = "/teaching_studio_api/web_office/info" //在线office => 某个协作文档的详细情况，主持人可看所有的，组长可看组内的，组员可以看参与的
let TEACHING_STUDIO_APIWEB_OFFICEINLISTGET = "/teaching_studio_api/web_office/in/list" //在线office => 用户参与过的协作文档
let TEACHING_STUDIO_APIWEB_OFFICELISTGET = "/teaching_studio_api/web_office/list" //在线office => **暂时不用**用户可见的在线协作文档，不一定参与了，只是权限范围内可见；主持人可见工作室全部的，组长可见组内全部的自己参与的，组员只能看见自己参与的
let TEACHING_STUDIO_APIWEB_OFFICEPREVIEWPOST = "/teaching_studio_api/web_office/preview" //在线office => 微信端上传媒体文件，支持的格式：et、xls、xlt、xlsx、xlsm、xltx、xltm、csv文字文件：doc、docx、txt、dot、wps、wpt、dotx、docm、dotm、rtf 演示文件：ppt、pptx、pptm、ppsx、ppsm、pps、potx、potm、dpt、dps；PDF格式文件：pdf
let TEACHING_STUDIO_APIWEB_OFFICEPREVIEWREFRESHPOST = "/teaching_studio_api/web_office/preview/refresh" //在线office => 在线浏览文件的token有效期刷新
let TEACHING_STUDIO_APIWEB_OFFICEUSERINPOST = "/teaching_studio_api/web_office/user/in" //在线office => 登记用户加入在线文档的协作；得是本次文档协作的发起者才能邀请
let TEACHING_STUDIO_APIWEB_OFFICEUSERLISTGET = "/teaching_studio_api/web_office/user/list" //在线office => 在线协作，文档的用户，参与的用户可见
let TEACHING_STUDIO_APIWEB_OFFICEWORKINDOCGET = "/teaching_studio_api/web_office/workin/doc" //在线office => 获取文档的工作状态
let TEACHING_STUDIO_APIWEB_OFFICEWORKINHEARTGET = "/teaching_studio_api/web_office/workin/heart" //在线office => 在线协作时打开office的工作心跳
let TEACHING_STUDIO_APIWEB_OFFICEWORKPOST = "/teaching_studio_api/web_office/work" //在线office => 以在线协作的方式打开一文件；pdf文件仅支持预览，不支持编辑，需要将Permission字段中的Readonly设置为true。目前支持的文件类型有：表格文件：et、xls、xlt、xlsx、xlsm、xltx、xltm、csv；文字文件：doc、docx、txt、dot、wps、wpt、dotx、docm、dotm、rtf；演示文件：ppt、pptx、pptm、ppsx、ppsm、pps、potx、potm、dpt、dps；pdf文件：pdf

let TEACHING_STUDIO_APIMEDIAALIYUNFILECLOUD_UPGET = "/teaching_studio_api/media/aliyun/file/cloud_up" //媒体文件上传 => 阿里云网盘文件直接上传
let TEACHING_STUDIO_APIMEDIAALIYUNFILEUPGET = "/teaching_studio_api/media/aliyun/file/up" //媒体文件上传 => 阿里云文件直接上传
let TEACHING_STUDIO_APIMEDIAQINIUFILEUPGET = "/teaching_studio_api/media/qiniu/file/up" //媒体文件上传 => 前端获取七牛上传的Token【废弃】
let TEACHING_STUDIO_APIMEDIAWECHATUPPOST = "/teaching_studio_api/media/wechat/up" //媒体文件上传 => 微信端上传媒体文件

let IOT_AUTOLEARN_NOTEADDPOST = "/iot_auto/learn_note/add" //学习笔记 => 新增
let IOT_AUTOLEARN_NOTEDELDELETE = "/iot_auto/learn_note/del" //学习笔记 => 删除
let IOT_AUTOLEARN_NOTEINFOGET = "/iot_auto/learn_note/info" //学习笔记 => 获取详情
let IOT_AUTOLEARN_NOTELISTGET = "/iot_auto/learn_note/list" //学习笔记 => 获取列表
let IOT_AUTOLEARN_NOTEUPDATEPOST = "/iot_auto/learn_note/update" //学习笔记 => 更新
let TEACHING_STUDIO_APILEARN_NOTEADDPOST = "/teaching_studio_api/learn_note/add" //学习笔记 => 添加学习笔记，工作室内成员有此权限
let TEACHING_STUDIO_APILEARN_NOTEDELDELETE = "/teaching_studio_api/learn_note/del" //学习笔记 => 删除学习笔记，创建人有此权限
let TEACHING_STUDIO_APILEARN_NOTEFILEUPPOST = "/teaching_studio_api/learn_note/file/up" //学习笔记 => 更新关联的云盘文件，创建人有此权限
let TEACHING_STUDIO_APILEARN_NOTEINFOGET = "/teaching_studio_api/learn_note/info" //学习笔记 => 笔记详情，工作室开放
let TEACHING_STUDIO_APILEARN_NOTEINFOUPPOST = "/teaching_studio_api/learn_note/info/up" //学习笔记 => 更新笔记内容：内容，媒体文件，创建人有此权限
let TEACHING_STUDIO_APILEARN_NOTEMINEPAGEGET = "/teaching_studio_api/learn_note/mine/page" //学习笔记 => 获取我的学习笔记分页列表，创建人有此权限
let TEACHING_STUDIO_APILEARN_NOTESTUDIOPAGEGET = "/teaching_studio_api/learn_note/studio/page" //学习笔记 => 获取工作室内学习笔记分页列表，工作室内成员有此权限

let TEACHING_STUDIOSTUDIOSADDPOST = "/teaching_studio/studios/add" //工作室 => 新增
let TEACHING_STUDIOSTUDIOSDELDELETE = "/teaching_studio/studios/del" //工作室 => 删除
let TEACHING_STUDIOSTUDIOSINFOGET = "/teaching_studio/studios/info" //工作室 => 获取详情
let TEACHING_STUDIOSTUDIOSLISTGET = "/teaching_studio/studios/list" //工作室 => 获取列表
let TEACHING_STUDIOSTUDIOSUPDATEPUT = "/teaching_studio/studios/update" //工作室 => 更新
let TEACHING_STUDIO_APISTUDIODEVICE_PREFIXPUT = "/teaching_studio_api/studio/device_prefix" //工作室 => 更新工作室设备前缀，主持人有权限

let TEACHING_STUDIO_APIUSER_EXPERTADDPOST = "/teaching_studio_api/user_expert/add" //工作室专家 => 为工作室新增一个专家，主持人权限
let TEACHING_STUDIO_APIUSER_EXPERTAVATARPOST = "/teaching_studio_api/user_expert/avatar" //工作室专家 => 专家头像修改，主持人权限
let TEACHING_STUDIO_APIUSER_EXPERTNAMEPOST = "/teaching_studio_api/user_expert/name" //工作室专家 => 专家姓名修改，主持人权限
let TEACHING_STUDIO_APIUSER_EXPERTSORTPOST = "/teaching_studio_api/user_expert/sort" //工作室专家 => 修改专家的排序，主持人权限
let TEACHING_STUDIO_APIUSER_EXPERTSTUDIO_INTROPOST = "/teaching_studio_api/user_expert/studio_intro" //工作室专家 => 专家工作室介绍，主持人权限

let TEACHING_STUDIO_APINOTICEADDPOST = "/teaching_studio_api/notice/add" //工作室公告，公告分为整个工作室，或者小组 => 新增一个公告，主持人与小组长的权限
let TEACHING_STUDIO_APINOTICEDELDELETE = "/teaching_studio_api/notice/del" //工作室公告，公告分为整个工作室，或者小组 => 删除一个公告，主持人和发布人的权限
let TEACHING_STUDIO_APINOTICEEDITPOST = "/teaching_studio_api/notice/edit" //工作室公告，公告分为整个工作室，或者小组 => 编辑一个公告，不能改变可见范围，主持人和发布人的权限
let TEACHING_STUDIO_APINOTICEINFOGET = "/teaching_studio_api/notice/info" //工作室公告，公告分为整个工作室，或者小组 => 小组的，用户可见的公告列表，主持人可见全部，其他人员可见自己小组和全体用户的
let TEACHING_STUDIO_APINOTICESTUDIOLISTGET = "/teaching_studio_api/notice/studio/list" //工作室公告，公告分为整个工作室，或者小组 => 工作室的，用户可见的公告列表，主持人可见全部，其他人员可见自己小组和全体用户的,活动主持人和发布人可以操作
let TEACHING_STUDIO_APINOTICEUSERGET = "/teaching_studio_api/notice/user" //工作室公告，公告分为整个工作室，或者小组 => 可见公告的人可见，公告的发送用户对象
let TEACHING_STUDIO_APINOTICEVIEW_RECORDGET = "/teaching_studio_api/notice/view_record" //工作室公告，公告分为整个工作室，或者小组 => 可见公告的人可见，公告的查阅记录

let TEACHING_STUDIO_APINOTICETEMPLATE_MSGGET = "/teaching_studio_api/notice/template_msg" //工作室公告，公告分为整个工作室，或者小组d => 模板消息的发送情况

let TEACHING_STUDIO_APIMEMBERGROUPCOUNTGET = "/teaching_studio_api/member/group/count" //工作室成员 => 工作室里组的相关统计，一次一个组
let TEACHING_STUDIO_APIMEMBERGROUPGET = "/teaching_studio_api/member/group" //工作室成员 => 工作室里的组
let TEACHING_STUDIO_APIMEMBERGROUPINFOGET = "/teaching_studio_api/member/group/info" //工作室成员 => 工作室里的组，某个组的信息
let TEACHING_STUDIO_APIMEMBERGROUPUSERGET = "/teaching_studio_api/member/group/user" //工作室成员 => 工作室某个组里的组员；包含组长以及组员，会输出用户最后年度的考核信息
let TEACHING_STUDIO_APIMEMBERGROWUPDOCGET = "/teaching_studio_api/member/growup/doc" //工作室成员 => 个人的成长档案、主要是在系统的活动的信息，包含：签到记录、心得记录、线上学习记录、线上学习心得记录、考核记录
let TEACHING_STUDIO_APIMEMBERHOST_EXPERTGET = "/teaching_studio_api/member/host_expert" //工作室成员 => 主持人、专家、信息
let TEACHING_STUDIO_APIMEMBERINFOGET = "/teaching_studio_api/member/info" //工作室成员 => 个人档案信息，不包含成长档案，工作室成员可看
let TEACHING_STUDIO_APIMEMBERLOGIN_USERGET = "/teaching_studio_api/member/login_user" //工作室成员 => 当前登录用户的信息，也就是我的详细信息，不包含成长档案
let TEACHING_STUDIO_APIMEMBERUSERADDPOST = "/teaching_studio_api/member/user/add" //工作室成员 => 为工作室新增一个用户(用户可以在其他工作内已经存在)，这里只需要基础信息，主持人操作；默认身份为学员，在操作组的时候会根据他在组里的身份下进行联动改变；组长可以加人，组长加完人后，此用户会默认在此组内
let TEACHING_STUDIO_APIMEMBERUSERINTRODUCE_IMGPOST = "/teaching_studio_api/member/user/introduce_img" //工作室成员 => 更新用户的工作室的简介照片，自己和主持人可以操作
let TEACHING_STUDIO_APIMEMBERUSERINTRODUCE_TEXTPOST = "/teaching_studio_api/member/user/introduce_text" //工作室成员 => 更新用户的工作室的简介文本，自己和主持人可以操作
let TEACHING_STUDIO_APIMEMBERUSERLISTGET = "/teaching_studio_api/member/user/list" //工作室成员 => 工作室的非专家之外的用户列表
let TEACHING_STUDIO_APIMEMBERUSERNO_GROUPGET = "/teaching_studio_api/member/user/no_group" //工作室成员 => 没有组的工作室成员（不包含主持人），全体成员可看
let TEACHING_STUDIO_APIMEMBERUSEROUTDELETE = "/teaching_studio_api/member/user/out" //工作室成员 => 成员踢出，注意小组长不能踢出，主持人操作，踢出时同时也会踢出组
let TEACHING_STUDIO_APIMEMBERUSERQR_CODEPOST = "/teaching_studio_api/member/user/qr_code" //工作室成员 => 要求新用户绑定的二维码，主持人可以见所有人的，组长可以见组员的
let TEACHING_STUDIO_APIMEMBERUSERWECHATDELETE = "/teaching_studio_api/member/user/wechat" //工作室成员 => 解绑某个用户的微信操作，主持人操作
let TEACHING_STUDIO_APIUSERUPDATEAVATARPOST = "/teaching_studio_api/user/update/avatar" //工作室成员 => 更新自己的头像
let TEACHING_STUDIO_APIUSERUPDATEAWARDSPOST = "/teaching_studio_api/user/update/awards" //工作室成员 => 更新获奖记录
let TEACHING_STUDIO_APIUSERUPDATEOTHER_INTROPOST = "/teaching_studio_api/user/update/other_intro" //工作室成员 => 其他信息的更新
let TEACHING_STUDIO_APIUSERUPDATEPASSWORDPOST = "/teaching_studio_api/user/update/password" //工作室成员 => 更新自己的登录密码；自己以及主持人操作
let TEACHING_STUDIO_APIUSERUPDATEREAL_NAMEPOST = "/teaching_studio_api/user/update/real_name" //工作室成员 => 更新自己的姓名
let TEACHING_STUDIO_APIUSERUPDATESEXPOST = "/teaching_studio_api/user/update/sex" //工作室成员 => 更新自己的性别
let TEACHING_STUDIO_APIUSERUPDATETELPHONEPOST = "/teaching_studio_api/user/update/telphone" //工作室成员 => 更新自己的电话号码
let TEACHING_STUDIO_APIUSERUPDATEWORK_INTROPOST = "/teaching_studio_api/user/update/work_intro" //工作室成员 => 工作内容简要介绍
let TEACHING_STUDIO_APIUSERUPDATEWORK_UNITPOST = "/teaching_studio_api/user/update/work_unit" //工作室成员 => 更新自己的工作单位

let TEACHING_STUDIO_APISTUDIOACTIVITY_ASSESSLISTGET = "/teaching_studio_api/studio/activity_assess/list" //工作室本身相关接口 => 当前工作室的考核细则列表，工作室人员可以获取，可能返回为空
let TEACHING_STUDIO_APISTUDIOACTIVITY_ASSESS_AREAPOST = "/teaching_studio_api/studio/activity_assess_area" //工作室本身相关接口 => 修改工作室的考核标准
let TEACHING_STUDIO_APISTUDIOBANNERPOST = "/teaching_studio_api/studio/banner" //工作室本身相关接口 => 修改Banner
let TEACHING_STUDIO_APISTUDIODISPLAY_CLASSADDPOST = "/teaching_studio_api/studio/display_class/add" //工作室本身相关接口 => 新增一个显示分类，主持人权限
let TEACHING_STUDIO_APISTUDIODISPLAY_CLASSDELDELETE = "/teaching_studio_api/studio/display_class/del" //工作室本身相关接口 => 删除一个显示分类，主持人权限
let TEACHING_STUDIO_APISTUDIODISPLAY_CLASSUPDATEPUT = "/teaching_studio_api/studio/display_class/update" //工作室本身相关接口 => 更新一个显示分类，主持人权限
let TEACHING_STUDIO_APISTUDIODISPLAY_LISTALL_FUNCGET = "/teaching_studio_api/studio/display_list/all_func" //工作室本身相关接口 => 系统登记的所有的功能
let TEACHING_STUDIO_APISTUDIODISPLAY_LISTCHANGEPUT = "/teaching_studio_api/studio/display_list/change" //工作室本身相关接口 => 更新一个显示分类下面的功能列表，主持人权限
let TEACHING_STUDIO_APISTUDIOEND_TEXTPOST = "/teaching_studio_api/studio/end_text" //工作室本身相关接口 => 结束后的总结
let TEACHING_STUDIO_APISTUDIOHONORPOST = "/teaching_studio_api/studio/honor" //工作室本身相关接口 => 成果与荣誉；富文本：主持人可操作
let TEACHING_STUDIO_APISTUDIOIMGPOST = "/teaching_studio_api/studio/img" //工作室本身相关接口 => 修改Img
let TEACHING_STUDIO_APISTUDIOINTRODUCEPOST = "/teaching_studio_api/studio/introduce" //工作室本身相关接口 => 修改工作室简介：主持人可操作
let TEACHING_STUDIO_APISTUDIOIS_ENDPOST = "/teaching_studio_api/studio/is_end" //工作室本身相关接口 => 结束工作室
let TEACHING_STUDIO_APISTUDIOLOGOPOST = "/teaching_studio_api/studio/logo" //工作室本身相关接口 => 修改logo
let TEACHING_STUDIO_APISTUDIONAMEPOST = "/teaching_studio_api/studio/name" //工作室本身相关接口 => 修改工作室名称：主持人可操作
let TEACHING_STUDIO_APISTUDIOTARGET_TEXTPOST = "/teaching_studio_api/studio/target_text" //工作室本身相关接口 => 工作室目标；富文本

let TEACHING_STUDIO_APILIVEBEGINPOST = "/teaching_studio_api/live/begin" //工作室直播讲座 => 开始一场直播，主持人和发布人可以进行开启，开启后才能推流但是聊天是一直可以的
let TEACHING_STUDIO_APILIVECHATADDPOST = "/teaching_studio_api/live/chat/add" //工作室直播讲座 => 发送聊天记录，rtm由前端发送，不需要验证表单
let TEACHING_STUDIO_APILIVECREATEPOST = "/teaching_studio_api/live/create" //工作室直播讲座 => 新建一场直播，只有主持人可以新建
let TEACHING_STUDIO_APILIVEDELDELETE = "/teaching_studio_api/live/del" //工作室直播讲座 => 删除一场直播；主持人和发布人可以删除，删除后记录也会被删除
let TEACHING_STUDIO_APILIVEEXPREIENCEADDPOST = "/teaching_studio_api/live/expreience/add" //工作室直播讲座 => 新增心得，可以在直播结束后新增，一次直播对一个用户只产生一个心得记录，总是后面的覆盖前面的
let TEACHING_STUDIO_APILIVELIVECHATGET = "/teaching_studio_api/live/live/chat" //工作室直播讲座 => 直播的签到记录，工作室成员可见
let TEACHING_STUDIO_APILIVELIVEEXPREIENCEGET = "/teaching_studio_api/live/live/expreience" //工作室直播讲座 => 直播的心得记录，工作室成员可见
let TEACHING_STUDIO_APILIVELIVEINFOGET = "/teaching_studio_api/live/live/info" //工作室直播讲座 => 直播详情，如果处于直播中，会进行签到操作，此接口需要用户权限
let TEACHING_STUDIO_APILIVELIVELISTGET = "/teaching_studio_api/live/live/list" //工作室直播讲座 => 直播列表，按时间排列一次性读取所有的简要信息
let TEACHING_STUDIO_APILIVELIVENOWGET = "/teaching_studio_api/live/live/now" //工作室直播讲座 => 直播列表，按时间排列一次性读取所有的简要信息
let TEACHING_STUDIO_APILIVELIVEQRCODEGET = "/teaching_studio_api/live/live/qrcode" //工作室直播讲座 => 直播邀请码，只有处于直播中或者直播前的直播才能生成二维码；主持人或者讲座主持人或者讲座创建者
let TEACHING_STUDIO_APILIVELIVEUSEREXPREIENCEGET = "/teaching_studio_api/live/live/user/expreience" //工作室直播讲座 => 直播讲座的某个用户的心得，工作室成员可见
let TEACHING_STUDIO_APILIVELIVEVIEWGET = "/teaching_studio_api/live/live/view" //工作室直播讲座 => 直播的签到记录，工作室成员可见
let TEACHING_STUDIO_APILIVERTCGET = "/teaching_studio_api/live/rtc" //工作室直播讲座 => 拉取直播的RTC信息，只能拉取正在直播中的信息
let TEACHING_STUDIO_APILIVESIGNADDPOST = "/teaching_studio_api/live/sign/add" //工作室直播讲座 => 打卡，用户在观看过程中，前端定时调用
let TEACHING_STUDIO_APILIVESTOPPUT = "/teaching_studio_api/live/stop" //工作室直播讲座 => 停止一场直播，主持人、发布人，直播主持人都可以停止一场直播

let TEACHING_STUDIO_APIMEMBERGROUPADDPOST = "/teaching_studio_api/member/group/add" //工作室组 => 在工作室里面新增小组，会检测组是否重名，重名则会增加失败
let TEACHING_STUDIO_APIMEMBERGROUPCHANGELEADERPOST = "/teaching_studio_api/member/group/change/leader" //工作室组 => 更换组长，主持人操作，且此人不能在其他组
let TEACHING_STUDIO_APIMEMBERGROUPDELDELETE = "/teaching_studio_api/member/group/del" //工作室组 => 删除组，主持人操作，操作后组里的用户会变成无组；此组关联的用户都会踢出此组
let TEACHING_STUDIO_APIMEMBERGROUPMEMBER_ADDPOST = "/teaching_studio_api/member/group/member_add" //工作室组 => 新增组员，主持人操作，组长操作，这些人必须不在任何一组
let TEACHING_STUDIO_APIMEMBERGROUPMEMBER_OUTDELETE = "/teaching_studio_api/member/group/member_out" //工作室组 => 组员踢出(不能踢组长)，主持人操作，组长操作
let TEACHING_STUDIO_APIMEMBERGROUPMEMBER_SORTPOST = "/teaching_studio_api/member/group/member_sort" //工作室组 => 变更排序，主持人操作，组长操作
let TEACHING_STUDIO_APIMEMBERGROUPUPINFOPOST = "/teaching_studio_api/member/group/upinfo" //工作室组 => 更新组信息，只允许 更新组名、logo、sort(无论是否变化，都需要提交)

let TEACHING_STUDIO_APIRULEADDPOST = "/teaching_studio_api/rule/add" //工作室规则制度 => 新增规则，主持人权限，标题在系统内不能重复
let TEACHING_STUDIO_APIRULEDELDELETE = "/teaching_studio_api/rule/del" //工作室规则制度 => 删除规则，主持人权限
let TEACHING_STUDIO_APIRULEEDITPOST = "/teaching_studio_api/rule/edit" //工作室规则制度 => 新增规则，主持人权限，标题在系统内不能重复
let TEACHING_STUDIO_APIRULEINFOGET = "/teaching_studio_api/rule/info" //工作室规则制度 => 具体的规则详情，所有人都可见
let TEACHING_STUDIO_APIRULELISTGET = "/teaching_studio_api/rule/list" //工作室规则制度 => 工作室的规则列表，所有人都可见

let TEACHING_STUDIO_APIPLAN_SUMMARYADDPOST = "/teaching_studio_api/plan_summary/add" //工作室计划与总结 => 新增一个，主持人与小组长的权限
let TEACHING_STUDIO_APIPLAN_SUMMARYDELDELETE = "/teaching_studio_api/plan_summary/del" //工作室计划与总结 => 删除一个，不能改变可见范围，主持人与发布人的权限
let TEACHING_STUDIO_APIPLAN_SUMMARYEDITPOST = "/teaching_studio_api/plan_summary/edit" //工作室计划与总结 => 编辑一个，不能改变可见范围，主持人与发布人的权限
let TEACHING_STUDIO_APIPLAN_SUMMARYGROUPLISTGET = "/teaching_studio_api/plan_summary/group/list" //工作室计划与总结 => 组级别的计划与总结，主持人可见全部，其他人员可见自己小组和工作室的
let TEACHING_STUDIO_APIPLAN_SUMMARYINFOGET = "/teaching_studio_api/plan_summary/info" //工作室计划与总结 => 具体的计划于总结详情，全部人可见
let TEACHING_STUDIO_APIPLAN_SUMMARYSTUDIOLISTGET = "/teaching_studio_api/plan_summary/studio/list" //工作室计划与总结 => 工作级别的计划与总结，主持人可见全部，其他人员可见自己小组和工作室的

let TEACHING_STUDIO_APIEVALUATESELFCHANGEPOST = "/teaching_studio_api/evaluate/self/change" //工作室评价[废弃] => 添加自评分，当年没有才可以进行操作，只可以自我操作
let TEACHING_STUDIO_APIEVALUATESORTGROUPGET = "/teaching_studio_api/evaluate/sort/group" //工作室评价[废弃] => 某个年度，小组的自评排名，全体成员可看，有工作单位了
let TEACHING_STUDIO_APIEVALUATESORTSTUDIOGET = "/teaching_studio_api/evaluate/sort/studio" //工作室评价[废弃] => 某个年度，整个工作室的某年度自评排名，全体成员可看
let TEACHING_STUDIO_APIEVALUATESTUDIOCHANGEPOST = "/teaching_studio_api/evaluate/studio/change" //工作室评价[废弃] => 工作室评分的改变，主持人或者组长操作，注意组长不能自我操作，当综合评分定了后，就不能再次修改
let TEACHING_STUDIO_APIEVALUATESYNTHESIZECHANGEPOST = "/teaching_studio_api/evaluate/synthesize/change" //工作室评价[废弃] => 综合评分改变，主持人操作
let TEACHING_STUDIO_APIEVALUATEUSERGET = "/teaching_studio_api/evaluate/user" //工作室评价[废弃] => 当前工作室的某个用户的评价历史记录，支持人，组长，自己能看

let TEACHING_STUDIO_APIDETAILGROUPGET = "/teaching_studio_api/detail/group" //年度考核 => 小组级别的年度考核详细，组长以及主持人可看
let TEACHING_STUDIO_APIDETAILLISTGET = "/teaching_studio_api/detail/list" //年度考核 => 某个用户的年度学时构成的记录；自己可以看自己的，组长可以看组员的，主持人可以看所有的，这里调用的全是实际数据
let TEACHING_STUDIO_APIDETAILUSERGET = "/teaching_studio_api/detail/user" //年度考核 => 工作室某位用户的年度考核详细，自己可以看自己的，组长可以看组员的，主持人可以看所有的

let TEACHING_STUDIO_APIASSESSYEARADDPOST = "/teaching_studio_api/assess/year/add" //年度考核时段 => 新增一个考核年度，注意时间上不能重叠，只有主持人可以操作
let TEACHING_STUDIO_APIASSESSYEARDELDELETE = "/teaching_studio_api/assess/year/del" //年度考核时段 => 删除一个考核年度信息
let TEACHING_STUDIO_APIASSESSYEARINFOGET = "/teaching_studio_api/assess/year/info" //年度考核时段 => 获取当前登录工作室的某个年度的详情，记录的本身信息
let TEACHING_STUDIO_APIASSESSYEARLISTGET = "/teaching_studio_api/assess/year/list" //年度考核时段 => 获取当前登录工作室的考核的年度列表，不分页,
let TEACHING_STUDIO_APIASSESSYEARUPPUT = "/teaching_studio_api/assess/year/up" //年度考核时段 => 更新一个考核年度信息，注意时间上不能重叠，只有主持人可以操作

let IOT_AUTOWARNING_RANGE_QUICKADDPOST = "/iot_auto/warning_range_quick/add" //报警范围的设置的快捷方式 => 新增
let IOT_AUTOWARNING_RANGE_QUICKDELDELETE = "/iot_auto/warning_range_quick/del" //报警范围的设置的快捷方式 => 删除
let IOT_AUTOWARNING_RANGE_QUICKINFOGET = "/iot_auto/warning_range_quick/info" //报警范围的设置的快捷方式 => 获取详情
let IOT_AUTOWARNING_RANGE_QUICKLISTGET = "/iot_auto/warning_range_quick/list" //报警范围的设置的快捷方式 => 获取列表
let IOT_AUTOWARNING_RANGE_QUICKUPDATEPOST = "/iot_auto/warning_range_quick/update" //报警范围的设置的快捷方式 => 更新
let TEACHING_STUDIO_APIWARNING_RANGE_QUICKADDPOST = "/teaching_studio_api/warning_range_quick/add" //报警范围的设置的快捷方式 => 新增设备属性报警快捷方式，工作室内所有人员有权限
let TEACHING_STUDIO_APIWARNING_RANGE_QUICKDELDELETE = "/teaching_studio_api/warning_range_quick/del" //报警范围的设置的快捷方式 => 删除设备属性报警快捷方式，工作室内所有人员有权限
let TEACHING_STUDIO_APIWARNING_RANGE_QUICKINFOGET = "/teaching_studio_api/warning_range_quick/info" //报警范围的设置的快捷方式 => 获取设备属性报警快捷方式详细信息，工作室内所有人员有权限
let TEACHING_STUDIO_APIWARNING_RANGE_QUICKLISTGET = "/teaching_studio_api/warning_range_quick/list" //报警范围的设置的快捷方式 => 获取设备的属性报警快捷方式列表，工作室内所有人员有权限
let TEACHING_STUDIO_APIWARNING_RANGE_QUICKTODEVICE_PROPERTY_WARNINGPUT = "/teaching_studio_api/warning_range_quick/to/device_property_warning" //报警范围的设置的快捷方式 => 快捷设置设备的报警，工作室内所有人员有权限
let TEACHING_STUDIO_APIWARNING_RANGE_QUICKUPPUT = "/teaching_studio_api/warning_range_quick/up" //报警范围的设置的快捷方式 => 更新设备属性报警设置：工作室内主持人、创建人有次限

let TEACHING_STUDIO_APISSESENDGET = "/teaching_studio_api/sse/send" //服务器向客户端推送实时数据 => 服务器向客户端推送实时数据

let TEACHING_STUDIO_APIACTIVITYCLASSESACTIVITYGET = "/teaching_studio_api/activity/classes/activity" //活动 => 某个分类下面的活动[分页]，主持人可以看见所有活动，其他只能看见可见的活动，默认展示正在进行中的，可以分为未开始的、已结束的、正在进行中的
let TEACHING_STUDIO_APIACTIVITYCLASSESGET = "/teaching_studio_api/activity/classes" //活动 => 活动分类，当前工作室成员可见，按照排序拉取分类
let TEACHING_STUDIO_APIACTIVITYEXPREIENCEUSERGET = "/teaching_studio_api/activity/expreience/user" //活动 => 活动中某人的心得，对活动的人员可见，默认获取本人的心得
let TEACHING_STUDIO_APIACTIVITYEXPREIENCE_RECORDGET = "/teaching_studio_api/activity/expreience_record" //活动 => 活动的心得记录，对活动可见的人员，此接口可见
let TEACHING_STUDIO_APIACTIVITYINFOGET = "/teaching_studio_api/activity/info" //活动 => 活动的详情，只拉取活动本身的信息，不拉取签到记录、相册、心得
let TEACHING_STUDIO_APIACTIVITYNEWESTGET = "/teaching_studio_api/activity/newest" //活动 => 分类下面的活动[分页的]，主持人可以看见所有活动，其他只能看见可见的活动，默认展示正在进行中的，可以分为未开始的、已结束的、正在进行中的
let TEACHING_STUDIO_APIACTIVITYPHOTOGET = "/teaching_studio_api/activity/photo" //活动 => 活动的相册，对活动可见的人员，此接口可见
let TEACHING_STUDIO_APIACTIVITYPHOTO_INFOGET = "/teaching_studio_api/activity/photo_info" //活动 => 活动的相册，对活动可见的人员，此接口可见
let TEACHING_STUDIO_APIACTIVITYSIGN_QRCODEGET = "/teaching_studio_api/activity/sign_qrcode" //活动 => 活动的签到码，主持人和活动发布者可以拉取；活动进行后无法再拉取活动签到码
let TEACHING_STUDIO_APIACTIVITYSIGN_RECORDGET = "/teaching_studio_api/activity/sign_record" //活动 => 活动的签到记录，对活动可见的人员，此接口可见
let TEACHING_STUDIO_APIACTIVITYTEMPLATE_MSGGET = "/teaching_studio_api/activity/template_msg" //活动 => 模板消息的发送情况
let TEACHING_STUDIO_APIACTIVITYUSERGET = "/teaching_studio_api/activity/user" //活动 => 活动需要参与的人员，拉取活动需要参与的人员列表，对活动可见的人员，此接口可见

let TEACHING_STUDIO_APIACTIVITY_ACTIVITYADDPOST = "/teaching_studio_api/activity_activity/add" //活动本身的相关变更 => 新增活动，主持人和组长可以操作
let TEACHING_STUDIO_APIACTIVITY_ACTIVITYBEGINPOST = "/teaching_studio_api/activity_activity/begin" //活动本身的相关变更 => 活动开始按钮，主持人、活动主持人和发布人可以操作
let TEACHING_STUDIO_APIACTIVITY_ACTIVITYCHANGE_CLASSESPOST = "/teaching_studio_api/activity_activity/change_classes" //活动本身的相关变更 => 修改活动分类，主持人，活动主持人和发布人可以操作
let TEACHING_STUDIO_APIACTIVITY_ACTIVITYCHANGE_HOSTPOST = "/teaching_studio_api/activity_activity/change_host" //活动本身的相关变更 => 编辑活动主持人，主持人和发布人可以操作
let TEACHING_STUDIO_APIACTIVITY_ACTIVITYDELDELETE = "/teaching_studio_api/activity_activity/del" //活动本身的相关变更 => 活动删除，主持人、活动主持人和发布人可以操作
let TEACHING_STUDIO_APIACTIVITY_ACTIVITYEDITPOST = "/teaching_studio_api/activity_activity/edit" //活动本身的相关变更 => 编辑活动基础信息，不能修改分类，人员范围，主持人和发布人可以操作
let TEACHING_STUDIO_APIACTIVITY_ACTIVITYENDPOST = "/teaching_studio_api/activity_activity/end" //活动本身的相关变更 => 活动结束按钮，主持人、活动主持人和发布人可以操作；结束时需要提交心得得结束时间；结束时，如果此活动对应着考核细节，则会自动计算学时
let TEACHING_STUDIO_APIACTIVITY_ACTIVITYEND_TEXTPOST = "/teaching_studio_api/activity_activity/end_text" //活动本身的相关变更 => 活动总结（结束后添加），主持人、活动主持人和发布人可以操作
let TEACHING_STUDIO_APIACTIVITY_ACTIVITYFILESPUT = "/teaching_studio_api/activity_activity/files" //活动本身的相关变更 => 活动文件，活动参与人可传
let TEACHING_STUDIO_APIACTIVITY_ACTIVITYLEARN_HOUR_NUMPUT = "/teaching_studio_api/activity_activity/learn_hour_num" //活动本身的相关变更 => 活动结束后，更新活动的学时【限制活动只能是已结束的活动，且处于当前的活动】，主持人，活动主持人和发布人可以操作

let TEACHING_STUDIO_APIACTIVITY_EXPERIENCECHANGEPOST = "/teaching_studio_api/activity_experience/change" //活动的心得 => 心得变更，参与活动的人，进行中，一直到结束后的心得结束时间才不能进行心得提交
let TEACHING_STUDIO_APIACTIVITY_EXPERIENCEDELDELETE = "/teaching_studio_api/activity_experience/del" //活动的心得 => 心得删除，主持人与活动主持人与活动创建者;注意心得发表人不能进行自我删除

let TEACHING_STUDIO_APIACTIVITY_PHOTOADDPOST = "/teaching_studio_api/activity_photo/add" //活动的照片 => 新增活动照片，主持人,活动主持人和发布人可以操作
let TEACHING_STUDIO_APIACTIVITY_PHOTODELDELETE = "/teaching_studio_api/activity_photo/del" //活动的照片 => 删除活动照片，主持人，活动主持人和发布人可以操作
let TEACHING_STUDIO_APIACTIVITY_PHOTOEDITPOST = "/teaching_studio_api/activity_photo/edit" //活动的照片 => 编辑活动照片（比如删除其中一张），主持人，活动主持人和发布人可以操作

let TEACHING_STUDIO_APIACTIVITY_SIGNADDPOST = "/teaching_studio_api/activity_sign/add" //活动签到的变更 => 进行签到，参与活动的人。用户在活动中，活动未开始

let IOT_AUTOACTIVITY_CLASSESADDPOST = "/iot_auto/activity_classes/add" //活动类型 => 新增
let IOT_AUTOACTIVITY_CLASSESDELDELETE = "/iot_auto/activity_classes/del" //活动类型 => 删除
let IOT_AUTOACTIVITY_CLASSESINFOGET = "/iot_auto/activity_classes/info" //活动类型 => 获取详情
let IOT_AUTOACTIVITY_CLASSESLISTGET = "/iot_auto/activity_classes/list" //活动类型 => 获取列表
let IOT_AUTOACTIVITY_CLASSESUPDATEPOST = "/iot_auto/activity_classes/update" //活动类型 => 更新
let TEACHING_STUDIO_APIACTIVITY_CLASSESACTIVITY_ASSESSPUT = "/teaching_studio_api/activity_classes/activity_assess" //活动类型 => 编辑活动分类的考核细则id，支持人操作
let TEACHING_STUDIO_APIACTIVITY_CLASSESADDPOST = "/teaching_studio_api/activity_classes/add" //活动类型 => 新增活动分类，应该在PC端修改，主持人操作
let TEACHING_STUDIO_APIACTIVITY_CLASSESDELDELETE = "/teaching_studio_api/activity_classes/del" //活动类型 => 删除活动分类，主持人操作
let TEACHING_STUDIO_APIACTIVITY_CLASSESEDITPOST = "/teaching_studio_api/activity_classes/edit" //活动类型 => 编辑活动分类，主持人操作
let TEACHING_STUDIO_APIACTIVITY_CLASSESINFOGET = "/teaching_studio_api/activity_classes/info" //活动类型 => 活动分类详情，主持人操作
let TEACHING_STUDIO_APIACTIVITY_CLASSESSORTPOST = "/teaching_studio_api/activity_classes/sort" //活动类型 => 编辑活动分类排序，主持人操作

let TEACHING_STUDIO_APIIOT_DEVICEADDPOST = "/teaching_studio_api/iot_device/add" //物联设备 => 新增设备，工作室内主持人、组长有权限
let TEACHING_STUDIO_APIIOT_DEVICEDELDELETE = "/teaching_studio_api/iot_device/del" //物联设备 => 删除设备，工作室内主持人、创建人有次权限
let TEACHING_STUDIO_APIIOT_DEVICEDEVICE_KEYUPPUT = "/teaching_studio_api/iot_device/device_key/up" //物联设备 => 更新设备key，工作室内主持人、创建人有次限
let TEACHING_STUDIO_APIIOT_DEVICEDEVICE_TYPEUPPUT = "/teaching_studio_api/iot_device/device_type/up" //物联设备 => 更新设备类型，工作室内主持人、创建人有次限
let TEACHING_STUDIO_APIIOT_DEVICEEVENTRECORDGET = "/teaching_studio_api/iot_device/event/record" //物联设备 => 获取工作室内开关设备开关记录，分页列表，工作室内所有人有权限
let TEACHING_STUDIO_APIIOT_DEVICEINFOGET = "/teaching_studio_api/iot_device/info" //物联设备 => 获取设备详细信息，工作室内所有人员有权限
let TEACHING_STUDIO_APIIOT_DEVICELISTGET = "/teaching_studio_api/iot_device/list" //物联设备 => 获取生产房间下的设备列表，工作室内所有人员有权限
let TEACHING_STUDIO_APIIOT_DEVICENAMEUPPUT = "/teaching_studio_api/iot_device/name/up" //物联设备 => 更新设备名称，工作室内所有人有权限
let TEACHING_STUDIO_APIIOT_DEVICEORDERPUT = "/teaching_studio_api/iot_device/order" //物联设备 => 发送设备命令，工作室内所有人有权限
let TEACHING_STUDIO_APIIOT_DEVICEREAL_SHOW_PROPERTYUPPUT = "/teaching_studio_api/iot_device/real_show_property/up" //物联设备 => 更新设备真实需要展示到前端的属性，工作室内所有人有权限
let TEACHING_STUDIO_APIIOT_DEVICEROOMCOUNTGET = "/teaching_studio_api/iot_device/room/count" //物联设备 => 获取房间下设备统计（温湿度传感器、粒子数传感器、风速传感器、压差传感器、浮游菌采样器），工作室内所有人员有权限
let TEACHING_STUDIO_APIIOT_DEVICEROOMGET = "/teaching_studio_api/iot_device/room" //物联设备 => 获取设备所属房间信息，工作室内所有人有权限
let TEACHING_STUDIO_APIIOT_DEVICESTUDIOTYPELISTGET = "/teaching_studio_api/iot_device/studio/type/list" //物联设备 => 按类型获取工作室中的设备列表
let TEACHING_STUDIO_APIIOT_DEVICEUPFROMIOTPUT = "/teaching_studio_api/iot_device/up/from/iot" //物联设备 => 更新工作室的设备,支持人有权限
let TEACHING_STUDIO_APIIOT_DEVICEWORK_ROOMUPPUT = "/teaching_studio_api/iot_device/work_room/up" //物联设备 => 更新设备房间，工作室内所有人有权限
let TEACHING_STUDIO_APIWORKSHOPDEVICECOUNTGET = "/teaching_studio_api/workshop/device/count" //物联设备 => 获取生产车间下设备统计（温湿度传感器、粒子数传感器、风速传感器、压差传感器、浮游菌采样器），工作室内所有人员有权限
let TEACHING_STUDIO_APIWORK_ROOMDEVICEDATAGET = "/teaching_studio_api/work_room/device/data" //物联设备 => 获取房间下的所有设备及属性数据，工作室内所有人有权限

let TEACHING_STUDIO_APIIOT_DEVICE_PROPERTYADDPOST = "/teaching_studio_api/iot_device_property/add" //物联设备属性 => 新增设备属性，工作室内主持人、组长有权限
let TEACHING_STUDIO_APIIOT_DEVICE_PROPERTYDATALASTGET = "/teaching_studio_api/iot_device_property/data/last" //物联设备属性 => 获取设备属性的数据，工作室内主持人、创建人有次权限
let TEACHING_STUDIO_APIIOT_DEVICE_PROPERTYDELDELETE = "/teaching_studio_api/iot_device_property/del" //物联设备属性 => 删除设备属性，工作室内主持人、创建人有次权限
let TEACHING_STUDIO_APIIOT_DEVICE_PROPERTYINFOGET = "/teaching_studio_api/iot_device_property/info" //物联设备属性 => 获取设备属性详细信息，工作室内所有人员有权限
let TEACHING_STUDIO_APIIOT_DEVICE_PROPERTYLISTGET = "/teaching_studio_api/iot_device_property/list" //物联设备属性 => 获取设备的属性列表，工作室内所有人员有权限
let TEACHING_STUDIO_APIIOT_DEVICE_PROPERTYPROPERTY_KEYUPPUT = "/teaching_studio_api/iot_device_property/property_key/up" //物联设备属性 => 更新设备属性key，工作室内主持人、创建人有次限
let TEACHING_STUDIO_APIIOT_DEVICE_PROPERTYSTUDIOWARNINGSGET = "/teaching_studio_api/iot_device_property/studio/warnings" //物联设备属性 => 获取工作室设备属性数据报警的列表，工作室内所有人员有权限
let TEACHING_STUDIO_APIIOT_DEVICE_PROPERTYTIMEDATAGET = "/teaching_studio_api/iot_device_property/time/data" //物联设备属性 => 获取设备属性一段时间的数据，工作室内所有人员有权限

let TEACHING_STUDIO_APIIOT_DEVICE_PROPERTY_WARNINGADDPOST = "/teaching_studio_api/iot_device_property_warning/add" //物联设备属性报警设置 => 新增设备属性报警设置，工作室内主持人、组长有权限
let TEACHING_STUDIO_APIIOT_DEVICE_PROPERTY_WARNINGDELDELETE = "/teaching_studio_api/iot_device_property_warning/del" //物联设备属性报警设置 => 删除设备属性，工作室内主持人、创建人有次权限
let TEACHING_STUDIO_APIIOT_DEVICE_PROPERTY_WARNINGINFOGET = "/teaching_studio_api/iot_device_property_warning/info" //物联设备属性报警设置 => 获取设备属性报警设置详细信息，工作室内所有人员有权限
let TEACHING_STUDIO_APIIOT_DEVICE_PROPERTY_WARNINGLISTGET = "/teaching_studio_api/iot_device_property_warning/list" //物联设备属性报警设置 => 获取设备的属性报警设置列表，工作室内所有人员有权限
let TEACHING_STUDIO_APIIOT_DEVICE_PROPERTY_WARNINGWARNINGUPPUT = "/teaching_studio_api/iot_device_property_warning/warning/up" //物联设备属性报警设置 => 更新设备属性报警设置：工作室内主持人、创建人有次限

let TEACHING_STUDIO_APIWORK_AREAADDPOST = "/teaching_studio_api/work_area/add" //生产工区 => 新增一个生产工区，工作室内所有人有权限
let TEACHING_STUDIO_APIWORK_AREADELDELETE = "/teaching_studio_api/work_area/del" //生产工区 => 删除生产工区，工作室内所有人员有权限
let TEACHING_STUDIO_APIWORK_AREAINFOGET = "/teaching_studio_api/work_area/info" //生产工区 => 获取生产工区的详细信息，工作室内所有人员有权限
let TEACHING_STUDIO_APIWORK_AREALISTGET = "/teaching_studio_api/work_area/list" //生产工区 => 获取生产车间下的工区列表，工作室内所有人员有权限
let TEACHING_STUDIO_APIWORK_AREANAMEUPPUT = "/teaching_studio_api/work_area/name/up" //生产工区 => 更新生产工区的名字，工作室内所有人有权限

let IOT_AUTOWORK_ROOMADDPOST = "/iot_auto/work_room/add" //生产房间 => 新增
let IOT_AUTOWORK_ROOMDELDELETE = "/iot_auto/work_room/del" //生产房间 => 删除
let IOT_AUTOWORK_ROOMINFOGET = "/iot_auto/work_room/info" //生产房间 => 获取详情
let IOT_AUTOWORK_ROOMLISTGET = "/iot_auto/work_room/list" //生产房间 => 获取列表
let IOT_AUTOWORK_ROOMUPDATEPOST = "/iot_auto/work_room/update" //生产房间 => 更新
let TEACHING_STUDIO_APIWORK_ROOMADDPOST = "/teaching_studio_api/work_room/add" //生产房间 => 新增一个生产房间，工作室内所有人有权限
let TEACHING_STUDIO_APIWORK_ROOMDELDELETE = "/teaching_studio_api/work_room/del" //生产房间 => 删除生产房间，工作室内所支持人和创建人有权限
let TEACHING_STUDIO_APIWORK_ROOMINFOGET = "/teaching_studio_api/work_room/info" //生产房间 => 获取生产房间的详细信息，工作室内所有人员有权限
let TEACHING_STUDIO_APIWORK_ROOMLISTGET = "/teaching_studio_api/work_room/list" //生产房间 => 获取生产工区下的房间列表，工作室内所有人员有权限
let TEACHING_STUDIO_APIWORK_ROOMNAMEUPPUT = "/teaching_studio_api/work_room/name/up" //生产房间 => 更新生产房间的名字，工作室内所有人有权限
let TEACHING_STUDIO_APIWORK_ROOMSERIAL_NUMBERUPPUT = "/teaching_studio_api/work_room/serial_number/up" //生产房间 => 更新生产房间的编号，工作室内主持人、创建人有权限
let TEACHING_STUDIO_APIWORK_ROOMUSERUPPUT = "/teaching_studio_api/work_room/user/up" //生产房间 => 更新生产房间的负责人，工作室内所有人有权限

let TEACHING_STUDIO_APIWORKSHOPADDPOST = "/teaching_studio_api/workshop/add" //生产车间 => 新增一个生产车间，工作室内所有人有权限
let TEACHING_STUDIO_APIWORKSHOPDELDELETE = "/teaching_studio_api/workshop/del" //生产车间 => 删除生产车间，工作室内所有人员有权限
let TEACHING_STUDIO_APIWORKSHOPICONUPPUT = "/teaching_studio_api/workshop/icon/up" //生产车间 => 更新生产车间的logo，工作室内所有人有权限
let TEACHING_STUDIO_APIWORKSHOPINFOGET = "/teaching_studio_api/workshop/info" //生产车间 => 获取生产车间列表，工作室内所有人员有权限
let TEACHING_STUDIO_APIWORKSHOPLISTGET = "/teaching_studio_api/workshop/list" //生产车间 => 获取生产车间列表，工作室内所有人员有权限
let TEACHING_STUDIO_APIWORKSHOPNAMEUPPUT = "/teaching_studio_api/workshop/name/up" //生产车间 => 更新生产车间的名字，工作室内所有人有权限

let TEACHING_STUDIO_APISTUDIOLOGIN_STUDIOGET = "/teaching_studio_api/studio/login_studio" //用户与工作室 => 用户当前登录状态的工作室
let TEACHING_STUDIO_APISTUDIOTOSTUDIOGET = "/teaching_studio_api/studio/to/studio" //用户与工作室 => 切换到某个工作室
let TEACHING_STUDIO_APISTUDIOVISIONGET = "/teaching_studio_api/studio/vision" //用户与工作室 => 工作室的视觉元素[名称、img、logo、banner]
let TEACHING_STUDIO_APISTUDIOWECHATSTUDIOGET = "/teaching_studio_api/studio/wechat/studio" //用户与工作室 => 在此微信公众号下面的用户所在的工作室的列表

let TEACHING_STUDIO_APIUSER_APPRAISE_FORMADDPOST = "/teaching_studio_api/user_appraise_form/add" //用户的考核评价 => 新增自评，工作室内成员有此权限，一旦提交不能修改，所以请提醒用户
let TEACHING_STUDIO_APIUSER_APPRAISE_FORMFORMINFOGET = "/teaching_studio_api/user_appraise_form/form/info" //用户的考核评价 => 获取考核评价，主持人可以看全部的，组长可以看组员的，所有人可以看自己的
let TEACHING_STUDIO_APIUSER_APPRAISE_FORMGROUPLISTGET = "/teaching_studio_api/user_appraise_form/group/list" //用户的考核评价 => 某年度的某个小组的年度排名，注意如果未评分就不会有记录输出；这里不显示已经踢出的用户
let TEACHING_STUDIO_APIUSER_APPRAISE_FORMGROUPNUM_COUNTGET = "/teaching_studio_api/user_appraise_form/group/num_count" //用户的考核评价 => 组的年度人数统计，直接按组输出工作室所有组的统计，不包含主持人
let TEACHING_STUDIO_APIUSER_APPRAISE_FORMHIGH_LEVELUPPOST = "/teaching_studio_api/user_appraise_form/high_level/up" //用户的考核评价 => 上级评价，主持人、小组长有此权限；小组长对学员评价，主持人对成员评价
let TEACHING_STUDIO_APIUSER_APPRAISE_FORMLOGININFOGET = "/teaching_studio_api/user_appraise_form/login/info" //用户的考核评价 => 当前登录用户，最后一次的考核信息
let TEACHING_STUDIO_APIUSER_APPRAISE_FORMMEMBERLISTGET = "/teaching_studio_api/user_appraise_form/member/list" //用户的考核评价 => 某年度的成员的排名，注意如果未评分就不会有记录输出
let TEACHING_STUDIO_APIUSER_APPRAISE_FORMNUM_COUNTGET = "/teaching_studio_api/user_appraise_form/num_count" //用户的考核评价 => 年度人数统计，不包含主持人
let TEACHING_STUDIO_APIUSER_APPRAISE_FORMSTUDIOHIGH_LEVELHAVEGET = "/teaching_studio_api/user_appraise_form/studio/high_level/have" //用户的考核评价 => 工作室内已上一级评价的人，不包含主持人，工作室内开放
let TEACHING_STUDIO_APIUSER_APPRAISE_FORMSTUDIOSELFHAVEGET = "/teaching_studio_api/user_appraise_form/studio/self/have" //用户的考核评价 => 工作室内已自评的人，不包含主持人，工作室内开放

let TEACHING_STUDIO_APIMEDIAMENUDELDELETE = "/teaching_studio_api/media/menu/del" //用户端公众号菜单 => 删除菜单记录，注意本操作不会导致微信端的任何变化
let TEACHING_STUDIO_APIMEDIAMENUHISTORYGET = "/teaching_studio_api/media/menu/history" //用户端公众号菜单 => 菜单的编辑历史
let TEACHING_STUDIO_APIMEDIAMENUNOWGET = "/teaching_studio_api/media/menu/now" //用户端公众号菜单 => 现在生效的菜单
let TEACHING_STUDIO_APIMEDIAMENURELEASEPUT = "/teaching_studio_api/media/menu/release" //用户端公众号菜单 => 发布菜单到微信
let TEACHING_STUDIO_APIMEDIAMENUSAVEPOST = "/teaching_studio_api/media/menu/save" //用户端公众号菜单 => 保存菜单，其实是新增一个菜单记录，注意需要单独调用接口使其在微信端生效

let TEACHING_STUDIO_APIMEETINGADDPOST = "/teaching_studio_api/meeting/add" //用户端在线会议 => 添加一场会议，工作室内的人都有权限，只是只有主持人可以开启全部人会议，组长可以开启本组会议，组员只能进行person级别的会议添加
let TEACHING_STUDIO_APIMEETINGBEGINPUT = "/teaching_studio_api/meeting/begin" //用户端在线会议 => 开启一场会议，主持人、会议主持人、创建人可以操作
let TEACHING_STUDIO_APIMEETINGCANCELPUT = "/teaching_studio_api/meeting/cancel" //用户端在线会议 => 取消一场会议，主持人，会议主持人、创建人可以操作
let TEACHING_STUDIO_APIMEETINGDELDELETE = "/teaching_studio_api/meeting/del" //用户端在线会议 => 删除一场会议，主持人，会议主持人、创建人可以操作
let TEACHING_STUDIO_APIMEETINGDOINGGET = "/teaching_studio_api/meeting/doing" //用户端在线会议 => 我能看见的正在进行中的会议，考虑数量很少，不需要搜索
let TEACHING_STUDIO_APIMEETINGDONEPUT = "/teaching_studio_api/meeting/done" //用户端在线会议 => 结束一场会议，主持人、会议主持人、创建人可以操作
let TEACHING_STUDIO_APIMEETINGDONE_LISTGET = "/teaching_studio_api/meeting/done_list" //用户端在线会议 => 我能看见的已经结束的会议，包含取消的，这里会有一定数量（估计）
let TEACHING_STUDIO_APIMEETINGFILESCHANGEPUT = "/teaching_studio_api/meeting/files/change" //用户端在线会议 => 添加会议的文件；注意这里是全量修改的模式；参与会议的人都有权限
let TEACHING_STUDIO_APIMEETINGINFOGET = "/teaching_studio_api/meeting/info" //用户端在线会议 => 某次会议的详情，工作室成员可看
let TEACHING_STUDIO_APIMEETINGLISTGET = "/teaching_studio_api/meeting/list" //用户端在线会议 => 我可见的会议的列表记录，以时间范围进行查询，方便以月度视图，年度视图进行查看
let TEACHING_STUDIO_APIMEETINGPEOPLELINEDELDELETE = "/teaching_studio_api/meeting/people/line/del" //用户端在线会议 => 删除会议聊天，会议中的人员可以操作
let TEACHING_STUDIO_APIMEETINGPEOPLELINEGET = "/teaching_studio_api/meeting/people/line" //用户端在线会议 => 获取某场会议的聊天记录，工作室人员都可以看（也就是不在做工作室内的权限验证了)
let TEACHING_STUDIO_APIMEETINGPEOPLELINESENDPOST = "/teaching_studio_api/meeting/people/line/send" //用户端在线会议 => 发送会议聊天，会议中的人员可以操作
let TEACHING_STUDIO_APIMEETINGRTCGET = "/teaching_studio_api/meeting/rtc" //用户端在线会议 => 会议的RTC，与会的人可拉取
let TEACHING_STUDIO_APIMEETINGRTMGET = "/teaching_studio_api/meeting/rtm" //用户端在线会议 => 会议的RTM，与会的人可拉取
let TEACHING_STUDIO_APIMEETINGSTOP_LIVE_RECORDGET = "/teaching_studio_api/meeting/stop_live_record" //用户端在线会议 => 强制停止录制
let TEACHING_STUDIO_APIMEETINGWAITGET = "/teaching_studio_api/meeting/wait" //用户端在线会议 => 我能看见的等待中的会议，考虑数量很少，不需要搜索
let TEACHING_STUDIO_APIPEOPLEHOSTPUT = "/teaching_studio_api/people/host" //用户端在线会议 => 更换会议主持人；主持人、创建人、会议主持人可以操作

let TEACHING_STUDIO_APISTUDIOINFOCOUNTGET = "/teaching_studio_api/studio/info/count" //用户端工作情况 => 当前登录的工作室统计数据；课题数量，成员数量，活动数量，学习资源数量，直播数量，心得总数量，公告数量，评价考核数量

let TEACHING_STUDIO_APILIVE_RECORDONLINE_MEETINGRECORDGET = "/teaching_studio_api/live_record/online_meeting/record" //直播录制 => 视频会议=》获取录制记录
let TEACHING_STUDIO_APILIVE_RECORDONLINE_MEETINGSET_RECORDPOST = "/teaching_studio_api/live_record/online_meeting/set_record" //直播录制 => 视频会议=》设置是否录制，直播录制；在开始前，可以取消，也可以设置录制；主持人，会议主持人，会议创办人都可以操作
let TEACHING_STUDIO_APILIVE_RECORDONLINE_MEETINGSTATUSGET = "/teaching_studio_api/live_record/online_meeting/status" //直播录制 => 视频会议=》获取录制状态

let TEACHING_STUDIO_APILIVERECORD_STOPGET = "/teaching_studio_api/live/record_stop" //直播接口 => 停止录制
let TEACHING_STUDIO_APILIVERTMGET = "/teaching_studio_api/live/rtm" //直播接口 => 拉取直播的RTM信息，只要信息正确，总能拉取对应rtm信息
let TEACHING_STUDIO_APILIVESTATUSGET = "/teaching_studio_api/live/status" //直播接口 => 直播讲座的状态

let TEACHING_STUDIO_APIRESEARCHADDPOST = "/teaching_studio_api/research/add" //研究课题，课题本身是全体成员可见 => 添加课题，主持人可操作
let TEACHING_STUDIO_APIRESEARCHDELDELETE = "/teaching_studio_api/research/del" //研究课题，课题本身是全体成员可见 => 删除一个课题，主持人和课题带头人可以操作
let TEACHING_STUDIO_APIRESEARCHINFOGET = "/teaching_studio_api/research/info" //研究课题，课题本身是全体成员可见 => 课题详情，工作室内开放
let TEACHING_STUDIO_APIRESEARCHINFOUPDATEPOST = "/teaching_studio_api/research/info/update" //研究课题，课题本身是全体成员可见 => 更新课题基础信息，主持人和课题带头人可操作
let TEACHING_STUDIO_APIRESEARCHLISTGET = "/teaching_studio_api/research/list" //研究课题，课题本身是全体成员可见 => 工作室课题列表，工作室内开放
let TEACHING_STUDIO_APIRESEARCHSTATUSUPDATEPOST = "/teaching_studio_api/research/status/update" //研究课题，课题本身是全体成员可见 => 课题状态的变化，主持人和课题带头人可以操作
let TEACHING_STUDIO_APIRESEARCHUSERLEADERUPDATEPOST = "/teaching_studio_api/research/user/leader/update" //研究课题，课题本身是全体成员可见 => 更新课题基础信息，主持人和课题带头人可操作
let TEACHING_STUDIO_APIRESEARCHUSERPARTUPDATEPOST = "/teaching_studio_api/research/user/part/update" //研究课题，课题本身是全体成员可见 => 更新课题的成员，主持人和课题带头人可操作

let TEACHING_STUDIO_APIMONTH_COUNTBUILDPOST = "/teaching_studio_api/month_count/build" //统计数据 => 构建某个月度统计，主持人能操作；开始时间为工作室的创建的时间
let TEACHING_STUDIO_APIMONTH_COUNTGROUPGET = "/teaching_studio_api/month_count/group" //统计数据 => 某个组的月度统计，组员和主持人能看
let TEACHING_STUDIO_APIMONTH_COUNTGROUPSORTGET = "/teaching_studio_api/month_count/group/sort" //统计数据 => 工作室内组的排名，各组组长和主持人能看
let TEACHING_STUDIO_APIMONTH_COUNTGROUP_USERSORTGET = "/teaching_studio_api/month_count/group_user/sort" //统计数据 => 某个组内用户的排名，组内人员以及主持人能看，不做跨组比较
let TEACHING_STUDIO_APIMONTH_COUNTSTUDIOGET = "/teaching_studio_api/month_count/studio" //统计数据 => 工作室的月度统计数据，工作室的人能看
let TEACHING_STUDIO_APIMONTH_COUNTUSERGET = "/teaching_studio_api/month_count/user" //统计数据 => 某个用户的工作室月度统计，自己和组长和主持人能看

let TEACHING_STUDIO_APIAPPRAISE_FORMADDPOST = "/teaching_studio_api/appraise_form/add" //考核报表 => 添加考核报表，主持人有此权限，同一时期，一个工作室同一种报表只能存在一种
let TEACHING_STUDIO_APIAPPRAISE_FORMDELDELETE = "/teaching_studio_api/appraise_form/del" //考核报表 => 删除考核表单，只能删除下线表单，主持人和创建人有此权限
let TEACHING_STUDIO_APIAPPRAISE_FORMENDUPPOST = "/teaching_studio_api/appraise_form/end/up" //考核报表 => 考核下线：主持人和创建人有此权限
let TEACHING_STUDIO_APIAPPRAISE_FORMFORMENDGET = "/teaching_studio_api/appraise_form/form/end" //考核报表 => 主持人获取工作室已经下线的考核报表
let TEACHING_STUDIO_APIAPPRAISE_FORMFORMGET = "/teaching_studio_api/appraise_form/form" //考核报表 => 根据用户身份获取其需要填写的表单，主持人获取的是未下线的两种表单
let TEACHING_STUDIO_APIAPPRAISE_FORMINFOGET = "/teaching_studio_api/appraise_form/info" //考核报表 => 获取考核报表详细信息，工作室内成员有此权限
let TEACHING_STUDIO_APIAPPRAISE_FORMNAMEUPPOST = "/teaching_studio_api/appraise_form/name/up" //考核报表 => 更新考核报表名称，主持人和创建人有此权限
let TEACHING_STUDIO_APIAPPRAISE_FORMUSER_TYPEUPPOST = "/teaching_studio_api/appraise_form/user_type/up" //考核报表 => 更新考核成员的类型：member=>成员，study=>学员，主持人和创建人有此权限

let IOT_AUTOFORM_FIELDADDPOST = "/iot_auto/form_field/add" //表单字段 => 新增
let IOT_AUTOFORM_FIELDDELDELETE = "/iot_auto/form_field/del" //表单字段 => 删除
let IOT_AUTOFORM_FIELDINFOGET = "/iot_auto/form_field/info" //表单字段 => 获取详情
let IOT_AUTOFORM_FIELDLISTGET = "/iot_auto/form_field/list" //表单字段 => 获取列表
let IOT_AUTOFORM_FIELDUPDATEPOST = "/iot_auto/form_field/update" //表单字段 => 更新
let TEACHING_STUDIO_APIFORM_FIELDADDPOST = "/teaching_studio_api/form_field/add" //表单字段 => 添加考核报表字段，主持人和报表创建人有此权限
let TEACHING_STUDIO_APIFORM_FIELDALLGET = "/teaching_studio_api/form_field/all" //表单字段 => 获取表单的全部字段以及对应的值，工作室内成员有此权限
let TEACHING_STUDIO_APIFORM_FIELDDELDELETE = "/teaching_studio_api/form_field/del" //表单字段 => 删除表单的字段，创建人有此权限
let TEACHING_STUDIO_APIFORM_FIELDUPPOST = "/teaching_studio_api/form_field/up" //表单字段 => 更新字段名称、提示，主持人和创建人有此权限

let TEACHING_STUDIO_APIFIELD_VALUESADDPOST = "/teaching_studio_api/field_values/add" //表单字段的值 => 添加字段对应的值，主持人有此权限
let TEACHING_STUDIO_APIFIELD_VALUESALLGET = "/teaching_studio_api/field_values/all" //表单字段的值 => 获取字段的全部值，工作室内成员有此权限
let TEACHING_STUDIO_APIFIELD_VALUESDELDELETE = "/teaching_studio_api/field_values/del" //表单字段的值 => 删除字段的值，创建人有此权限
let TEACHING_STUDIO_APIFIELD_VALUESUPPOST = "/teaching_studio_api/field_values/up" //表单字段的值 => 更新字段值的信息，创建人有此权限

let TEACHING_STUDIO_APIMEDIAVIDEO_INFOPOST = "/teaching_studio_api/media/video_info" //视频文件处理 => 获取视频文件的封面，现在默认获取第一秒钟的截图

let TEACHING_STUDIO_APIFINANCEADDPOST = "/teaching_studio_api/finance/add" //财务记录 => 新增工作室财务使用记录  主持人可以添加
let TEACHING_STUDIO_APIFINANCELISTGET = "/teaching_studio_api/finance/list" //财务记录 => 列表获取财务记录  主持人可以查看
let TEACHING_STUDIO_APIFINANCEMONTHRECORDGET = "/teaching_studio_api/finance/month/record" //财务记录 => 财务的月记录  主持人可以查看
let TEACHING_STUDIO_APIFINANCERECORDDELETE = "/teaching_studio_api/finance/record" //财务记录 => 删除工作室财务使用记录  主持人可以添加


const  teaching_studio_teaching_studio_api = {

    /**
    * =>在线协作文件的token有效期刷新
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string access_token formData true 访问文档预览服务器的凭证 
    * @param string refresh_token formData true 凭证过期时使用RefeshToken刷新凭证 
    *
    **/
    Teaching_studio_apiWeb_officeWorkRefreshPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWEB_OFFICEWORKREFRESHPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWeb_officeWorkRefreshPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.access_token  = data.access_token    
        postParam.refresh_token  = data.refresh_token    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * =>登入某个工作室
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer studio_id formData true 工作室ID 
    *
    **/
    Not_studioSelf_studioLoginPost(data,bodyData){
        let url = thisReplaceUrl(NOT_STUDIOSELF_STUDIOLOGINPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Not_studioSelf_studioLoginPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.studio_id  = data.studio_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * =>新增工作室接口，一个最多在5个工作室内，自建的默认为免费版
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string name formData true 工作室名称，在系统中不能重复 
    * @param string img formData true 工作室LOGO 
    *
    **/
    Not_studioSelf_studioCreatePost(data,bodyData){
        let url = thisReplaceUrl(NOT_STUDIOSELF_STUDIOCREATEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Not_studioSelf_studioCreatePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.name  = data.name    
        postParam.img  = data.img    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 云盘文件=>新增
    * @param integer studio_id formData false 工作室ID 
    * @param integer studio_group_id formData false 工作室组ID 
    * @param integer user_id formData false 文件上传的用户id 
    * @param string is_open formData false 文件是否开放;yes||no 
    * @param string file_name formData false 文件名称 
    * @param string file_url formData false 文件地址 
    * @param string file_type formData false 文件类型 
    * @param integer admin_id formData false 新增人ID 
    *
    **/
    Iot_autoCloud_fileAddPost(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOCLOUD_FILEADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoCloud_fileAddPost');
        //相关参数
  
        postParam.studio_id  = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        postParam.studio_group_id  = typeof(data.studio_group_id) !="undefined" ?  data.studio_group_id:0  
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        postParam.is_open  = typeof(data.is_open) !="undefined" ?  data.is_open:''  
        postParam.file_name  = typeof(data.file_name) !="undefined" ?  data.file_name:''  
        postParam.file_url  = typeof(data.file_url) !="undefined" ?  data.file_url:''  
        postParam.file_type  = typeof(data.file_type) !="undefined" ?  data.file_type:''  
        postParam.admin_id  = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 云盘文件=>添加云盘文件，工作室内所有人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string is_open formData true 文件是否开放:yes=>开放，no=>不开放 
    * @param string file_name formData true 文件名称 
    * @param string file_url formData true 文件地址 
    * @param string file_type formData true 文件类型 
    *
    **/
    Teaching_studio_apiCloud_fileAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APICLOUD_FILEADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiCloud_fileAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.is_open  = data.is_open    
        postParam.file_name  = data.file_name    
        postParam.file_url  = data.file_url    
        postParam.file_type  = data.file_type    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 云盘文件=>更新云盘文件：名称、文件类型，创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer id formData true 记录唯一码 
    * @param string file_name formData true 文件名称 
    * @param string file_type formData true 文件类型 
    *
    **/
    Teaching_studio_apiCloud_fileFileUpPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APICLOUD_FILEFILEUPPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiCloud_fileFileUpPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.id  = data.id    
        postParam.file_name  = data.file_name    
        postParam.file_type  = data.file_type    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 云盘文件=>更新云盘文件开放状态：yes=>开放，no=>不开放，创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer id formData true 记录唯一码 
    * @param string is_open formData true 文件是否开放:yes=>开放，no=>不开放 
    *
    **/
    Teaching_studio_apiCloud_fileOpenUpPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APICLOUD_FILEOPENUPPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiCloud_fileOpenUpPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.id  = data.id    
        postParam.is_open  = data.is_open    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 云盘文件=>更新
    * @param integer id formData false noComment 
    * @param string sn formData false sn唯一码，用来公开访问 
    * @param integer studio_id formData false 工作室ID 
    * @param integer studio_group_id formData false 工作室组ID 
    * @param integer user_id formData false 文件上传的用户id 
    * @param string is_open formData false 文件是否开放;yes||no 
    * @param string file_name formData false 文件名称 
    * @param string file_url formData false 文件地址 
    * @param string file_type formData false 文件类型 
    * @param integer admin_id formData false 更新人ID 
    *
    **/
    Iot_autoCloud_fileUpdatePost(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOCLOUD_FILEUPDATEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoCloud_fileUpdatePost');
        //相关参数
  
        postParam.id  = typeof(data.id) !="undefined" ?  data.id:0  
        postParam.sn  = typeof(data.sn) !="undefined" ?  data.sn:''  
        postParam.studio_id  = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        postParam.studio_group_id  = typeof(data.studio_group_id) !="undefined" ?  data.studio_group_id:0  
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        postParam.is_open  = typeof(data.is_open) !="undefined" ?  data.is_open:''  
        postParam.file_name  = typeof(data.file_name) !="undefined" ?  data.file_name:''  
        postParam.file_url  = typeof(data.file_url) !="undefined" ?  data.file_url:''  
        postParam.file_type  = typeof(data.file_type) !="undefined" ?  data.file_type:''  
        postParam.admin_id  = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 云盘文件=>获取详情
    * @param integer id query false noComment 
    * @param string sn query false sn唯一码，用来公开访问 
    * @param integer studio_id query false 工作室ID 
    * @param integer studio_group_id query false 工作室组ID 
    * @param integer user_id query false 文件上传的用户id 
    * @param string is_open query false 文件是否开放;yes||no 
    * @param string file_name query false 文件名称 
    * @param string file_url query false 文件地址 
    * @param string file_type query false 文件类型 
    *
    **/
    Iot_autoCloud_fileInfoGet(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOCLOUD_FILEINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoCloud_fileInfoGet');
        //相关参数
  
        getParam.id = typeof(data.id) !="undefined" ?  data.id:0  
        getParam.sn = typeof(data.sn) !="undefined" ?  data.sn:''  
        getParam.studio_id = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        getParam.studio_group_id = typeof(data.studio_group_id) !="undefined" ?  data.studio_group_id:0  
        getParam.user_id = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        getParam.is_open = typeof(data.is_open) !="undefined" ?  data.is_open:''  
        getParam.file_name = typeof(data.file_name) !="undefined" ?  data.file_name:''  
        getParam.file_url = typeof(data.file_url) !="undefined" ?  data.file_url:''  
        getParam.file_type = typeof(data.file_type) !="undefined" ?  data.file_type:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 云盘文件=>获取列表
    * @param integer studio_id query false 工作室ID 
    * @param integer studio_group_id query false 工作室组ID 
    * @param integer user_id query false 文件上传的用户id 
    * @param string is_open query false 文件是否开放;yes||no 
    * @param string file_name query false 文件名称 
    * @param string file_url query false 文件地址 
    * @param string file_type query false 文件类型 
    * @param integer create_admin_id query false 记录的创建者 
    * @param integer update_admin_id query false 记录的更新者 
    * @param integer create_time_min query false 记录的创建时间开始 
    * @param integer create_time_max query false 记录的创建时间结束 
    * @param integer update_time_min query false 记录的更新时间开始 
    * @param integer update_time_max query false 记录的更新时间结束 
    * @param integer page query false 要读取第几页 
    * @param integer each_page query false 每页记录条数 
    * @param string order_field query false 排序字段；id: ID 
    * @param string order_type query false 排序方式；asc:升序,desc:降序 
    * @param string group_by query false 聚合方式 
    * @param string having query false 聚合后的筛选方式 
    *
    **/
    Iot_autoCloud_fileListGet(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOCLOUD_FILELISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoCloud_fileListGet');
        //相关参数
  
        getParam.studio_id = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        getParam.studio_group_id = typeof(data.studio_group_id) !="undefined" ?  data.studio_group_id:0  
        getParam.user_id = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        getParam.is_open = typeof(data.is_open) !="undefined" ?  data.is_open:''  
        getParam.file_name = typeof(data.file_name) !="undefined" ?  data.file_name:''  
        getParam.file_url = typeof(data.file_url) !="undefined" ?  data.file_url:''  
        getParam.file_type = typeof(data.file_type) !="undefined" ?  data.file_type:''  
        getParam.create_admin_id = typeof(data.create_admin_id) !="undefined" ?  data.create_admin_id:0  
        getParam.update_admin_id = typeof(data.update_admin_id) !="undefined" ?  data.update_admin_id:0  
        getParam.create_time_min = typeof(data.create_time_min) !="undefined" ?  data.create_time_min:0  
        getParam.create_time_max = typeof(data.create_time_max) !="undefined" ?  data.create_time_max:0  
        getParam.update_time_min = typeof(data.update_time_min) !="undefined" ?  data.update_time_min:0  
        getParam.update_time_max = typeof(data.update_time_max) !="undefined" ?  data.update_time_max:0  
        getParam.page = typeof(data.page) !="undefined" ?  data.page:0  
        getParam.each_page = typeof(data.each_page) !="undefined" ?  data.each_page:0  
        getParam.order_field = typeof(data.order_field) !="undefined" ?  data.order_field:''  
        getParam.order_type = typeof(data.order_type) !="undefined" ?  data.order_type:''  
        getParam.group_by = typeof(data.group_by) !="undefined" ?  data.group_by:''  
        getParam.having = typeof(data.having) !="undefined" ?  data.having:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 云盘文件=>我的云盘里的文件发起了在线协作的文件，这里的对应的删除，只能删除协作记录
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer page query true 分页数 
    * @param integer each_page query true 分页读取数量 
    *
    **/
    Teaching_studio_apiCloud_fileWeb_officeGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APICLOUD_FILEWEB_OFFICEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiCloud_fileWeb_officeGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.page = data.page    
        getParam.each_page = data.each_page    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 云盘文件=>获取系统云盘文件分页列表(开放的)，工作室内成员有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string file_name query false 文件名称 
    * @param string file_type query false 文件类型 
    * @param integer page query true 分页数 
    * @param integer each_page query true 分页读取数量 
    *
    **/
    Teaching_studio_apiCloud_fileStudioOpenPageGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APICLOUD_FILESTUDIOOPENPAGEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiCloud_fileStudioOpenPageGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.file_name = typeof(data.file_name) !="undefined" ?  data.file_name:''  
        getParam.file_type = typeof(data.file_type) !="undefined" ?  data.file_type:''  
        getParam.page = data.page    
        getParam.each_page = data.each_page    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 云盘文件=>获取工作室内云盘文件分页列表(我自己的)，创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string file_name query false 文件名称 
    * @param string file_type query false 文件类型 
    * @param string is_open query false yes=>公开的，no=>不公开的 
    * @param integer page query true 分页数 
    * @param integer each_page query true 分页读取数量 
    *
    **/
    Teaching_studio_apiCloud_fileStudioMinePageGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APICLOUD_FILESTUDIOMINEPAGEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiCloud_fileStudioMinePageGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.file_name = typeof(data.file_name) !="undefined" ?  data.file_name:''  
        getParam.file_type = typeof(data.file_type) !="undefined" ?  data.file_type:''  
        getParam.is_open = typeof(data.is_open) !="undefined" ?  data.is_open:''  
        getParam.page = data.page    
        getParam.each_page = data.each_page    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 云盘文件=>删除云盘文件，创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer id query true 记录唯一码 
    *
    **/
    Teaching_studio_apiCloud_fileDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APICLOUD_FILEDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiCloud_fileDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 云盘文件=>删除
    * @param integer id query false noComment 
    * @param string sn query false sn唯一码，用来公开访问 
    * @param integer studio_id query false 工作室ID 
    * @param integer studio_group_id query false 工作室组ID 
    * @param integer user_id query false 文件上传的用户id 
    * @param string is_open query false 文件是否开放;yes||no 
    * @param string file_name query false 文件名称 
    * @param string file_url query false 文件地址 
    * @param string file_type query false 文件类型 
    * @param integer admin_id query false 删除人ID 
    *
    **/
    Iot_autoCloud_fileDelDelete(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOCLOUD_FILEDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoCloud_fileDelDelete');
        //相关参数
  
        getParam.id = typeof(data.id) !="undefined" ?  data.id:0  
        getParam.sn = typeof(data.sn) !="undefined" ?  data.sn:''  
        getParam.studio_id = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        getParam.studio_group_id = typeof(data.studio_group_id) !="undefined" ?  data.studio_group_id:0  
        getParam.user_id = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        getParam.is_open = typeof(data.is_open) !="undefined" ?  data.is_open:''  
        getParam.file_name = typeof(data.file_name) !="undefined" ?  data.file_name:''  
        getParam.file_url = typeof(data.file_url) !="undefined" ?  data.file_url:''  
        getParam.file_type = typeof(data.file_type) !="undefined" ?  data.file_type:''  
        getParam.admin_id = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 会议人员=>获取会议人员列表,会议中的人具有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer meet_id query true 会议id 
    *
    **/
    Teaching_studio_apiMeetingPeopleAllGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGPEOPLEALLGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingPeopleAllGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.meet_id = data.meet_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 会议人员=>通过邀请进入会议
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string qrcode_value query true 邀请生成的值 
    *
    **/
    Teaching_studio_apiMeetingPeopleInviteInGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGPEOPLEINVITEINGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingPeopleInviteInGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.qrcode_value = data.qrcode_value    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 会议人员=>获取会议内的人员状态[起码得有状态，虽是会议人员但是没有进入过不会有状态][人员列表，是否可以语音、视频，是否在线]最多30秒一次
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string meet_id query true 会议ID 
    *
    **/
    Teaching_studio_apiMeetingPeopleStatusGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGPEOPLESTATUSGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingPeopleStatusGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.meet_id = data.meet_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 会议人员=>获取邀请人员加入的链接，因为都是在微信里，应该是要生成微信的分享链接；同时也具备提醒进入会议的功能
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer meet_id query true 会议id 
    *
    **/
    Teaching_studio_apiMeetingPeopleInviteGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGPEOPLEINVITEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingPeopleInviteGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.meet_id = data.meet_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 会议人员=>用户的在线心跳(20秒一次)；要求会议未处于结束状态
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string meet_id query true 会议ID 
    *
    **/
    Teaching_studio_apiMeetingPeopleHeartPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGPEOPLEHEARTPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingPeopleHeartPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.meet_id = data.meet_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 会议人员=>剔出会议中的一个人员,主持人、创建人、会议主持人拥有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer meet_id query true 会议id 
    * @param integer user_id query true 用户id 
    *
    **/
    Teaching_studio_apiMeetingPeopleDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGPEOPLEDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingPeopleDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.meet_id = data.meet_id    
        getParam.user_id = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 会议人员=>更新会议人员是否可以视屏通话,主持人、创建人、会议主持人拥有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer meet_id formData true 会议id 
    * @param string can_video formData true 用户在本次会议中能否视频通话(yes|no) 
    * @param integer user_id formData true 用户id 
    *
    **/
    Teaching_studio_apiMeetingPeopleCan_videoPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGPEOPLECAN_VIDEOPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingPeopleCan_videoPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.meet_id  = data.meet_id    
        postParam.can_video  = data.can_video    
        postParam.user_id  = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 会议人员=>更新用户在会议里的权限：是否可以语音通话；主持人、创建人、会议主持人可以操作；其中自己可以关闭的语音通话
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer meet_id formData true 会议id 
    * @param string can_voice formData true 用户在本场会议中能否讲话（yes|no） 
    * @param integer user_id formData true 用户标识 
    *
    **/
    Teaching_studio_apiMeetingPeopleCan_voicePut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGPEOPLECAN_VOICEPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingPeopleCan_voicePut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.meet_id  = data.meet_id    
        postParam.can_voice  = data.can_voice    
        postParam.user_id  = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 会议人员=>人员进入会议
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer meet_id query true 会议id 
    *
    **/
    Teaching_studio_apiMeetingPeopleInMeetingPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGPEOPLEINMEETINGPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingPeopleInMeetingPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.meet_id = data.meet_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 会议人员=>人员离开会议
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer meet_id query true 会议id 
    *
    **/
    Teaching_studio_apiMeetingPeopleOutMeetingPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGPEOPLEOUTMEETINGPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingPeopleOutMeetingPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.meet_id = data.meet_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>新增一条学习资源，工作室成员都可以添加
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer classes_id formData true 学习资源分类id 
    * @param string title formData true 资源标题 
    * @param string intro formData true 资源的简要介绍 
    * @param string video_url formData false 视频资源地址 
    * @param string voice_url formData false 音频资源学习地址 
    * @param string text_content formData false 文本内容 
    *
    **/
    Teaching_studio_apiStudyInfoAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYINFOADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyInfoAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.classes_id  = data.classes_id    
        postParam.title  = data.title    
        postParam.intro  = data.intro    
        postParam.video_url  = typeof(data.video_url) !="undefined" ?  data.video_url:''  
        postParam.voice_url  = typeof(data.voice_url) !="undefined" ?  data.voice_url:''  
        postParam.text_content  = typeof(data.text_content) !="undefined" ?  data.text_content:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>新增资源分类，主持人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string name formData true 分类名称；分类名称不能重复 
    * @param string img formData true 封面图 
    * @param integer sort formData true 排序；越大越靠前 
    *
    **/
    Teaching_studio_apiStudyClassesAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYCLASSESADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyClassesAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.name  = data.name    
        postParam.img  = data.img    
        postParam.sort  = data.sort    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>更新资源的所属分类，主持人和发布人
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer study_list_id formData true 资源id 
    * @param integer classes_id formData true 新的分类id 
    *
    **/
    Teaching_studio_apiStudyInfoChange_classesPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYINFOCHANGE_CLASSESPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyInfoChange_classesPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.study_list_id  = data.study_list_id    
        postParam.classes_id  = data.classes_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>资源开放，主持人和发布人
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer study_list_id formData true 资源id 
    * @param boolean to_open formData true 是否开放：true||false 
    *
    **/
    Teaching_studio_apiStudyInfoOpen_closePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYINFOOPEN_CLOSEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyInfoOpen_closePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.study_list_id  = data.study_list_id    
        postParam.to_open  = data.to_open    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>更新排序，主持人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer classes_id formData true 修改的分类id 
    * @param integer sort formData true 分类名称；分类名称不能重复 
    *
    **/
    Teaching_studio_apiStudyClassesSortPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYCLASSESSORTPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyClassesSortPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.classes_id  = data.classes_id    
        postParam.sort  = data.sort    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>更新资源分类，主持人和发布人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer classes_id formData true 修改的分类id 
    * @param string name formData true 分类名称；分类名称不能重复 
    * @param string img formData true 封面图 
    *
    **/
    Teaching_studio_apiStudyClassesUpdatePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYCLASSESUPDATEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyClassesUpdatePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.classes_id  = data.classes_id    
        postParam.name  = data.name    
        postParam.img  = data.img    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>发布一次心得
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer study_id formData true 具体的学习资源ID 
    * @param string send_content formData true 心得内容 
    * @param string imgs formData true 心得的照片 
    *
    **/
    Teaching_studio_apiStudySendExperiencePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYSENDEXPERIENCEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudySendExperiencePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.study_id  = data.study_id    
        postParam.send_content  = data.send_content    
        postParam.imgs  = data.imgs    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>更新一条学习资源，主持人和发布人
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer study_list_id formData true 资源id 
    * @param string title formData false 资源标题 
    * @param string intro formData false 资源的简要介绍 
    * @param string video_url formData false 视频资源地址 
    * @param string voice_url formData false 音频资源学习地址 
    * @param string text_content formData false 文本内容 
    *
    **/
    Teaching_studio_apiStudyInfoUpdatePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYINFOUPDATEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyInfoUpdatePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.study_list_id  = data.study_list_id    
        postParam.title  = typeof(data.title) !="undefined" ?  data.title:''  
        postParam.intro  = typeof(data.intro) !="undefined" ?  data.intro:''  
        postParam.video_url  = typeof(data.video_url) !="undefined" ?  data.video_url:''  
        postParam.voice_url  = typeof(data.voice_url) !="undefined" ?  data.voice_url:''  
        postParam.text_content  = typeof(data.text_content) !="undefined" ?  data.text_content:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>更新一次心得,could update self send;host can update anyone send
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer record_id formData true 心得记录ID 
    * @param string send_content formData true 更新后内容 
    * @param string imgs formData true 心得的照片 
    *
    **/
    Teaching_studio_apiStudyUpdateExperiencePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYUPDATEEXPERIENCEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyUpdateExperiencePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.record_id  = data.record_id    
        postParam.send_content  = data.send_content    
        postParam.imgs  = data.imgs    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>资源详情，工作室开放
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer classes_id query true 资源分类id 
    *
    **/
    Teaching_studio_apiStudyClassesInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYCLASSESINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyClassesInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.classes_id = data.classes_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>资源详情，并且会记录一次查看记录
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer study_id query true 具体的学习资源ID 
    *
    **/
    Teaching_studio_apiStudyInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.study_id = data.study_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>学习资源分类列表，经过排序的
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiStudyClassesListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYCLASSESLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyClassesListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>分类下所有的学习资源，按照发布时间排序的
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer study_classes_id query true 要调取的资源分类id 
    *
    **/
    Teaching_studio_apiStudyClassesStudyListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYCLASSESSTUDYLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyClassesStudyListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.study_classes_id = data.study_classes_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>某个资源的工作室成员的心得
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer study_id query true 具体的学习资源ID 
    *
    **/
    Teaching_studio_apiStudyInfoExperienceGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYINFOEXPERIENCEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyInfoExperienceGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.study_id = data.study_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>某个资源的工作室成员的查看记录
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer study_id query true 具体的学习资源ID 
    *
    **/
    Teaching_studio_apiStudyInfoView_recordGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYINFOVIEW_RECORDGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyInfoView_recordGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.study_id = data.study_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>某条心得的详情
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer record_id query true 具体的心得id 
    *
    **/
    Teaching_studio_apiStudyExperienceRecordGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYEXPERIENCERECORDGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyExperienceRecordGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.record_id = data.record_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>最新的资源，按照发布时间的降序排序
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiStudyNewestGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYNEWESTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyNewestGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>删除一条学习资源，主持人与发布人，会删除签到记录与心得记录
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer study_id query true 具体的学习资源ID 
    *
    **/
    Teaching_studio_apiStudyInfoDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYINFODELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyInfoDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.study_id = data.study_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>删除资源分类，主持人和发布人可以操作并且其下没有资源时才可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer classes_id query true 分类id 
    *
    **/
    Teaching_studio_apiStudyClassesDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYCLASSESDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyClassesDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.classes_id = data.classes_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 内部的、工作室的、学习资源=>删除一次心得,could delete self send;host can delete anyone send
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer record_id query true 心得记录ID 
    *
    **/
    Teaching_studio_apiStudyDelExperienceDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDYDELEXPERIENCEDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudyDelExperienceDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.record_id = data.record_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 在线office=>在线浏览文件的token有效期刷新
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string access_token formData true 访问文档预览服务器的凭证 
    * @param string refresh_token formData true 凭证过期时使用RefeshToken刷新凭证 
    *
    **/
    Teaching_studio_apiWeb_officePreviewRefreshPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWEB_OFFICEPREVIEWREFRESHPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWeb_officePreviewRefreshPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.access_token  = data.access_token    
        postParam.refresh_token  = data.refresh_token    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 在线office=>微信端上传媒体文件，支持的格式：et、xls、xlt、xlsx、xlsm、xltx、xltm、csv文字文件：doc、docx、txt、dot、wps、wpt、dotx、docm、dotm、rtf 演示文件：ppt、pptx、pptm、ppsx、ppsm、pps、potx、potm、dpt、dps；PDF格式文件：pdf
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string url formData true 阿里云文件的访问地址；会根据文件后缀自动判断文件类型 
    * @param integer is_cloud formData true 是否是云盘文件；1=>是，0=>否 
    *
    **/
    Teaching_studio_apiWeb_officePreviewPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWEB_OFFICEPREVIEWPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWeb_officePreviewPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.url  = data.url    
        postParam.is_cloud  = data.is_cloud    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 在线office=>登记用户加入在线文档的协作；得是本次文档协作的发起者才能邀请
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer record_id formData true 协作记录ID 
    * @param integer user_id formData true 登记的用户对象 
    *
    **/
    Teaching_studio_apiWeb_officeUserInPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWEB_OFFICEUSERINPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWeb_officeUserInPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.record_id  = data.record_id    
        postParam.user_id  = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 在线office=>以在线协作的方式打开一文件；pdf文件仅支持预览，不支持编辑，需要将Permission字段中的Readonly设置为true。目前支持的文件类型有：表格文件：et、xls、xlt、xlsx、xlsm、xltx、xltm、csv；文字文件：doc、docx、txt、dot、wps、wpt、dotx、docm、dotm、rtf；演示文件：ppt、pptx、pptm、ppsx、ppsm、pps、potx、potm、dpt、dps；pdf文件：pdf
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string url formData true 阿里云文件的访问地址；会根据文件后缀自动判断文件类型 
    * @param integer could_write formData true 是否能够修改文件：1：可以，0：不可以 
    * @param integer could_export formData true 是否能够导出文件：1：可以，0：不可以 
    * @param string file_name formData true 文件名称 
    * @param integer is_cloud formData true 是否是云盘文件；1=>是，0=>否 
    *
    **/
    Teaching_studio_apiWeb_officeWorkPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWEB_OFFICEWORKPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWeb_officeWorkPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.url  = data.url    
        postParam.could_write  = data.could_write    
        postParam.could_export  = data.could_export    
        postParam.file_name  = data.file_name    
        postParam.is_cloud  = data.is_cloud    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 在线office=>某个协作文档的详细情况，主持人可看所有的，组长可看组内的，组员可以看参与的
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer web_office_id query false 文件记录ID 
    * @param integer cloud_file_id query false 云盘文件id 
    *
    **/
    Teaching_studio_apiWeb_officeInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWEB_OFFICEINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWeb_officeInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.web_office_id = typeof(data.web_office_id) !="undefined" ?  data.web_office_id:0  
        getParam.cloud_file_id = typeof(data.cloud_file_id) !="undefined" ?  data.cloud_file_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 在线office=>用户参与过的协作文档
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiWeb_officeInListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWEB_OFFICEINLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWeb_officeInListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 在线office=>在线协作，文档的用户，参与的用户可见
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer record_id query true 协作记录ID 
    *
    **/
    Teaching_studio_apiWeb_officeUserListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWEB_OFFICEUSERLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWeb_officeUserListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.record_id = data.record_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 在线office=>**暂时不用**用户可见的在线协作文档，不一定参与了，只是权限范围内可见；主持人可见工作室全部的，组长可见组内全部的自己参与的，组员只能看见自己参与的
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer studio_group_id query false 筛选组，组id 
    *
    **/
    Teaching_studio_apiWeb_officeListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWEB_OFFICELISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWeb_officeListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.studio_group_id = typeof(data.studio_group_id) !="undefined" ?  data.studio_group_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 在线office=>获取文档的工作状态
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string web_office_ids query true 多个文档ID用英文逗号拼接 
    *
    **/
    Teaching_studio_apiWeb_officeWorkinDocGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWEB_OFFICEWORKINDOCGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWeb_officeWorkinDocGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.web_office_ids = data.web_office_ids    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 在线office=>在线协作时打开office的工作心跳
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer web_office_id query true 文档ID 
    *
    **/
    Teaching_studio_apiWeb_officeWorkinHeartGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWEB_OFFICEWORKINHEARTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWeb_officeWorkinHeartGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.web_office_id = data.web_office_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 在线office=>删除一个协作文档，注意只会删除协作记录功能，不会对对应的云盘文件或者在线文件进行删除；注意只能删除我发起的文档
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer web_office_id query false 文件记录ID 
    *
    **/
    Teaching_studio_apiWeb_officeDocDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWEB_OFFICEDOCDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWeb_officeDocDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.web_office_id = typeof(data.web_office_id) !="undefined" ?  data.web_office_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 媒体文件上传=>微信端上传媒体文件
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string media_id formData true 资源ID 
    * @param boolean is_cloud formData true 是否时云盘文件；true|false 
    * @param string media_type formData true 图片=>img,视屏=>video,语音=>voice 
    *
    **/
    Teaching_studio_apiMediaWechatUpPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEDIAWECHATUPPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMediaWechatUpPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.media_id  = data.media_id    
        postParam.is_cloud  = data.is_cloud    
        postParam.media_type  = data.media_type    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 媒体文件上传=>阿里云文件直接上传
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiMediaAliyunFileUpGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEDIAALIYUNFILEUPGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMediaAliyunFileUpGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 媒体文件上传=>阿里云网盘文件直接上传
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiMediaAliyunFileCloud_upGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEDIAALIYUNFILECLOUD_UPGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMediaAliyunFileCloud_upGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 媒体文件上传=>前端获取七牛上传的Token【废弃】
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiMediaQiniuFileUpGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEDIAQINIUFILEUPGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMediaQiniuFileUpGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 学习笔记=>新增
    * @param integer studio_id formData false 工作室ID 
    * @param integer studio_group_id formData false 工作室组ID 
    * @param integer user_id formData false 文件上传的用户id 
    * @param string note_context formData false 笔记的文本内容 
    * @param string note_media formData false 笔记的媒体内容JSON格式 
    * @param string note_file formData false 笔记关联的云盘文件ids，以英文逗号拼接 
    * @param integer admin_id formData false 新增人ID 
    *
    **/
    Iot_autoLearn_noteAddPost(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOLEARN_NOTEADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoLearn_noteAddPost');
        //相关参数
  
        postParam.studio_id  = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        postParam.studio_group_id  = typeof(data.studio_group_id) !="undefined" ?  data.studio_group_id:0  
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        postParam.note_context  = typeof(data.note_context) !="undefined" ?  data.note_context:''  
        postParam.note_media  = typeof(data.note_media) !="undefined" ?  data.note_media:''  
        postParam.note_file  = typeof(data.note_file) !="undefined" ?  data.note_file:''  
        postParam.admin_id  = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 学习笔记=>添加学习笔记，工作室内成员有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string note_context formData true 笔记的文本内容 
    * @param string note_media formData true 笔记的媒体内容JSON格式 
    * @param string note_file formData true 笔记关联的云盘文件ids，以英文逗号拼接 
    *
    **/
    Teaching_studio_apiLearn_noteAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILEARN_NOTEADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLearn_noteAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.note_context  = data.note_context    
        postParam.note_media  = data.note_media    
        postParam.note_file  = data.note_file    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 学习笔记=>更新笔记内容：内容，媒体文件，创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer id formData true 记录唯一标识 
    * @param string note_context formData true 笔记的文本内容 
    * @param string note_media formData true 笔记的媒体内容JSON格式 
    *
    **/
    Teaching_studio_apiLearn_noteInfoUpPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILEARN_NOTEINFOUPPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLearn_noteInfoUpPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.id  = data.id    
        postParam.note_context  = data.note_context    
        postParam.note_media  = data.note_media    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 学习笔记=>更新关联的云盘文件，创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer id formData true 记录唯一标识 
    * @param string note_file formData true 笔记关联的云盘文件ids，以英文逗号拼接 
    *
    **/
    Teaching_studio_apiLearn_noteFileUpPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILEARN_NOTEFILEUPPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLearn_noteFileUpPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.id  = data.id    
        postParam.note_file  = data.note_file    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 学习笔记=>更新
    * @param integer id formData false noComment 
    * @param integer studio_id formData false 工作室ID 
    * @param integer studio_group_id formData false 工作室组ID 
    * @param integer user_id formData false 文件上传的用户id 
    * @param string note_context formData false 笔记的文本内容 
    * @param string note_media formData false 笔记的媒体内容JSON格式 
    * @param string note_file formData false 笔记关联的云盘文件ids，以英文逗号拼接 
    * @param integer admin_id formData false 更新人ID 
    *
    **/
    Iot_autoLearn_noteUpdatePost(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOLEARN_NOTEUPDATEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoLearn_noteUpdatePost');
        //相关参数
  
        postParam.id  = typeof(data.id) !="undefined" ?  data.id:0  
        postParam.studio_id  = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        postParam.studio_group_id  = typeof(data.studio_group_id) !="undefined" ?  data.studio_group_id:0  
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        postParam.note_context  = typeof(data.note_context) !="undefined" ?  data.note_context:''  
        postParam.note_media  = typeof(data.note_media) !="undefined" ?  data.note_media:''  
        postParam.note_file  = typeof(data.note_file) !="undefined" ?  data.note_file:''  
        postParam.admin_id  = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 学习笔记=>笔记详情，工作室开放
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer note_id query true 学习笔记ID 
    *
    **/
    Teaching_studio_apiLearn_noteInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILEARN_NOTEINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLearn_noteInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.note_id = data.note_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 学习笔记=>获取详情
    * @param integer id query false noComment 
    * @param integer studio_id query false 工作室ID 
    * @param integer studio_group_id query false 工作室组ID 
    * @param integer user_id query false 文件上传的用户id 
    * @param string note_context query false 笔记的文本内容 
    * @param string note_media query false 笔记的媒体内容JSON格式 
    * @param string note_file query false 笔记关联的云盘文件ids，以英文逗号拼接 
    *
    **/
    Iot_autoLearn_noteInfoGet(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOLEARN_NOTEINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoLearn_noteInfoGet');
        //相关参数
  
        getParam.id = typeof(data.id) !="undefined" ?  data.id:0  
        getParam.studio_id = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        getParam.studio_group_id = typeof(data.studio_group_id) !="undefined" ?  data.studio_group_id:0  
        getParam.user_id = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        getParam.note_context = typeof(data.note_context) !="undefined" ?  data.note_context:''  
        getParam.note_media = typeof(data.note_media) !="undefined" ?  data.note_media:''  
        getParam.note_file = typeof(data.note_file) !="undefined" ?  data.note_file:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 学习笔记=>获取列表
    * @param integer studio_id query false 工作室ID 
    * @param integer studio_group_id query false 工作室组ID 
    * @param integer user_id query false 文件上传的用户id 
    * @param string note_context query false 笔记的文本内容 
    * @param string note_media query false 笔记的媒体内容JSON格式 
    * @param string note_file query false 笔记关联的云盘文件ids，以英文逗号拼接 
    * @param integer create_admin_id query false 记录的创建者 
    * @param integer update_admin_id query false 记录的更新者 
    * @param integer create_time_min query false 记录的创建时间开始 
    * @param integer create_time_max query false 记录的创建时间结束 
    * @param integer update_time_min query false 记录的更新时间开始 
    * @param integer update_time_max query false 记录的更新时间结束 
    * @param integer page query false 要读取第几页 
    * @param integer each_page query false 每页记录条数 
    * @param string order_field query false 排序字段；id: ID 
    * @param string order_type query false 排序方式；asc:升序,desc:降序 
    * @param string group_by query false 聚合方式 
    * @param string having query false 聚合后的筛选方式 
    *
    **/
    Iot_autoLearn_noteListGet(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOLEARN_NOTELISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoLearn_noteListGet');
        //相关参数
  
        getParam.studio_id = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        getParam.studio_group_id = typeof(data.studio_group_id) !="undefined" ?  data.studio_group_id:0  
        getParam.user_id = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        getParam.note_context = typeof(data.note_context) !="undefined" ?  data.note_context:''  
        getParam.note_media = typeof(data.note_media) !="undefined" ?  data.note_media:''  
        getParam.note_file = typeof(data.note_file) !="undefined" ?  data.note_file:''  
        getParam.create_admin_id = typeof(data.create_admin_id) !="undefined" ?  data.create_admin_id:0  
        getParam.update_admin_id = typeof(data.update_admin_id) !="undefined" ?  data.update_admin_id:0  
        getParam.create_time_min = typeof(data.create_time_min) !="undefined" ?  data.create_time_min:0  
        getParam.create_time_max = typeof(data.create_time_max) !="undefined" ?  data.create_time_max:0  
        getParam.update_time_min = typeof(data.update_time_min) !="undefined" ?  data.update_time_min:0  
        getParam.update_time_max = typeof(data.update_time_max) !="undefined" ?  data.update_time_max:0  
        getParam.page = typeof(data.page) !="undefined" ?  data.page:0  
        getParam.each_page = typeof(data.each_page) !="undefined" ?  data.each_page:0  
        getParam.order_field = typeof(data.order_field) !="undefined" ?  data.order_field:''  
        getParam.order_type = typeof(data.order_type) !="undefined" ?  data.order_type:''  
        getParam.group_by = typeof(data.group_by) !="undefined" ?  data.group_by:''  
        getParam.having = typeof(data.having) !="undefined" ?  data.having:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 学习笔记=>获取工作室内学习笔记分页列表，工作室内成员有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer user_id query false 查询人的标识；可以为0；为0表示查看全工作室 
    * @param string keyword query false 关键字查询 
    * @param integer page query true 分页数 
    * @param integer each_page query true 分页读取数量 
    *
    **/
    Teaching_studio_apiLearn_noteStudioPageGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILEARN_NOTESTUDIOPAGEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLearn_noteStudioPageGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.user_id = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        getParam.keyword = typeof(data.keyword) !="undefined" ?  data.keyword:''  
        getParam.page = data.page    
        getParam.each_page = data.each_page    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 学习笔记=>获取我的学习笔记分页列表，创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string keyword query false 关键字查询 
    * @param integer page query true 分页数 
    * @param integer each_page query true 分页读取数量 
    *
    **/
    Teaching_studio_apiLearn_noteMinePageGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILEARN_NOTEMINEPAGEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLearn_noteMinePageGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.keyword = typeof(data.keyword) !="undefined" ?  data.keyword:''  
        getParam.page = data.page    
        getParam.each_page = data.each_page    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 学习笔记=>删除
    * @param integer id query false noComment 
    * @param integer studio_id query false 工作室ID 
    * @param integer studio_group_id query false 工作室组ID 
    * @param integer user_id query false 文件上传的用户id 
    * @param string note_context query false 笔记的文本内容 
    * @param string note_media query false 笔记的媒体内容JSON格式 
    * @param string note_file query false 笔记关联的云盘文件ids，以英文逗号拼接 
    * @param integer admin_id query false 删除人ID 
    *
    **/
    Iot_autoLearn_noteDelDelete(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOLEARN_NOTEDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoLearn_noteDelDelete');
        //相关参数
  
        getParam.id = typeof(data.id) !="undefined" ?  data.id:0  
        getParam.studio_id = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        getParam.studio_group_id = typeof(data.studio_group_id) !="undefined" ?  data.studio_group_id:0  
        getParam.user_id = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        getParam.note_context = typeof(data.note_context) !="undefined" ?  data.note_context:''  
        getParam.note_media = typeof(data.note_media) !="undefined" ?  data.note_media:''  
        getParam.note_file = typeof(data.note_file) !="undefined" ?  data.note_file:''  
        getParam.admin_id = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 学习笔记=>删除学习笔记，创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer id query true 记录唯一标识 
    *
    **/
    Teaching_studio_apiLearn_noteDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILEARN_NOTEDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLearn_noteDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室=>新增
    * @param string name formData true 工作室名称 
    * @param string img formData false 工作室LOGO 
    * @param integer wechat_id formData false 关联使用的微信平台id 
    * @param string introduce_text formData false 工作室简介：富文本 
    * @param string introduce_imgs formData false 工作室简介：图片集 
    * @param string honor formData false 成果与荣誉；富文本 
    * @param string target_text formData false 工作室目标；富文本 
    * @param string is_end formData false 工作室是否结束了;yes|no 
    * @param integer end_time formData false 如果结束了，及其结束时间 
    * @param string end_text formData false 结束后的总结性；富文本 
    * @param string logo formData false 展示用的logo 
    * @param string price_type formData false 套餐类型：free,base,high 
    * @param string banner formData false 展示横幅照片 
    *
    **/
    Teaching_studioStudiosAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIOSTUDIOSADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studioStudiosAddPost');
        //相关参数
  
        postParam.name  = data.name    
        postParam.img  = typeof(data.img) !="undefined" ?  data.img:''  
        postParam.wechat_id  = typeof(data.wechat_id) !="undefined" ?  data.wechat_id:0  
        postParam.introduce_text  = typeof(data.introduce_text) !="undefined" ?  data.introduce_text:''  
        postParam.introduce_imgs  = typeof(data.introduce_imgs) !="undefined" ?  data.introduce_imgs:''  
        postParam.honor  = typeof(data.honor) !="undefined" ?  data.honor:''  
        postParam.target_text  = typeof(data.target_text) !="undefined" ?  data.target_text:''  
        postParam.is_end  = typeof(data.is_end) !="undefined" ?  data.is_end:''  
        postParam.end_time  = typeof(data.end_time) !="undefined" ?  data.end_time:0  
        postParam.end_text  = typeof(data.end_text) !="undefined" ?  data.end_text:''  
        postParam.logo  = typeof(data.logo) !="undefined" ?  data.logo:''  
        postParam.price_type  = typeof(data.price_type) !="undefined" ?  data.price_type:''  
        postParam.banner  = typeof(data.banner) !="undefined" ?  data.banner:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室=>获取详情
    * @param integer id query true 记录id 
    *
    **/
    Teaching_studioStudiosInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIOSTUDIOSINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studioStudiosInfoGet');
        //相关参数
  
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室=>获取列表
    * @param string name query false 工作室名称 
    * @param integer wechat_id query false 关联使用的微信平台id 
    * @param string introduce_text query false 工作室简介：富文本 
    * @param string target_text query false 工作室目标；富文本 
    * @param integer page query true 要读取第几页 
    * @param integer each_page query true 每页记录条数 
    *
    **/
    Teaching_studioStudiosListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIOSTUDIOSLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studioStudiosListGet');
        //相关参数
  
        getParam.name = typeof(data.name) !="undefined" ?  data.name:''  
        getParam.wechat_id = typeof(data.wechat_id) !="undefined" ?  data.wechat_id:0  
        getParam.introduce_text = typeof(data.introduce_text) !="undefined" ?  data.introduce_text:''  
        getParam.target_text = typeof(data.target_text) !="undefined" ?  data.target_text:''  
        getParam.page = data.page    
        getParam.each_page = data.each_page    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室=>更新工作室设备前缀，主持人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string device_prefix formData true 工作室设备前缀（注意标识，设置好后提醒非必要不修改） 
    *
    **/
    Teaching_studio_apiStudioDevice_prefixPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIODEVICE_PREFIXPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioDevice_prefixPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.device_prefix  = data.device_prefix    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 工作室=>删除
    * @param integer id query true 记录id 
    *
    **/
    Teaching_studioStudiosDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIOSTUDIOSDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studioStudiosDelDelete');
        //相关参数
  
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室=>更新
    * @param integer id formData true 记录id 
    * @param string name formData false 工作室名称 
    * @param string img formData false 工作室LOGO 
    * @param integer wechat_id formData false 关联使用的微信平台id 
    * @param string introduce_text formData false 工作室简介：富文本 
    * @param string introduce_imgs formData false 工作室简介：图片集 
    * @param string honor formData false 成果与荣誉；富文本 
    * @param string target_text formData false 工作室目标；富文本 
    * @param string is_end formData false 工作室是否结束了;yes|no 
    * @param integer end_time formData false 如果结束了，及其结束时间 
    * @param string end_text formData false 结束后的总结性；富文本 
    * @param string logo formData false 展示用的logo 
    * @param string price_type formData false 套餐类型：free,base,high 
    * @param string banner formData false 展示横幅照片 
    *
    **/
    Teaching_studioStudiosUpdatePut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIOSTUDIOSUPDATEPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studioStudiosUpdatePut');
        //相关参数
  
        postParam.id  = data.id    
        postParam.name  = typeof(data.name) !="undefined" ?  data.name:''  
        postParam.img  = typeof(data.img) !="undefined" ?  data.img:''  
        postParam.wechat_id  = typeof(data.wechat_id) !="undefined" ?  data.wechat_id:0  
        postParam.introduce_text  = typeof(data.introduce_text) !="undefined" ?  data.introduce_text:''  
        postParam.introduce_imgs  = typeof(data.introduce_imgs) !="undefined" ?  data.introduce_imgs:''  
        postParam.honor  = typeof(data.honor) !="undefined" ?  data.honor:''  
        postParam.target_text  = typeof(data.target_text) !="undefined" ?  data.target_text:''  
        postParam.is_end  = typeof(data.is_end) !="undefined" ?  data.is_end:''  
        postParam.end_time  = typeof(data.end_time) !="undefined" ?  data.end_time:0  
        postParam.end_text  = typeof(data.end_text) !="undefined" ?  data.end_text:''  
        postParam.logo  = typeof(data.logo) !="undefined" ?  data.logo:''  
        postParam.price_type  = typeof(data.price_type) !="undefined" ?  data.price_type:''  
        postParam.banner  = typeof(data.banner) !="undefined" ?  data.banner:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 工作室专家=>为工作室新增一个专家，主持人权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string telphone formData true 用户的电话号码；需是系统中不能已经存在的 
    * @param string name formData true 用户名称 
    * @param string avatar formData true 专家的头像 
    *
    **/
    Teaching_studio_apiUser_expertAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSER_EXPERTADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUser_expertAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.telphone  = data.telphone    
        postParam.name  = data.name    
        postParam.avatar  = data.avatar    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室专家=>专家头像修改，主持人权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string avatar formData true 用户头像 
    * @param integer expert_user_id formData true 专家id 
    *
    **/
    Teaching_studio_apiUser_expertAvatarPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSER_EXPERTAVATARPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUser_expertAvatarPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.avatar  = data.avatar    
        postParam.expert_user_id  = data.expert_user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室专家=>修改专家的排序，主持人权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string introduce formData true 工作室介绍 
    * @param integer expert_user_id formData true 专家id 
    *
    **/
    Teaching_studio_apiUser_expertSortPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSER_EXPERTSORTPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUser_expertSortPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.introduce  = data.introduce    
        postParam.expert_user_id  = data.expert_user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室专家=>专家工作室介绍，主持人权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string introduce formData true 工作室介绍 
    * @param integer expert_user_id formData true 专家id 
    *
    **/
    Teaching_studio_apiUser_expertStudio_introPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSER_EXPERTSTUDIO_INTROPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUser_expertStudio_introPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.introduce  = data.introduce    
        postParam.expert_user_id  = data.expert_user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室专家=>专家姓名修改，主持人权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string name formData true 用户名称 
    * @param integer expert_user_id formData true 专家id 
    *
    **/
    Teaching_studio_apiUser_expertNamePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSER_EXPERTNAMEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUser_expertNamePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.name  = data.name    
        postParam.expert_user_id  = data.expert_user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室公告，公告分为整个工作室，或者小组=>新增一个公告，主持人与小组长的权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string is_all_user formData true 是否所有的用户都接收(yes|no) 
    * @param string group_ids formData false 非全体成员下参与活动的工作室小组，用英文逗号分隔开多个小组 
    * @param string title formData true 公告标题 
    * @param string intro formData true 公告简介 
    * @param string content formData true 公告内容 
    *
    **/
    Teaching_studio_apiNoticeAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APINOTICEADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiNoticeAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.is_all_user  = data.is_all_user    
        postParam.group_ids  = typeof(data.group_ids) !="undefined" ?  data.group_ids:''  
        postParam.title  = data.title    
        postParam.intro  = data.intro    
        postParam.content  = data.content    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室公告，公告分为整个工作室，或者小组=>编辑一个公告，不能改变可见范围，主持人和发布人的权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer notice_id formData true 公告id 
    * @param string title formData true 公告标题 
    * @param string intro formData true 公告简介 
    * @param string content formData true 公告内容 
    *
    **/
    Teaching_studio_apiNoticeEditPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APINOTICEEDITPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiNoticeEditPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.notice_id  = data.notice_id    
        postParam.title  = data.title    
        postParam.intro  = data.intro    
        postParam.content  = data.content    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室公告，公告分为整个工作室，或者小组=>小组的，用户可见的公告列表，主持人可见全部，其他人员可见自己小组和全体用户的
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer notice_id query true 组id 
    *
    **/
    Teaching_studio_apiNoticeInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APINOTICEINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiNoticeInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.notice_id = data.notice_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室公告，公告分为整个工作室，或者小组=>工作室的，用户可见的公告列表，主持人可见全部，其他人员可见自己小组和全体用户的,活动主持人和发布人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiNoticeStudioListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APINOTICESTUDIOLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiNoticeStudioListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室公告，公告分为整个工作室，或者小组=>可见公告的人可见，公告的查阅记录
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer notice_id query true 公告id 
    *
    **/
    Teaching_studio_apiNoticeView_recordGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APINOTICEVIEW_RECORDGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiNoticeView_recordGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.notice_id = data.notice_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室公告，公告分为整个工作室，或者小组=>可见公告的人可见，公告的发送用户对象
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer notice_id query true 组id 
    *
    **/
    Teaching_studio_apiNoticeUserGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APINOTICEUSERGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiNoticeUserGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.notice_id = data.notice_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室公告，公告分为整个工作室，或者小组=>删除一个公告，主持人和发布人的权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer notice_id query true 公告id 
    *
    **/
    Teaching_studio_apiNoticeDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APINOTICEDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiNoticeDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.notice_id = data.notice_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室公告，公告分为整个工作室，或者小组d=>模板消息的发送情况
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer notice_id query true 活动id 
    *
    **/
    Teaching_studio_apiNoticeTemplate_msgGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APINOTICETEMPLATE_MSGGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiNoticeTemplate_msgGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.notice_id = data.notice_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室成员=>为工作室新增一个用户(用户可以在其他工作内已经存在)，这里只需要基础信息，主持人操作；默认身份为学员，在操作组的时候会根据他在组里的身份下进行联动改变；组长可以加人，组长加完人后，此用户会默认在此组内
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string telphone formData true 用户的电话号码 
    * @param string name formData true 用户名称 
    * @param string avatar formData false 头像：非必须 
    * @param string work_unit formData false 工作单位：非必须 
    *
    **/
    Teaching_studio_apiMemberUserAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERUSERADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberUserAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.telphone  = data.telphone    
        postParam.name  = data.name    
        postParam.avatar  = typeof(data.avatar) !="undefined" ?  data.avatar:''  
        postParam.work_unit  = typeof(data.work_unit) !="undefined" ?  data.work_unit:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室成员=>更新用户的工作室的简介文本，自己和主持人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer user_id formData false 用户id，不传为更新自己 
    * @param string introduce_text formData true 工作室里的用户简介 
    *
    **/
    Teaching_studio_apiMemberUserIntroduce_textPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERUSERINTRODUCE_TEXTPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberUserIntroduce_textPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        postParam.introduce_text  = data.introduce_text    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室成员=>要求新用户绑定的二维码，主持人可以见所有人的，组长可以见组员的
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer user_id formData true 用户ID 
    *
    **/
    Teaching_studio_apiMemberUserQr_codePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERUSERQR_CODEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberUserQr_codePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.user_id  = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室成员=>更新自己的头像
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string avatar formData true 头像url地址 
    * @param integer user_id formData false 如果是主持人修改他人的信息；注意这个用户也得在这个工作室内 
    *
    **/
    Teaching_studio_apiUserUpdateAvatarPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSERUPDATEAVATARPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUserUpdateAvatarPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.avatar  = data.avatar    
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室成员=>更新获奖记录
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string awards formData true 获奖记录，逗号分隔多个奖项，这个数据仅仅作为数据的展示 
    * @param integer user_id formData false 如果是主持人修改他人的信息；注意这个用户也得在这个工作室内 
    *
    **/
    Teaching_studio_apiUserUpdateAwardsPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSERUPDATEAWARDSPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUserUpdateAwardsPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.awards  = data.awards    
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室成员=>更新用户的工作室的简介照片，自己和主持人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer user_id formData false 用户id，不传为更新自己 
    * @param string introduce_img formData true 工作室里的用户简介 
    *
    **/
    Teaching_studio_apiMemberUserIntroduce_imgPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERUSERINTRODUCE_IMGPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberUserIntroduce_imgPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        postParam.introduce_img  = data.introduce_img    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室成员=>其他信息的更新
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string other_intro formData true 其他信息 
    * @param integer user_id formData false 如果是主持人修改他人的信息；注意这个用户也得在这个工作室内 
    *
    **/
    Teaching_studio_apiUserUpdateOther_introPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSERUPDATEOTHER_INTROPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUserUpdateOther_introPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.other_intro  = data.other_intro    
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室成员=>更新自己的登录密码；自己以及主持人操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string md5password formData true MD5后的密码 
    * @param integer user_id formData false 如果是主持人修改他人的信息；注意这个用户也得在这个工作室内 
    *
    **/
    Teaching_studio_apiUserUpdatePasswordPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSERUPDATEPASSWORDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUserUpdatePasswordPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.md5password  = data.md5password    
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室成员=>更新自己的电话号码
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string telphone formData true 变更后的电话号码，此号码不能在系统中已经存在 
    * @param integer user_id formData false 如果是主持人修改他人的信息；注意这个用户也得在这个工作室内 
    *
    **/
    Teaching_studio_apiUserUpdateTelphonePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSERUPDATETELPHONEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUserUpdateTelphonePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.telphone  = data.telphone    
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室成员=>更新自己的工作单位
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string work_unit formData true 工作单位 
    * @param integer user_id formData false 如果是主持人修改他人的信息；注意这个用户也得在这个工作室内 
    *
    **/
    Teaching_studio_apiUserUpdateWork_unitPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSERUPDATEWORK_UNITPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUserUpdateWork_unitPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.work_unit  = data.work_unit    
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室成员=>工作内容简要介绍
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string work_intro formData true 工作内容简要介绍 
    * @param integer user_id formData false 如果是主持人修改他人的信息；注意这个用户也得在这个工作室内 
    *
    **/
    Teaching_studio_apiUserUpdateWork_introPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSERUPDATEWORK_INTROPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUserUpdateWork_introPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.work_intro  = data.work_intro    
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室成员=>更新自己的性别
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string sex formData true 性别remale||male 
    * @param integer user_id formData false 如果是主持人修改他人的信息；注意这个用户也得在这个工作室内 
    *
    **/
    Teaching_studio_apiUserUpdateSexPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSERUPDATESEXPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUserUpdateSexPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.sex  = data.sex    
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室成员=>更新自己的姓名
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string real_name formData true 变更姓名 
    * @param integer user_id formData false 如果是主持人修改他人的信息；注意这个用户也得在这个工作室内 
    *
    **/
    Teaching_studio_apiUserUpdateReal_namePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSERUPDATEREAL_NAMEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUserUpdateReal_namePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.real_name  = data.real_name    
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室成员=>工作室里的组，某个组的信息
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer group_id query true 工作室里的组ID 
    *
    **/
    Teaching_studio_apiMemberGroupInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERGROUPINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberGroupInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.group_id = data.group_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室成员=>个人档案信息，不包含成长档案，工作室成员可看
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer user_id query true 工作室里的组ID 
    *
    **/
    Teaching_studio_apiMemberInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.user_id = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室成员=>工作室的非专家之外的用户列表
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiMemberUserListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERUSERLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberUserListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室成员=>工作室里的组
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiMemberGroupGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERGROUPGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberGroupGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室成员=>工作室里组的相关统计，一次一个组
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer group_id query true 工作室里的组ID 
    *
    **/
    Teaching_studio_apiMemberGroupCountGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERGROUPCOUNTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberGroupCountGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.group_id = data.group_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室成员=>主持人、专家、信息
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiMemberHost_expertGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERHOST_EXPERTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberHost_expertGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室成员=>当前登录用户的信息，也就是我的详细信息，不包含成长档案
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiMemberLogin_userGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERLOGIN_USERGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberLogin_userGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室成员=>个人的成长档案、主要是在系统的活动的信息，包含：签到记录、心得记录、线上学习记录、线上学习心得记录、考核记录
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer user_id query true 工作室里的组ID 
    * @param integer begin_time query true 开始读取时间 
    * @param integer end_time query true 结束读取时间 
    *
    **/
    Teaching_studio_apiMemberGrowupDocGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERGROWUPDOCGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberGrowupDocGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.user_id = data.user_id    
        getParam.begin_time = data.begin_time    
        getParam.end_time = data.end_time    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室成员=>没有组的工作室成员（不包含主持人），全体成员可看
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiMemberUserNo_groupGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERUSERNO_GROUPGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberUserNo_groupGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室成员=>工作室某个组里的组员；包含组长以及组员，会输出用户最后年度的考核信息
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer group_id query true 工作室里的组ID 
    * @param string year query false 考核年份，传入才有考核信息 
    *
    **/
    Teaching_studio_apiMemberGroupUserGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERGROUPUSERGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberGroupUserGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.group_id = data.group_id    
        getParam.year = typeof(data.year) !="undefined" ?  data.year:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室成员=>成员踢出，注意小组长不能踢出，主持人操作，踢出时同时也会踢出组
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer user_id query true 要踢出的用户ID 
    *
    **/
    Teaching_studio_apiMemberUserOutDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERUSEROUTDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberUserOutDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.user_id = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室成员=>解绑某个用户的微信操作，主持人操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer user_id query true 要解绑的用户id 
    *
    **/
    Teaching_studio_apiMemberUserWechatDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERUSERWECHATDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberUserWechatDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.user_id = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室本身相关接口=>新增一个显示分类，主持人权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string name formData true 分类名称 
    * @param integer sort formData true 分类排序 
    *
    **/
    Teaching_studio_apiStudioDisplay_classAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIODISPLAY_CLASSADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioDisplay_classAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.name  = data.name    
        postParam.sort  = data.sort    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室本身相关接口=>修改工作室的考核标准
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer activity_assess_area_id query true 考核标准ID 
    *
    **/
    Teaching_studio_apiStudioActivity_assess_areaPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIOACTIVITY_ASSESS_AREAPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioActivity_assess_areaPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.activity_assess_area_id = data.activity_assess_area_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室本身相关接口=>成果与荣誉；富文本：主持人可操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string honor formData true 成果与荣誉；富文本：主持人可操作 
    *
    **/
    Teaching_studio_apiStudioHonorPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIOHONORPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioHonorPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.honor  = data.honor    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室本身相关接口=>修改Banner
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string banner_url formData true 当前工作室的banner 
    *
    **/
    Teaching_studio_apiStudioBannerPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIOBANNERPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioBannerPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.banner_url  = data.banner_url    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室本身相关接口=>工作室目标；富文本
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string target_text formData true 成果与荣誉；富文本：主持人可操作 
    *
    **/
    Teaching_studio_apiStudioTarget_textPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIOTARGET_TEXTPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioTarget_textPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.target_text  = data.target_text    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室本身相关接口=>修改Img
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string img_url formData true 当前工作室的img 
    *
    **/
    Teaching_studio_apiStudioImgPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIOIMGPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioImgPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.img_url  = data.img_url    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室本身相关接口=>修改工作室简介：主持人可操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string text formData true 简介的富文本内容 
    * @param string imgs formData false 简介的图片内容 
    *
    **/
    Teaching_studio_apiStudioIntroducePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIOINTRODUCEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioIntroducePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.text  = data.text    
        postParam.imgs  = typeof(data.imgs) !="undefined" ?  data.imgs:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室本身相关接口=>修改工作室名称：主持人可操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string name formData true 新的名称 
    *
    **/
    Teaching_studio_apiStudioNamePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIONAMEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioNamePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.name  = data.name    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室本身相关接口=>结束工作室
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiStudioIs_endPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIOIS_ENDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioIs_endPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室本身相关接口=>结束后的总结
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string end_text formData true 工作室结束后的总结 
    *
    **/
    Teaching_studio_apiStudioEnd_textPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIOEND_TEXTPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioEnd_textPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.end_text  = data.end_text    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室本身相关接口=>修改logo
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string logo_url formData true 当前工作室的logo 
    *
    **/
    Teaching_studio_apiStudioLogoPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIOLOGOPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioLogoPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.logo_url  = data.logo_url    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室本身相关接口=>当前工作室的考核细则列表，工作室人员可以获取，可能返回为空
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiStudioActivity_assessListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIOACTIVITY_ASSESSLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioActivity_assessListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室本身相关接口=>系统登记的所有的功能
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiStudioDisplay_listAll_funcGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIODISPLAY_LISTALL_FUNCGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioDisplay_listAll_funcGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室本身相关接口=>更新一个显示分类下面的功能列表，主持人权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer classes_id formData true 显示分类id 
    * @param array funcs formData true 分类下的功能列表 
    *
    **/
    Teaching_studio_apiStudioDisplay_listChangePut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIODISPLAY_LISTCHANGEPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioDisplay_listChangePut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.classes_id  = data.classes_id    
        postParam.funcs  = data.funcs    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 工作室本身相关接口=>删除一个显示分类，主持人权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer classes_id query true 显示分类id 
    *
    **/
    Teaching_studio_apiStudioDisplay_classDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIODISPLAY_CLASSDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioDisplay_classDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.classes_id = data.classes_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室本身相关接口=>更新一个显示分类，主持人权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer classes_id formData true 显示分类id 
    * @param string name formData true 分类名称 
    * @param integer sort formData true 分类的排序 
    *
    **/
    Teaching_studio_apiStudioDisplay_classUpdatePut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIODISPLAY_CLASSUPDATEPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioDisplay_classUpdatePut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.classes_id  = data.classes_id    
        postParam.name  = data.name    
        postParam.sort  = data.sort    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 工作室直播讲座=>发送聊天记录，rtm由前端发送，不需要验证表单
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer live_id formData true 直播id 
    * @param string content formData true 聊天内容 
    *
    **/
    Teaching_studio_apiLiveChatAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVECHATADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveChatAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.live_id  = data.live_id    
        postParam.content  = data.content    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室直播讲座=>新增心得，可以在直播结束后新增，一次直播对一个用户只产生一个心得记录，总是后面的覆盖前面的
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer live_id formData true 直播id 
    * @param string content formData true 心得内容 
    * @param string imgs formData true 心得照片，多张照片以英文逗号分隔 
    *
    **/
    Teaching_studio_apiLiveExpreienceAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVEEXPREIENCEADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveExpreienceAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.live_id  = data.live_id    
        postParam.content  = data.content    
        postParam.imgs  = data.imgs    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室直播讲座=>打卡，用户在观看过程中，前端定时调用
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer live_id formData true 直播id 
    *
    **/
    Teaching_studio_apiLiveSignAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVESIGNADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveSignAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.live_id  = data.live_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室直播讲座=>开始一场直播，主持人和发布人可以进行开启，开启后才能推流但是聊天是一直可以的
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer live_id formData true 直播id 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    *
    **/
    Teaching_studio_apiLiveBeginPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVEBEGINPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveBeginPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.live_id  = data.live_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室直播讲座=>新建一场直播，只有主持人可以新建
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer host_user_id formData true 直播人id，通过邀请码进入的这里可能为0 
    * @param string title formData true 直播活动标题 
    * @param integer begin_time formData true 直播预计开始时间 
    *
    **/
    Teaching_studio_apiLiveCreatePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVECREATEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveCreatePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.host_user_id  = data.host_user_id    
        postParam.title  = data.title    
        postParam.begin_time  = data.begin_time    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室直播讲座=>直播详情，如果处于直播中，会进行签到操作，此接口需要用户权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer live_id query true 直播ID 
    *
    **/
    Teaching_studio_apiLiveLiveInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVELIVEINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveLiveInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.live_id = data.live_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室直播讲座=>直播列表，按时间排列一次性读取所有的简要信息
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiLiveLiveListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVELIVELISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveLiveListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室直播讲座=>直播讲座的某个用户的心得，工作室成员可见
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer live_id query true 直播ID 
    * @param integer user_id query true 查看哪位用户的 
    *
    **/
    Teaching_studio_apiLiveLiveUserExpreienceGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVELIVEUSEREXPREIENCEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveLiveUserExpreienceGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.live_id = data.live_id    
        getParam.user_id = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室直播讲座=>直播列表，按时间排列一次性读取所有的简要信息
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiLiveLiveNowGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVELIVENOWGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveLiveNowGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室直播讲座=>直播的心得记录，工作室成员可见
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer live_id query true 直播ID 
    *
    **/
    Teaching_studio_apiLiveLiveExpreienceGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVELIVEEXPREIENCEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveLiveExpreienceGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.live_id = data.live_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室直播讲座=>直播的签到记录，工作室成员可见
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer live_id query true 直播ID 
    *
    **/
    Teaching_studio_apiLiveLiveChatGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVELIVECHATGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveLiveChatGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.live_id = data.live_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室直播讲座=>拉取直播的RTC信息，只能拉取正在直播中的信息
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer live_id query true 直播id 
    *
    **/
    Teaching_studio_apiLiveRtcGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVERTCGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveRtcGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.live_id = data.live_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室直播讲座=>直播邀请码，只有处于直播中或者直播前的直播才能生成二维码；主持人或者讲座主持人或者讲座创建者
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer live_id query true 直播ID 
    *
    **/
    Teaching_studio_apiLiveLiveQrcodeGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVELIVEQRCODEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveLiveQrcodeGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.live_id = data.live_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室直播讲座=>直播的签到记录，工作室成员可见
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer live_id query true 直播ID 
    *
    **/
    Teaching_studio_apiLiveLiveViewGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVELIVEVIEWGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveLiveViewGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.live_id = data.live_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室直播讲座=>停止一场直播，主持人、发布人，直播主持人都可以停止一场直播
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer live_id formData true 直播记录的ID 
    *
    **/
    Teaching_studio_apiLiveStopPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVESTOPPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveStopPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.live_id  = data.live_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 工作室直播讲座=>删除一场直播；主持人和发布人可以删除，删除后记录也会被删除
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer live_id query true 直播id 
    *
    **/
    Teaching_studio_apiLiveDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVEDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.live_id = data.live_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室组=>在工作室里面新增小组，会检测组是否重名，重名则会增加失败
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string group_name formData true 组名称 
    * @param string group_img formData true 小组logo；系统应该赋予默认的logo 
    * @param integer sort formData true 排序，越大越靠前 
    * @param integer leader_user_id formData false 组长 
    *
    **/
    Teaching_studio_apiMemberGroupAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERGROUPADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberGroupAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.group_name  = data.group_name    
        postParam.group_img  = data.group_img    
        postParam.sort  = data.sort    
        postParam.leader_user_id  = typeof(data.leader_user_id) !="undefined" ?  data.leader_user_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室组=>新增组员，主持人操作，组长操作，这些人必须不在任何一组
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer group_id formData true 组id 
    * @param integer user_id formData true 新增组内用户 
    *
    **/
    Teaching_studio_apiMemberGroupMember_addPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERGROUPMEMBER_ADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberGroupMember_addPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.group_id  = data.group_id    
        postParam.user_id  = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室组=>更新组信息，只允许 更新组名、logo、sort(无论是否变化，都需要提交)
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer group_id formData true 组id 
    * @param string group_name formData true 组名称 
    * @param string group_img formData true 小组logo；系统应该赋予默认的logo 
    * @param integer sort formData true 排序，越大越靠前 
    *
    **/
    Teaching_studio_apiMemberGroupUpinfoPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERGROUPUPINFOPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberGroupUpinfoPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.group_id  = data.group_id    
        postParam.group_name  = data.group_name    
        postParam.group_img  = data.group_img    
        postParam.sort  = data.sort    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室组=>更换组长，主持人操作，且此人不能在其他组
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer group_id formData true 组id 
    * @param integer leader_user_id formData true 新增组长用户ID 
    *
    **/
    Teaching_studio_apiMemberGroupChangeLeaderPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERGROUPCHANGELEADERPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberGroupChangeLeaderPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.group_id  = data.group_id    
        postParam.leader_user_id  = data.leader_user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室组=>变更排序，主持人操作，组长操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer group_id formData true 组id 
    * @param integer user_id formData true 新增组内用户 
    * @param integer sort formData true 调整组员排序 
    *
    **/
    Teaching_studio_apiMemberGroupMember_sortPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERGROUPMEMBER_SORTPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberGroupMember_sortPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.group_id  = data.group_id    
        postParam.user_id  = data.user_id    
        postParam.sort  = data.sort    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室组=>组员踢出(不能踢组长)，主持人操作，组长操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer group_id query true 组id 
    * @param integer user_id query true 新增组内用户 
    *
    **/
    Teaching_studio_apiMemberGroupMember_outDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERGROUPMEMBER_OUTDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberGroupMember_outDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.group_id = data.group_id    
        getParam.user_id = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室组=>删除组，主持人操作，操作后组里的用户会变成无组；此组关联的用户都会踢出此组
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer group_id query true 组id 
    *
    **/
    Teaching_studio_apiMemberGroupDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEMBERGROUPDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMemberGroupDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.group_id = data.group_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室规则制度=>新增规则，主持人权限，标题在系统内不能重复
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string title formData true 章程名称 
    * @param string content formData true 章程内容 
    *
    **/
    Teaching_studio_apiRuleAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIRULEADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiRuleAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.title  = data.title    
        postParam.content  = data.content    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室规则制度=>新增规则，主持人权限，标题在系统内不能重复
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer rule_id formData true 要修改的规则 
    * @param string title formData true 章程名称 
    * @param string content formData true 章程内容 
    *
    **/
    Teaching_studio_apiRuleEditPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIRULEEDITPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiRuleEditPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.rule_id  = data.rule_id    
        postParam.title  = data.title    
        postParam.content  = data.content    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室规则制度=>具体的规则详情，所有人都可见
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer rule_id query true 规则id 
    *
    **/
    Teaching_studio_apiRuleInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIRULEINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiRuleInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.rule_id = data.rule_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室规则制度=>工作室的规则列表，所有人都可见
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiRuleListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIRULELISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiRuleListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室规则制度=>删除规则，主持人权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer rule_id query true 要修改的规则 
    *
    **/
    Teaching_studio_apiRuleDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIRULEDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiRuleDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.rule_id = data.rule_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室计划与总结=>新增一个，主持人与小组长的权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer studio_group_id formData false 是否是组的 
    * @param string year formData false 哪个年度 
    * @param string content_type formData true 标志时计划还是总结；plan||summary 
    * @param string title formData true 标题长度 
    * @param string content formData true 内容 
    *
    **/
    Teaching_studio_apiPlan_summaryAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIPLAN_SUMMARYADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiPlan_summaryAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.studio_group_id  = typeof(data.studio_group_id) !="undefined" ?  data.studio_group_id:0  
        postParam.year  = typeof(data.year) !="undefined" ?  data.year:''  
        postParam.content_type  = data.content_type    
        postParam.title  = data.title    
        postParam.content  = data.content    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室计划与总结=>编辑一个，不能改变可见范围，主持人与发布人的权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string record_id formData true 记录ID 
    * @param string year formData false 哪个年度 
    * @param string content_type formData true 标志时计划还是总结；plan||summary 
    * @param string title formData true 标题长度 
    * @param string content formData true 内容 
    *
    **/
    Teaching_studio_apiPlan_summaryEditPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIPLAN_SUMMARYEDITPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiPlan_summaryEditPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.record_id  = data.record_id    
        postParam.year  = typeof(data.year) !="undefined" ?  data.year:''  
        postParam.content_type  = data.content_type    
        postParam.title  = data.title    
        postParam.content  = data.content    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室计划与总结=>具体的计划于总结详情，全部人可见
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer record_id query true 记录id 
    *
    **/
    Teaching_studio_apiPlan_summaryInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIPLAN_SUMMARYINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiPlan_summaryInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.record_id = data.record_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室计划与总结=>组级别的计划与总结，主持人可见全部，其他人员可见自己小组和工作室的
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer group_id query true 小组id 
    *
    **/
    Teaching_studio_apiPlan_summaryGroupListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIPLAN_SUMMARYGROUPLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiPlan_summaryGroupListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.group_id = data.group_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室计划与总结=>工作级别的计划与总结，主持人可见全部，其他人员可见自己小组和工作室的
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiPlan_summaryStudioListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIPLAN_SUMMARYSTUDIOLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiPlan_summaryStudioListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室计划与总结=>删除一个，不能改变可见范围，主持人与发布人的权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string record_id query true 记录ID 
    *
    **/
    Teaching_studio_apiPlan_summaryDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIPLAN_SUMMARYDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiPlan_summaryDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.record_id = data.record_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室评价[废弃]=>添加自评分，当年没有才可以进行操作，只可以自我操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string year formData true 哪个年度;一般来说只能是当前年度或者上一年度 
    * @param integer self_num formData true 自评分，注意是整数，且不能是零分 
    * @param string self_text formData true 自评文本内容 
    *
    **/
    Teaching_studio_apiEvaluateSelfChangePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIEVALUATESELFCHANGEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiEvaluateSelfChangePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.year  = data.year    
        postParam.self_num  = data.self_num    
        postParam.self_text  = data.self_text    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室评价[废弃]=>综合评分改变，主持人操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string year formData true 哪个年度;一般来说只能是当前年度或者上一年度 
    * @param integer result_num formData true 自评分，注意是整数，且不能是零分 
    * @param integer user_id formData true 评价对象用户id 
    *
    **/
    Teaching_studio_apiEvaluateSynthesizeChangePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIEVALUATESYNTHESIZECHANGEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiEvaluateSynthesizeChangePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.year  = data.year    
        postParam.result_num  = data.result_num    
        postParam.user_id  = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室评价[废弃]=>工作室评分的改变，主持人或者组长操作，注意组长不能自我操作，当综合评分定了后，就不能再次修改
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string year formData true 哪个年度;一般来说只能是当前年度或者上一年度 
    * @param integer studio_num formData true 自评分，注意是整数，且不能是零分 
    * @param string studio_text formData true 自评文本内容 
    * @param integer user_id formData true 评价对象用户id 
    *
    **/
    Teaching_studio_apiEvaluateStudioChangePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIEVALUATESTUDIOCHANGEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiEvaluateStudioChangePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.year  = data.year    
        postParam.studio_num  = data.studio_num    
        postParam.studio_text  = data.studio_text    
        postParam.user_id  = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 工作室评价[废弃]=>某个年度，整个工作室的某年度自评排名，全体成员可看
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string year query true 哪个年度 
    * @param string sort_type query true 哪个排名self||studio||result 
    *
    **/
    Teaching_studio_apiEvaluateSortStudioGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIEVALUATESORTSTUDIOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiEvaluateSortStudioGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.year = data.year    
        getParam.sort_type = data.sort_type    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室评价[废弃]=>某个年度，小组的自评排名，全体成员可看，有工作单位了
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string year query true 哪个年度 
    * @param integer group_id query true 哪个小组 
    * @param string sort_type query true 哪个排名self||stdio||result 
    *
    **/
    Teaching_studio_apiEvaluateSortGroupGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIEVALUATESORTGROUPGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiEvaluateSortGroupGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.year = data.year    
        getParam.group_id = data.group_id    
        getParam.sort_type = data.sort_type    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 工作室评价[废弃]=>当前工作室的某个用户的评价历史记录，支持人，组长，自己能看
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer user_id query true 哪个用户 
    *
    **/
    Teaching_studio_apiEvaluateUserGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIEVALUATEUSERGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiEvaluateUserGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.user_id = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 年度考核=>某个用户的年度学时构成的记录；自己可以看自己的，组长可以看组员的，主持人可以看所有的，这里调用的全是实际数据
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer user_id query true 要查看的用户ID 
    * @param integer assess_span_id query true 哪个年度 
    *
    **/
    Teaching_studio_apiDetailListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIDETAILLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiDetailListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.user_id = data.user_id    
        getParam.assess_span_id = data.assess_span_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 年度考核=>工作室某位用户的年度考核详细，自己可以看自己的，组长可以看组员的，主持人可以看所有的
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer user_id query true 要查看的用户ID 
    * @param integer assess_span_id query true 哪个年度 
    *
    **/
    Teaching_studio_apiDetailUserGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIDETAILUSERGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiDetailUserGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.user_id = data.user_id    
        getParam.assess_span_id = data.assess_span_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 年度考核=>小组级别的年度考核详细，组长以及主持人可看
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer group_id query true 要查看的组ID 
    * @param integer assess_span_id query true 哪个年度 
    *
    **/
    Teaching_studio_apiDetailGroupGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIDETAILGROUPGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiDetailGroupGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.group_id = data.group_id    
        getParam.assess_span_id = data.assess_span_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 年度考核时段=>新增一个考核年度，注意时间上不能重叠，只有主持人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string span_name formData true 时段名称 
    * @param string begin_year formData true 时段开始的年度 
    * @param string begin_month formData true 时段开始的月度(包含关系) 
    * @param string end_year formData true 时段结束的年度 
    * @param string end_month formData true 时段结束的月度（包含关系） 
    *
    **/
    Teaching_studio_apiAssessYearAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIASSESSYEARADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiAssessYearAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.span_name  = data.span_name    
        postParam.begin_year  = data.begin_year    
        postParam.begin_month  = data.begin_month    
        postParam.end_year  = data.end_year    
        postParam.end_month  = data.end_month    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 年度考核时段=>获取当前登录工作室的某个年度的详情，记录的本身信息
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer span_id query true 记录id 
    *
    **/
    Teaching_studio_apiAssessYearInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIASSESSYEARINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiAssessYearInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.span_id = data.span_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 年度考核时段=>获取当前登录工作室的考核的年度列表，不分页,
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string span_name query true 时段名称 
    *
    **/
    Teaching_studio_apiAssessYearListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIASSESSYEARLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiAssessYearListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.span_name = data.span_name    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 年度考核时段=>删除一个考核年度信息
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer span_id query true 记录id 
    *
    **/
    Teaching_studio_apiAssessYearDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIASSESSYEARDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiAssessYearDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.span_id = data.span_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 年度考核时段=>更新一个考核年度信息，注意时间上不能重叠，只有主持人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer span_id formData true 记录id 
    * @param string span_name formData true 时段名称 
    * @param string begin_year formData true 时段开始的年度 
    * @param string begin_month formData true 时段开始的月度(包含关系) 
    * @param string end_year formData true 时段结束的年度 
    * @param string end_month formData true 时段结束的月度（包含关系） 
    *
    **/
    Teaching_studio_apiAssessYearUpPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIASSESSYEARUPPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiAssessYearUpPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.span_id  = data.span_id    
        postParam.span_name  = data.span_name    
        postParam.begin_year  = data.begin_year    
        postParam.begin_month  = data.begin_month    
        postParam.end_year  = data.end_year    
        postParam.end_month  = data.end_month    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 报警范围的设置的快捷方式=>新增
    * @param string product_key formData false 产品key；支持多个产品，用逗号分隔 
    * @param string property_key formData false 属性key；支持多个属性，用逗号分隔 
    * @param string name formData false 快捷方式名称 
    * @param string normal_range formData false 正常范围的描述，格式：min,max 
    * @param string low_range formData false 低强度报警范围的描述，格式：min,max 
    * @param string high_range formData false 高强度报警范围的描述，格式：min,max 
    * @param integer admin_id formData false 新增人ID 
    *
    **/
    Iot_autoWarning_range_quickAddPost(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOWARNING_RANGE_QUICKADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoWarning_range_quickAddPost');
        //相关参数
  
        postParam.product_key  = typeof(data.product_key) !="undefined" ?  data.product_key:''  
        postParam.property_key  = typeof(data.property_key) !="undefined" ?  data.property_key:''  
        postParam.name  = typeof(data.name) !="undefined" ?  data.name:''  
        postParam.normal_range  = typeof(data.normal_range) !="undefined" ?  data.normal_range:''  
        postParam.low_range  = typeof(data.low_range) !="undefined" ?  data.low_range:''  
        postParam.high_range  = typeof(data.high_range) !="undefined" ?  data.high_range:''  
        postParam.admin_id  = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 报警范围的设置的快捷方式=>新增设备属性报警快捷方式，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string product_key formData true 产品key；支持多个产品，用逗号分隔 
    * @param string property_key formData true 属性key；支持多个属性，用逗号分隔 
    * @param string name formData true 名字 
    * @param string normal_range formData false 正常范围的描述，格式：min,max 
    * @param string low_range formData false 低强度报警范围的描述，格式：min,max 
    * @param string high_range formData false 高强度报警范围的描述，格式：min,max 
    *
    **/
    Teaching_studio_apiWarning_range_quickAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWARNING_RANGE_QUICKADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWarning_range_quickAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.product_key  = data.product_key    
        postParam.property_key  = data.property_key    
        postParam.name  = data.name    
        postParam.normal_range  = typeof(data.normal_range) !="undefined" ?  data.normal_range:''  
        postParam.low_range  = typeof(data.low_range) !="undefined" ?  data.low_range:''  
        postParam.high_range  = typeof(data.high_range) !="undefined" ?  data.high_range:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 报警范围的设置的快捷方式=>更新
    * @param integer id formData false noComment 
    * @param string product_key formData false 产品key；支持多个产品，用逗号分隔 
    * @param string property_key formData false 属性key；支持多个属性，用逗号分隔 
    * @param string name formData false 快捷方式名称 
    * @param string normal_range formData false 正常范围的描述，格式：min,max 
    * @param string low_range formData false 低强度报警范围的描述，格式：min,max 
    * @param string high_range formData false 高强度报警范围的描述，格式：min,max 
    * @param integer admin_id formData false 更新人ID 
    *
    **/
    Iot_autoWarning_range_quickUpdatePost(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOWARNING_RANGE_QUICKUPDATEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoWarning_range_quickUpdatePost');
        //相关参数
  
        postParam.id  = typeof(data.id) !="undefined" ?  data.id:0  
        postParam.product_key  = typeof(data.product_key) !="undefined" ?  data.product_key:''  
        postParam.property_key  = typeof(data.property_key) !="undefined" ?  data.property_key:''  
        postParam.name  = typeof(data.name) !="undefined" ?  data.name:''  
        postParam.normal_range  = typeof(data.normal_range) !="undefined" ?  data.normal_range:''  
        postParam.low_range  = typeof(data.low_range) !="undefined" ?  data.low_range:''  
        postParam.high_range  = typeof(data.high_range) !="undefined" ?  data.high_range:''  
        postParam.admin_id  = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 报警范围的设置的快捷方式=>获取设备属性报警快捷方式详细信息，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer quick_id query true 记录标识 
    *
    **/
    Teaching_studio_apiWarning_range_quickInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWARNING_RANGE_QUICKINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWarning_range_quickInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.quick_id = data.quick_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 报警范围的设置的快捷方式=>获取详情
    * @param integer id query false noComment 
    * @param string product_key query false 产品key；支持多个产品，用逗号分隔 
    * @param string property_key query false 属性key；支持多个属性，用逗号分隔 
    * @param string name query false 快捷方式名称 
    * @param string normal_range query false 正常范围的描述，格式：min,max 
    * @param string low_range query false 低强度报警范围的描述，格式：min,max 
    * @param string high_range query false 高强度报警范围的描述，格式：min,max 
    *
    **/
    Iot_autoWarning_range_quickInfoGet(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOWARNING_RANGE_QUICKINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoWarning_range_quickInfoGet');
        //相关参数
  
        getParam.id = typeof(data.id) !="undefined" ?  data.id:0  
        getParam.product_key = typeof(data.product_key) !="undefined" ?  data.product_key:''  
        getParam.property_key = typeof(data.property_key) !="undefined" ?  data.property_key:''  
        getParam.name = typeof(data.name) !="undefined" ?  data.name:''  
        getParam.normal_range = typeof(data.normal_range) !="undefined" ?  data.normal_range:''  
        getParam.low_range = typeof(data.low_range) !="undefined" ?  data.low_range:''  
        getParam.high_range = typeof(data.high_range) !="undefined" ?  data.high_range:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 报警范围的设置的快捷方式=>获取设备的属性报警快捷方式列表，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string product_key query false 产品标识 
    * @param string property_key query false 属性标识 
    * @param string name query false 名字 
    *
    **/
    Teaching_studio_apiWarning_range_quickListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWARNING_RANGE_QUICKLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWarning_range_quickListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.product_key = typeof(data.product_key) !="undefined" ?  data.product_key:''  
        getParam.property_key = typeof(data.property_key) !="undefined" ?  data.property_key:''  
        getParam.name = typeof(data.name) !="undefined" ?  data.name:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 报警范围的设置的快捷方式=>获取列表
    * @param string product_key query false 产品key；支持多个产品，用逗号分隔 
    * @param string property_key query false 属性key；支持多个属性，用逗号分隔 
    * @param string name query false 快捷方式名称 
    * @param string normal_range query false 正常范围的描述，格式：min,max 
    * @param string low_range query false 低强度报警范围的描述，格式：min,max 
    * @param string high_range query false 高强度报警范围的描述，格式：min,max 
    * @param integer create_admin_id query false 记录的创建者 
    * @param integer update_admin_id query false 记录的更新者 
    * @param integer create_time_min query false 记录的创建时间开始 
    * @param integer create_time_max query false 记录的创建时间结束 
    * @param integer update_time_min query false 记录的更新时间开始 
    * @param integer update_time_max query false 记录的更新时间结束 
    * @param integer page query false 要读取第几页 
    * @param integer each_page query false 每页记录条数 
    * @param string order_field query false 排序字段；id: ID 
    * @param string order_type query false 排序方式；asc:升序,desc:降序 
    * @param string group_by query false 聚合方式 
    * @param string having query false 聚合后的筛选方式 
    *
    **/
    Iot_autoWarning_range_quickListGet(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOWARNING_RANGE_QUICKLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoWarning_range_quickListGet');
        //相关参数
  
        getParam.product_key = typeof(data.product_key) !="undefined" ?  data.product_key:''  
        getParam.property_key = typeof(data.property_key) !="undefined" ?  data.property_key:''  
        getParam.name = typeof(data.name) !="undefined" ?  data.name:''  
        getParam.normal_range = typeof(data.normal_range) !="undefined" ?  data.normal_range:''  
        getParam.low_range = typeof(data.low_range) !="undefined" ?  data.low_range:''  
        getParam.high_range = typeof(data.high_range) !="undefined" ?  data.high_range:''  
        getParam.create_admin_id = typeof(data.create_admin_id) !="undefined" ?  data.create_admin_id:0  
        getParam.update_admin_id = typeof(data.update_admin_id) !="undefined" ?  data.update_admin_id:0  
        getParam.create_time_min = typeof(data.create_time_min) !="undefined" ?  data.create_time_min:0  
        getParam.create_time_max = typeof(data.create_time_max) !="undefined" ?  data.create_time_max:0  
        getParam.update_time_min = typeof(data.update_time_min) !="undefined" ?  data.update_time_min:0  
        getParam.update_time_max = typeof(data.update_time_max) !="undefined" ?  data.update_time_max:0  
        getParam.page = typeof(data.page) !="undefined" ?  data.page:0  
        getParam.each_page = typeof(data.each_page) !="undefined" ?  data.each_page:0  
        getParam.order_field = typeof(data.order_field) !="undefined" ?  data.order_field:''  
        getParam.order_type = typeof(data.order_type) !="undefined" ?  data.order_type:''  
        getParam.group_by = typeof(data.group_by) !="undefined" ?  data.group_by:''  
        getParam.having = typeof(data.having) !="undefined" ?  data.having:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 报警范围的设置的快捷方式=>更新设备属性报警设置：工作室内主持人、创建人有次限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer quick_id formData true 记录id 
    * @param string product_key formData true 产品key；支持多个产品，用逗号分隔 
    * @param string property_key formData true 属性key；支持多个属性，用逗号分隔 
    * @param string name formData true 名字 
    * @param string normal_range formData false 正常范围的描述，格式：min,max 
    * @param string low_range formData false 低强度报警范围的描述，格式：min,max 
    * @param string high_range formData false 高强度报警范围的描述，格式：min,max 
    *
    **/
    Teaching_studio_apiWarning_range_quickUpPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWARNING_RANGE_QUICKUPPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWarning_range_quickUpPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.quick_id  = data.quick_id    
        postParam.product_key  = data.product_key    
        postParam.property_key  = data.property_key    
        postParam.name  = data.name    
        postParam.normal_range  = typeof(data.normal_range) !="undefined" ?  data.normal_range:''  
        postParam.low_range  = typeof(data.low_range) !="undefined" ?  data.low_range:''  
        postParam.high_range  = typeof(data.high_range) !="undefined" ?  data.high_range:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 报警范围的设置的快捷方式=>删除
    * @param integer id query false noComment 
    * @param string product_key query false 产品key；支持多个产品，用逗号分隔 
    * @param string property_key query false 属性key；支持多个属性，用逗号分隔 
    * @param string name query false 快捷方式名称 
    * @param string normal_range query false 正常范围的描述，格式：min,max 
    * @param string low_range query false 低强度报警范围的描述，格式：min,max 
    * @param string high_range query false 高强度报警范围的描述，格式：min,max 
    * @param integer admin_id query false 删除人ID 
    *
    **/
    Iot_autoWarning_range_quickDelDelete(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOWARNING_RANGE_QUICKDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoWarning_range_quickDelDelete');
        //相关参数
  
        getParam.id = typeof(data.id) !="undefined" ?  data.id:0  
        getParam.product_key = typeof(data.product_key) !="undefined" ?  data.product_key:''  
        getParam.property_key = typeof(data.property_key) !="undefined" ?  data.property_key:''  
        getParam.name = typeof(data.name) !="undefined" ?  data.name:''  
        getParam.normal_range = typeof(data.normal_range) !="undefined" ?  data.normal_range:''  
        getParam.low_range = typeof(data.low_range) !="undefined" ?  data.low_range:''  
        getParam.high_range = typeof(data.high_range) !="undefined" ?  data.high_range:''  
        getParam.admin_id = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 报警范围的设置的快捷方式=>删除设备属性报警快捷方式，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer quick_id query true 记录id 
    *
    **/
    Teaching_studio_apiWarning_range_quickDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWARNING_RANGE_QUICKDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWarning_range_quickDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.quick_id = data.quick_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 报警范围的设置的快捷方式=>快捷设置设备的报警，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer quick_id formData true 记录id 
    * @param integer property_id formData true 要设置的设备属性id 
    *
    **/
    Teaching_studio_apiWarning_range_quickToDevice_property_warningPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWARNING_RANGE_QUICKTODEVICE_PROPERTY_WARNINGPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWarning_range_quickToDevice_property_warningPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.quick_id  = data.quick_id    
        postParam.property_id  = data.property_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 服务器向客户端推送实时数据=>服务器向客户端推送实时数据
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiSseSendGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISSESENDGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiSseSendGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 活动=>活动的相册，对活动可见的人员，此接口可见
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer photo_id query true 相册记录id 
    * @param integer activity_id query true 活动id 
    *
    **/
    Teaching_studio_apiActivityPhoto_infoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITYPHOTO_INFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivityPhoto_infoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.photo_id = data.photo_id    
        getParam.activity_id = data.activity_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 活动=>活动的详情，只拉取活动本身的信息，不拉取签到记录、相册、心得
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer activity_id query true 活动id 
    *
    **/
    Teaching_studio_apiActivityInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITYINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivityInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.activity_id = data.activity_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 活动=>分类下面的活动[分页的]，主持人可以看见所有活动，其他只能看见可见的活动，默认展示正在进行中的，可以分为未开始的、已结束的、正在进行中的
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string list_type query true 进行中，未开始，已结束[doing||wait||done||none] 
    * @param integer page query true 要读取的页数 
    * @param integer each_page query true 每页条数 
    * @param integer classes_id query false 分类的筛选 
    * @param string name query false 活动名称的筛选 
    *
    **/
    Teaching_studio_apiActivityNewestGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITYNEWESTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivityNewestGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.list_type = data.list_type    
        getParam.page = data.page    
        getParam.each_page = data.each_page    
        getParam.classes_id = typeof(data.classes_id) !="undefined" ?  data.classes_id:0  
        getParam.name = typeof(data.name) !="undefined" ?  data.name:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 活动=>活动分类，当前工作室成员可见，按照排序拉取分类
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiActivityClassesGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITYCLASSESGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivityClassesGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 活动=>活动的心得记录，对活动可见的人员，此接口可见
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer activity_id query true 活动id 
    *
    **/
    Teaching_studio_apiActivityExpreience_recordGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITYEXPREIENCE_RECORDGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivityExpreience_recordGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.activity_id = data.activity_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 活动=>模板消息的发送情况
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer activity_id query true 活动id 
    *
    **/
    Teaching_studio_apiActivityTemplate_msgGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITYTEMPLATE_MSGGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivityTemplate_msgGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.activity_id = data.activity_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 活动=>活动中某人的心得，对活动的人员可见，默认获取本人的心得
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer activity_id query true 活动id 
    * @param integer user_id query false 用户ID 
    *
    **/
    Teaching_studio_apiActivityExpreienceUserGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITYEXPREIENCEUSERGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivityExpreienceUserGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.activity_id = data.activity_id    
        getParam.user_id = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 活动=>活动的签到码，主持人和活动发布者可以拉取；活动进行后无法再拉取活动签到码
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer activity_id query true 活动id 
    *
    **/
    Teaching_studio_apiActivitySign_qrcodeGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITYSIGN_QRCODEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivitySign_qrcodeGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.activity_id = data.activity_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 活动=>活动的签到记录，对活动可见的人员，此接口可见
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer activity_id query true 活动id 
    *
    **/
    Teaching_studio_apiActivitySign_recordGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITYSIGN_RECORDGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivitySign_recordGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.activity_id = data.activity_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 活动=>活动的相册，对活动可见的人员，此接口可见
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer activity_id query true 活动id 
    *
    **/
    Teaching_studio_apiActivityPhotoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITYPHOTOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivityPhotoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.activity_id = data.activity_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 活动=>活动需要参与的人员，拉取活动需要参与的人员列表，对活动可见的人员，此接口可见
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer activity_id query true 活动id 
    *
    **/
    Teaching_studio_apiActivityUserGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITYUSERGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivityUserGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.activity_id = data.activity_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 活动=>某个分类下面的活动[分页]，主持人可以看见所有活动，其他只能看见可见的活动，默认展示正在进行中的，可以分为未开始的、已结束的、正在进行中的
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer classes_id query true 活动分类id 
    * @param string list_type query true 进行中，未开始，已结束[doing||wait||done||none] 
    * @param integer page query true 要读取的页数 
    * @param integer each_page query true 每页条数 
    *
    **/
    Teaching_studio_apiActivityClassesActivityGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITYCLASSESACTIVITYGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivityClassesActivityGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.classes_id = data.classes_id    
        getParam.list_type = data.list_type    
        getParam.page = data.page    
        getParam.each_page = data.each_page    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 活动本身的相关变更=>新增活动，主持人和组长可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer classes_id formData true 分类id 
    * @param integer host_user_id formData true 活动主持人 
    * @param string title formData true 活动标题 
    * @param string introduce_text formData true 活动的富文本介绍 
    * @param string is_all_user formData false 是否是全体工作室的活动；yes|no 
    * @param string group_ids formData true 非全体成员下参与活动的工作室小组，用英文逗号分隔开多个小组 
    * @param integer begin_time formData true 活动的开始时间,时间戳unix到秒 
    * @param integer end_time formData true 活动的结束时间,时间戳unix到秒 
    * @param string sign_type formData false 签到方式：code:扫码签到[默认]（必须在活动开始前扫码签到）；online:线上签到（在查阅过程中即可自动签到） 
    * @param string need_assess formData false 是否继承活动分类的考核细则[yes|no] 
    *
    **/
    Teaching_studio_apiActivity_activityAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_ACTIVITYADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_activityAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.classes_id  = data.classes_id    
        postParam.host_user_id  = data.host_user_id    
        postParam.title  = data.title    
        postParam.introduce_text  = data.introduce_text    
        postParam.is_all_user  = typeof(data.is_all_user) !="undefined" ?  data.is_all_user:''  
        postParam.group_ids  = data.group_ids    
        postParam.begin_time  = data.begin_time    
        postParam.end_time  = data.end_time    
        postParam.sign_type  = typeof(data.sign_type) !="undefined" ?  data.sign_type:''  
        postParam.need_assess  = typeof(data.need_assess) !="undefined" ?  data.need_assess:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 活动本身的相关变更=>编辑活动基础信息，不能修改分类，人员范围，主持人和发布人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer activity_id formData true 活动id 
    * @param string title formData true 活动标题 
    * @param string introduce_text formData true 活动的富文本介绍 
    * @param integer begin_time formData true 活动的开始时间,时间戳unix到秒 
    * @param integer end_time formData true 活动的结束时间,时间戳unix到秒 
    * @param string sign_type formData false 修改签到方式：code:扫码签到[默认]（必须在活动开始前扫码签到）；online:线上签到（在查阅过程中即可自动签到） 
    *
    **/
    Teaching_studio_apiActivity_activityEditPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_ACTIVITYEDITPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_activityEditPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.activity_id  = data.activity_id    
        postParam.title  = data.title    
        postParam.introduce_text  = data.introduce_text    
        postParam.begin_time  = data.begin_time    
        postParam.end_time  = data.end_time    
        postParam.sign_type  = typeof(data.sign_type) !="undefined" ?  data.sign_type:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 活动本身的相关变更=>活动总结（结束后添加），主持人、活动主持人和发布人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer activity_id formData true 活动id 
    * @param integer end_text formData true 活动id 
    *
    **/
    Teaching_studio_apiActivity_activityEnd_textPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_ACTIVITYEND_TEXTPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_activityEnd_textPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.activity_id  = data.activity_id    
        postParam.end_text  = data.end_text    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 活动本身的相关变更=>编辑活动主持人，主持人和发布人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer activity_id formData true 活动id 
    * @param integer host_user_id formData true 新的活动主持人 
    *
    **/
    Teaching_studio_apiActivity_activityChange_hostPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_ACTIVITYCHANGE_HOSTPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_activityChange_hostPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.activity_id  = data.activity_id    
        postParam.host_user_id  = data.host_user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 活动本身的相关变更=>修改活动分类，主持人，活动主持人和发布人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer activity_id formData true 活动id 
    * @param integer classes_id formData true 新的分类 
    *
    **/
    Teaching_studio_apiActivity_activityChange_classesPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_ACTIVITYCHANGE_CLASSESPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_activityChange_classesPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.activity_id  = data.activity_id    
        postParam.classes_id  = data.classes_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 活动本身的相关变更=>活动结束按钮，主持人、活动主持人和发布人可以操作；结束时需要提交心得得结束时间；结束时，如果此活动对应着考核细节，则会自动计算学时
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer activity_id formData true 活动id 
    * @param integer experience_end_time formData true 心得提交的结束时间；最早为结束时间 
    *
    **/
    Teaching_studio_apiActivity_activityEndPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_ACTIVITYENDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_activityEndPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.activity_id  = data.activity_id    
        postParam.experience_end_time  = data.experience_end_time    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 活动本身的相关变更=>活动开始按钮，主持人、活动主持人和发布人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer activity_id formData true 活动id 
    *
    **/
    Teaching_studio_apiActivity_activityBeginPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_ACTIVITYBEGINPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_activityBeginPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.activity_id  = data.activity_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 活动本身的相关变更=>活动删除，主持人、活动主持人和发布人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer activity_id query true 活动id 
    *
    **/
    Teaching_studio_apiActivity_activityDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_ACTIVITYDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_activityDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.activity_id = data.activity_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 活动本身的相关变更=>活动结束后，更新活动的学时【限制活动只能是已结束的活动，且处于当前的活动】，主持人，活动主持人和发布人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer activity_id formData true 活动id 
    * @param number learn_hour_num formData true 学时数量；最多两位小数 
    *
    **/
    Teaching_studio_apiActivity_activityLearn_hour_numPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_ACTIVITYLEARN_HOUR_NUMPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_activityLearn_hour_numPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.activity_id  = data.activity_id    
        postParam.learn_hour_num  = data.learn_hour_num    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 活动本身的相关变更=>活动文件，活动参与人可传
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer activity_id formData true 活动id 
    * @param string files formData true 文件列表 
    *
    **/
    Teaching_studio_apiActivity_activityFilesPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_ACTIVITYFILESPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_activityFilesPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.activity_id  = data.activity_id    
        postParam.files  = data.files    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 活动的心得=>心得变更，参与活动的人，进行中，一直到结束后的心得结束时间才不能进行心得提交
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string activity_id formData true 活动id 
    * @param string content formData true 心得内容 
    * @param string imgs formData false 心得照片 
    * @param string files formData false 心得分享的相关文件;url分隔开来 
    *
    **/
    Teaching_studio_apiActivity_experienceChangePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_EXPERIENCECHANGEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_experienceChangePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.activity_id  = data.activity_id    
        postParam.content  = data.content    
        postParam.imgs  = typeof(data.imgs) !="undefined" ?  data.imgs:''  
        postParam.files  = typeof(data.files) !="undefined" ?  data.files:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 活动的心得=>心得删除，主持人与活动主持人与活动创建者;注意心得发表人不能进行自我删除
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer experience_id query true 心得id 
    * @param integer activity_id query true 活动id 
    *
    **/
    Teaching_studio_apiActivity_experienceDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_EXPERIENCEDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_experienceDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.experience_id = data.experience_id    
        getParam.activity_id = data.activity_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 活动的照片=>新增活动照片，主持人,活动主持人和发布人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer activity_id formData true 活动id 
    * @param string imgs formData true 照片 
    *
    **/
    Teaching_studio_apiActivity_photoAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_PHOTOADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_photoAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.activity_id  = data.activity_id    
        postParam.imgs  = data.imgs    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 活动的照片=>编辑活动照片（比如删除其中一张），主持人，活动主持人和发布人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer activity_id formData true 活动id 
    * @param string imgs formData true 照片 
    * @param integer record_id formData true 记录ID 
    *
    **/
    Teaching_studio_apiActivity_photoEditPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_PHOTOEDITPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_photoEditPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.activity_id  = data.activity_id    
        postParam.imgs  = data.imgs    
        postParam.record_id  = data.record_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 活动的照片=>删除活动照片，主持人，活动主持人和发布人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer activity_id query true 活动id 
    * @param integer record_id query true 记录ID 
    *
    **/
    Teaching_studio_apiActivity_photoDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_PHOTODELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_photoDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.activity_id = data.activity_id    
        getParam.record_id = data.record_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 活动签到的变更=>进行签到，参与活动的人。用户在活动中，活动未开始
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string sign_qrcode formData true 签到码 
    *
    **/
    Teaching_studio_apiActivity_signAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_SIGNADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_signAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.sign_qrcode  = data.sign_qrcode    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 活动类型=>新增活动分类，应该在PC端修改，主持人操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string name formData true 类型名称；工作室内唯一 
    * @param string icon_img formData false 分类的ICON 
    * @param integer sort formData false 分类的排序 
    * @param integer activity_assess_id formData false 该活动分类对应的考核指标；为0表示不对应考核指标 
    *
    **/
    Teaching_studio_apiActivity_classesAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_CLASSESADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_classesAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.name  = data.name    
        postParam.icon_img  = typeof(data.icon_img) !="undefined" ?  data.icon_img:''  
        postParam.sort  = typeof(data.sort) !="undefined" ?  data.sort:0  
        postParam.activity_assess_id  = typeof(data.activity_assess_id) !="undefined" ?  data.activity_assess_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 活动类型=>新增
    * @param integer studio_id formData true 工作室id 
    * @param string name formData true 类型名称 
    * @param string icon_img formData false 分类的ICON 
    * @param integer sort formData false 分类的排序 
    * @param integer activity_assess_id formData false 这个活动分类对应着什么考核类型 
    * @param integer admin_id formData false 新增人ID 
    *
    **/
    Iot_autoActivity_classesAddPost(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOACTIVITY_CLASSESADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoActivity_classesAddPost');
        //相关参数
  
        postParam.studio_id  = data.studio_id    
        postParam.name  = data.name    
        postParam.icon_img  = typeof(data.icon_img) !="undefined" ?  data.icon_img:''  
        postParam.sort  = typeof(data.sort) !="undefined" ?  data.sort:0  
        postParam.activity_assess_id  = typeof(data.activity_assess_id) !="undefined" ?  data.activity_assess_id:0  
        postParam.admin_id  = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 活动类型=>编辑活动分类，主持人操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer classes_id formData true 分类id 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string name formData true 类型名称；工作室内唯一 
    * @param string icon_img formData false 分类的ICON 
    * @param integer activity_assess_id formData false 该活动分类对应的考核指标；为0表示不对应考核指标 
    *
    **/
    Teaching_studio_apiActivity_classesEditPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_CLASSESEDITPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_classesEditPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.classes_id  = data.classes_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.name  = data.name    
        postParam.icon_img  = typeof(data.icon_img) !="undefined" ?  data.icon_img:''  
        postParam.activity_assess_id  = typeof(data.activity_assess_id) !="undefined" ?  data.activity_assess_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 活动类型=>更新
    * @param integer id formData true noComment 
    * @param integer studio_id formData false 工作室id 
    * @param string name formData false 类型名称 
    * @param string icon_img formData false 分类的ICON 
    * @param integer sort formData false 分类的排序 
    * @param integer activity_assess_id formData false 这个活动分类对应着什么考核类型 
    * @param integer admin_id formData false 更新人ID 
    *
    **/
    Iot_autoActivity_classesUpdatePost(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOACTIVITY_CLASSESUPDATEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoActivity_classesUpdatePost');
        //相关参数
  
        postParam.id  = data.id    
        postParam.studio_id  = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        postParam.name  = typeof(data.name) !="undefined" ?  data.name:''  
        postParam.icon_img  = typeof(data.icon_img) !="undefined" ?  data.icon_img:''  
        postParam.sort  = typeof(data.sort) !="undefined" ?  data.sort:0  
        postParam.activity_assess_id  = typeof(data.activity_assess_id) !="undefined" ?  data.activity_assess_id:0  
        postParam.admin_id  = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 活动类型=>编辑活动分类排序，主持人操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer classes_id formData true 分类id 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer sort formData true 排序；越大越靠前 
    *
    **/
    Teaching_studio_apiActivity_classesSortPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_CLASSESSORTPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_classesSortPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.classes_id  = data.classes_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.sort  = data.sort    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 活动类型=>活动分类详情，主持人操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer classes_id query true 分类id 
    *
    **/
    Teaching_studio_apiActivity_classesInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_CLASSESINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_classesInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.classes_id = data.classes_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 活动类型=>获取详情
    * @param integer id query true noComment 
    * @param integer studio_id query false 工作室id 
    * @param string name query false 类型名称 
    * @param string icon_img query false 分类的ICON 
    * @param integer sort query false 分类的排序 
    * @param integer activity_assess_id query false 这个活动分类对应着什么考核类型 
    *
    **/
    Iot_autoActivity_classesInfoGet(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOACTIVITY_CLASSESINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoActivity_classesInfoGet');
        //相关参数
  
        getParam.id = data.id    
        getParam.studio_id = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        getParam.name = typeof(data.name) !="undefined" ?  data.name:''  
        getParam.icon_img = typeof(data.icon_img) !="undefined" ?  data.icon_img:''  
        getParam.sort = typeof(data.sort) !="undefined" ?  data.sort:0  
        getParam.activity_assess_id = typeof(data.activity_assess_id) !="undefined" ?  data.activity_assess_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 活动类型=>获取列表
    * @param integer studio_id query true 工作室id 
    * @param string name query false 类型名称 
    * @param string icon_img query false 分类的ICON 
    * @param integer sort query false 分类的排序 
    * @param integer create_admin_id query false 记录的创建者 
    * @param integer update_admin_id query false 记录的更新者 
    * @param integer create_time_min query false 记录的创建时间开始 
    * @param integer create_time_max query false 记录的创建时间结束 
    * @param integer update_time_min query false 记录的更新时间开始 
    * @param integer update_time_max query false 记录的更新时间结束 
    * @param integer activity_assess_id query false 这个活动分类对应着什么考核类型 
    * @param integer page query true 要读取第几页 
    * @param integer each_page query true 每页记录条数 
    * @param string order_field query false 排序字段；id: ID 
    * @param string order_type query false 排序方式；asc:升序,desc:降序 
    * @param string group_by query false 聚合方式 
    * @param string having query false 聚合后的筛选方式 
    *
    **/
    Iot_autoActivity_classesListGet(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOACTIVITY_CLASSESLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoActivity_classesListGet');
        //相关参数
  
        getParam.studio_id = data.studio_id    
        getParam.name = typeof(data.name) !="undefined" ?  data.name:''  
        getParam.icon_img = typeof(data.icon_img) !="undefined" ?  data.icon_img:''  
        getParam.sort = typeof(data.sort) !="undefined" ?  data.sort:0  
        getParam.create_admin_id = typeof(data.create_admin_id) !="undefined" ?  data.create_admin_id:0  
        getParam.update_admin_id = typeof(data.update_admin_id) !="undefined" ?  data.update_admin_id:0  
        getParam.create_time_min = typeof(data.create_time_min) !="undefined" ?  data.create_time_min:0  
        getParam.create_time_max = typeof(data.create_time_max) !="undefined" ?  data.create_time_max:0  
        getParam.update_time_min = typeof(data.update_time_min) !="undefined" ?  data.update_time_min:0  
        getParam.update_time_max = typeof(data.update_time_max) !="undefined" ?  data.update_time_max:0  
        getParam.activity_assess_id = typeof(data.activity_assess_id) !="undefined" ?  data.activity_assess_id:0  
        getParam.page = data.page    
        getParam.each_page = data.each_page    
        getParam.order_field = typeof(data.order_field) !="undefined" ?  data.order_field:''  
        getParam.order_type = typeof(data.order_type) !="undefined" ?  data.order_type:''  
        getParam.group_by = typeof(data.group_by) !="undefined" ?  data.group_by:''  
        getParam.having = typeof(data.having) !="undefined" ?  data.having:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 活动类型=>编辑活动分类的考核细则id，支持人操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer classes_id formData true 分类id 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer activity_assess_id formData true 考核细则id 
    *
    **/
    Teaching_studio_apiActivity_classesActivity_assessPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_CLASSESACTIVITY_ASSESSPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_classesActivity_assessPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.classes_id  = data.classes_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.activity_assess_id  = data.activity_assess_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 活动类型=>删除活动分类，主持人操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer classes_id query true 分类id 
    *
    **/
    Teaching_studio_apiActivity_classesDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIACTIVITY_CLASSESDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiActivity_classesDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.classes_id = data.classes_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 活动类型=>删除
    * @param integer id query true noComment 
    * @param integer studio_id query false 工作室id 
    * @param string name query false 类型名称 
    * @param string icon_img query false 分类的ICON 
    * @param integer sort query false 分类的排序 
    * @param integer activity_assess_id query false 这个活动分类对应着什么考核类型 
    * @param integer admin_id query false 删除人ID 
    *
    **/
    Iot_autoActivity_classesDelDelete(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOACTIVITY_CLASSESDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoActivity_classesDelDelete');
        //相关参数
  
        getParam.id = data.id    
        getParam.studio_id = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        getParam.name = typeof(data.name) !="undefined" ?  data.name:''  
        getParam.icon_img = typeof(data.icon_img) !="undefined" ?  data.icon_img:''  
        getParam.sort = typeof(data.sort) !="undefined" ?  data.sort:0  
        getParam.activity_assess_id = typeof(data.activity_assess_id) !="undefined" ?  data.activity_assess_id:0  
        getParam.admin_id = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备=>新增设备，工作室内主持人、组长有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer work_room_id formData true 生产房间标识 
    * @param string device_name formData true 设备名称 
    * @param string device_type formData true 设备类型 
    * @param string device_key formData true 设备KEY 
    *
    **/
    Teaching_studio_apiIot_deviceAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICEADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_deviceAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.work_room_id  = data.work_room_id    
        postParam.device_name  = data.device_name    
        postParam.device_type  = data.device_type    
        postParam.device_key  = data.device_key    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 物联设备=>获取设备详细信息，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer device_id query true 设备标识 
    *
    **/
    Teaching_studio_apiIot_deviceInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICEINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_deviceInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.device_id = data.device_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备=>按类型获取工作室中的设备列表
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer work_shop_id query true 生产车间标识 
    * @param string device_type query true 设备类型 
    *
    **/
    Teaching_studio_apiIot_deviceStudioTypeListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICESTUDIOTYPELISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_deviceStudioTypeListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.work_shop_id = data.work_shop_id    
        getParam.device_type = data.device_type    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备=>获取生产房间下的设备列表，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer work_room_id query true 生产房间标识 
    * @param string device_name query false 设备名称 
    * @param string device_type query false 设备类型 
    * @param string device_key query false 设备KEY 
    *
    **/
    Teaching_studio_apiIot_deviceListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICELISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_deviceListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.work_room_id = data.work_room_id    
        getParam.device_name = typeof(data.device_name) !="undefined" ?  data.device_name:''  
        getParam.device_type = typeof(data.device_type) !="undefined" ?  data.device_type:''  
        getParam.device_key = typeof(data.device_key) !="undefined" ?  data.device_key:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备=>获取设备所属房间信息，工作室内所有人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer device_id query true 设备标识 
    *
    **/
    Teaching_studio_apiIot_deviceRoomGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICEROOMGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_deviceRoomGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.device_id = data.device_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备=>获取房间下的所有设备及属性数据，工作室内所有人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer work_room_id query true 房间标识 
    *
    **/
    Teaching_studio_apiWork_roomDeviceDataGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORK_ROOMDEVICEDATAGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWork_roomDeviceDataGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.work_room_id = data.work_room_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备=>获取工作室内开关设备开关记录，分页列表，工作室内所有人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer device_id query true 设备标识 
    * @param integer page query true 分页数 
    * @param integer each_page query true 分页读取数量 
    *
    **/
    Teaching_studio_apiIot_deviceEventRecordGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICEEVENTRECORDGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_deviceEventRecordGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.device_id = data.device_id    
        getParam.page = data.page    
        getParam.each_page = data.each_page    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备=>获取生产车间下设备统计（温湿度传感器、粒子数传感器、风速传感器、压差传感器、浮游菌采样器），工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiWorkshopDeviceCountGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORKSHOPDEVICECOUNTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWorkshopDeviceCountGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备=>获取房间下设备统计（温湿度传感器、粒子数传感器、风速传感器、压差传感器、浮游菌采样器），工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer room_id query true 房间标识 
    *
    **/
    Teaching_studio_apiIot_deviceRoomCountGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICEROOMCOUNTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_deviceRoomCountGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.room_id = data.room_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备=>更新工作室的设备,支持人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string device_prefix formData true 工作室设备前缀 
    *
    **/
    Teaching_studio_apiIot_deviceUpFromIotPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICEUPFROMIOTPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_deviceUpFromIotPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.device_prefix  = data.device_prefix    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 物联设备=>更新设备真实需要展示到前端的属性，工作室内所有人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer device_id formData true 设备标识 
    * @param string peoperty_keys formData true 真实需要展示的属性，设置后默认展示的属性失效，多个则用英文逗号分割 
    *
    **/
    Teaching_studio_apiIot_deviceReal_show_propertyUpPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICEREAL_SHOW_PROPERTYUPPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_deviceReal_show_propertyUpPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.device_id  = data.device_id    
        postParam.peoperty_keys  = data.peoperty_keys    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 物联设备=>更新设备类型，工作室内主持人、创建人有次限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer device_id formData true 设备标识 
    * @param string device_type formData true 设备类型 
    *
    **/
    Teaching_studio_apiIot_deviceDevice_typeUpPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICEDEVICE_TYPEUPPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_deviceDevice_typeUpPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.device_id  = data.device_id    
        postParam.device_type  = data.device_type    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 物联设备=>更新设备名称，工作室内所有人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer device_id formData true 设备标识 
    * @param string device_name formData true 设备名称 
    *
    **/
    Teaching_studio_apiIot_deviceNameUpPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICENAMEUPPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_deviceNameUpPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.device_id  = data.device_id    
        postParam.device_name  = data.device_name    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 物联设备=>更新设备房间，工作室内所有人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer device_id formData true 设备标识 
    * @param string work_room_id formData true 设备名称 
    *
    **/
    Teaching_studio_apiIot_deviceWork_roomUpPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICEWORK_ROOMUPPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_deviceWork_roomUpPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.device_id  = data.device_id    
        postParam.work_room_id  = data.work_room_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 物联设备=>更新设备key，工作室内主持人、创建人有次限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer device_id formData true 设备标识 
    * @param string device_key formData true 设备KEY 
    *
    **/
    Teaching_studio_apiIot_deviceDevice_keyUpPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICEDEVICE_KEYUPPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_deviceDevice_keyUpPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.device_id  = data.device_id    
        postParam.device_key  = data.device_key    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 物联设备=>删除设备，工作室内主持人、创建人有次权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer device_id query true 设备标识 
    *
    **/
    Teaching_studio_apiIot_deviceDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICEDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_deviceDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.device_id = data.device_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备=>发送设备命令，工作室内所有人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer device_id formData true 设备标识 
    * @param string event_key formData true 设备事件key 
    * @param string event_value formData false 设备事件的值 
    *
    **/
    Teaching_studio_apiIot_deviceOrderPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICEORDERPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_deviceOrderPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.device_id  = data.device_id    
        postParam.event_key  = data.event_key    
        postParam.event_value  = typeof(data.event_value) !="undefined" ?  data.event_value:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 物联设备属性=>新增设备属性，工作室内主持人、组长有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer DeviceId formData true 设备标识 
    * @param string property_key formData true 属性key 
    *
    **/
    Teaching_studio_apiIot_device_propertyAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICE_PROPERTYADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_device_propertyAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.DeviceId  = data.DeviceId    
        postParam.property_key  = data.property_key    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 物联设备属性=>获取设备属性详细信息，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer property_id query true 设备标识 
    *
    **/
    Teaching_studio_apiIot_device_propertyInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICE_PROPERTYINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_device_propertyInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.property_id = data.property_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备属性=>获取设备的属性列表，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer device_id query true 设备标识 
    * @param string property_key query false 属性key 
    *
    **/
    Teaching_studio_apiIot_device_propertyListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICE_PROPERTYLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_device_propertyListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.device_id = data.device_id    
        getParam.property_key = typeof(data.property_key) !="undefined" ?  data.property_key:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备属性=>获取设备属性一段时间的数据，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer property_id query true 属性标识 
    * @param integer begin_time query true 开始时间 
    * @param integer end_time query true 结束时间 
    *
    **/
    Teaching_studio_apiIot_device_propertyTimeDataGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICE_PROPERTYTIMEDATAGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_device_propertyTimeDataGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.property_id = data.property_id    
        getParam.begin_time = data.begin_time    
        getParam.end_time = data.end_time    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备属性=>获取设备属性的数据，工作室内主持人、创建人有次权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer property_id query true 属性标识 
    *
    **/
    Teaching_studio_apiIot_device_propertyDataLastGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICE_PROPERTYDATALASTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_device_propertyDataLastGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.property_id = data.property_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备属性=>获取工作室设备属性数据报警的列表，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiIot_device_propertyStudioWarningsGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICE_PROPERTYSTUDIOWARNINGSGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_device_propertyStudioWarningsGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备属性=>删除设备属性，工作室内主持人、创建人有次权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer property_id query true 属性标识 
    *
    **/
    Teaching_studio_apiIot_device_propertyDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICE_PROPERTYDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_device_propertyDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.property_id = data.property_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备属性=>更新设备属性key，工作室内主持人、创建人有次限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer property_id formData true 属性标识 
    * @param string property_key formData true 属性key 
    *
    **/
    Teaching_studio_apiIot_device_propertyProperty_keyUpPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICE_PROPERTYPROPERTY_KEYUPPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_device_propertyProperty_keyUpPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.property_id  = data.property_id    
        postParam.property_key  = data.property_key    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 物联设备属性报警设置=>新增设备属性报警设置，工作室内主持人、组长有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer property_id formData true 属性id 
    * @param number min_value formData true 正常范围的小值 
    * @param number max_value formData true 正常范围的大值 
    * @param string warning_type formData true 不在正常类型中的报警提醒；none:不报警，low:低强度，high:高等 
    *
    **/
    Teaching_studio_apiIot_device_property_warningAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICE_PROPERTY_WARNINGADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_device_property_warningAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.property_id  = data.property_id    
        postParam.min_value  = data.min_value    
        postParam.max_value  = data.max_value    
        postParam.warning_type  = data.warning_type    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 物联设备属性报警设置=>获取设备属性报警设置详细信息，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer property_warning_id query true 设备报警记录标识标识 
    *
    **/
    Teaching_studio_apiIot_device_property_warningInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICE_PROPERTY_WARNINGINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_device_property_warningInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.property_warning_id = data.property_warning_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备属性报警设置=>获取设备的属性报警设置列表，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer property_id query true 属性id 
    * @param string warning_type query false 不在正常类型中的报警提醒；none:不报警，low:低强度，high:高等 
    *
    **/
    Teaching_studio_apiIot_device_property_warningListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICE_PROPERTY_WARNINGLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_device_property_warningListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.property_id = data.property_id    
        getParam.warning_type = typeof(data.warning_type) !="undefined" ?  data.warning_type:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备属性报警设置=>删除设备属性，工作室内主持人、创建人有次权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer property_warning_id query true 属性报警设置id 
    *
    **/
    Teaching_studio_apiIot_device_property_warningDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICE_PROPERTY_WARNINGDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_device_property_warningDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.property_warning_id = data.property_warning_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 物联设备属性报警设置=>更新设备属性报警设置：工作室内主持人、创建人有次限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer property_warning_id formData true 属性报警设置id 
    * @param number min_value formData true 正常范围的小值 
    * @param number max_value formData true 正常范围的大值 
    * @param string warning_type formData true 不在正常类型中的报警提醒；none:不报警，low:低强度，high:高等 
    *
    **/
    Teaching_studio_apiIot_device_property_warningWarningUpPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIIOT_DEVICE_PROPERTY_WARNINGWARNINGUPPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiIot_device_property_warningWarningUpPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.property_warning_id  = data.property_warning_id    
        postParam.min_value  = data.min_value    
        postParam.max_value  = data.max_value    
        postParam.warning_type  = data.warning_type    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 生产工区=>新增一个生产工区，工作室内所有人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer workshop_id formData true 生产车间的标识 
    * @param string name formData true 生产工区的名称 
    *
    **/
    Teaching_studio_apiWork_areaAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORK_AREAADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWork_areaAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.workshop_id  = data.workshop_id    
        postParam.name  = data.name    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 生产工区=>获取生产工区的详细信息，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer work_area_id query true 生产工区的标识 
    *
    **/
    Teaching_studio_apiWork_areaInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORK_AREAINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWork_areaInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.work_area_id = data.work_area_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 生产工区=>获取生产车间下的工区列表，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer workshop_id query true 生产车间的标识 
    * @param string name query false 生产工区的名称 
    * @param integer page query true 分页数 
    * @param integer each_page query true 分页读取数量 
    *
    **/
    Teaching_studio_apiWork_areaListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORK_AREALISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWork_areaListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.workshop_id = data.workshop_id    
        getParam.name = typeof(data.name) !="undefined" ?  data.name:''  
        getParam.page = data.page    
        getParam.each_page = data.each_page    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 生产工区=>删除生产工区，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer work_area_id query true 生产工区的标识 
    *
    **/
    Teaching_studio_apiWork_areaDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORK_AREADELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWork_areaDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.work_area_id = data.work_area_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 生产工区=>更新生产工区的名字，工作室内所有人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer work_area_id formData true 生产工区的标识 
    * @param string name formData true 生产工区的名称 
    *
    **/
    Teaching_studio_apiWork_areaNameUpPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORK_AREANAMEUPPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWork_areaNameUpPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.work_area_id  = data.work_area_id    
        postParam.name  = data.name    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 生产房间=>新增一个生产房间，工作室内所有人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer work_area_id formData true 生产工区的标识 
    * @param integer user_id formData true 负责人的用户标识 
    * @param string name formData true 生产房间的名称 
    * @param string serial_number formData true 房间编号 
    *
    **/
    Teaching_studio_apiWork_roomAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORK_ROOMADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWork_roomAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.work_area_id  = data.work_area_id    
        postParam.user_id  = data.user_id    
        postParam.name  = data.name    
        postParam.serial_number  = data.serial_number    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 生产房间=>新增
    * @param integer studio_id formData false noComment 
    * @param integer workshop_id formData false 生产车间id 
    * @param integer work_area_id formData false 生产工区id 
    * @param integer user_id formData false 负责人的用户id 
    * @param string name formData false 名称 
    * @param string serial_number formData false 房间编号 
    * @param integer admin_id formData false 新增人ID 
    *
    **/
    Iot_autoWork_roomAddPost(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOWORK_ROOMADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoWork_roomAddPost');
        //相关参数
  
        postParam.studio_id  = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        postParam.workshop_id  = typeof(data.workshop_id) !="undefined" ?  data.workshop_id:0  
        postParam.work_area_id  = typeof(data.work_area_id) !="undefined" ?  data.work_area_id:0  
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        postParam.name  = typeof(data.name) !="undefined" ?  data.name:''  
        postParam.serial_number  = typeof(data.serial_number) !="undefined" ?  data.serial_number:''  
        postParam.admin_id  = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 生产房间=>更新
    * @param integer id formData false noComment 
    * @param integer studio_id formData false noComment 
    * @param integer workshop_id formData false 生产车间id 
    * @param integer work_area_id formData false 生产工区id 
    * @param integer user_id formData false 负责人的用户id 
    * @param string name formData false 名称 
    * @param string serial_number formData false 房间编号 
    * @param integer admin_id formData false 更新人ID 
    *
    **/
    Iot_autoWork_roomUpdatePost(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOWORK_ROOMUPDATEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoWork_roomUpdatePost');
        //相关参数
  
        postParam.id  = typeof(data.id) !="undefined" ?  data.id:0  
        postParam.studio_id  = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        postParam.workshop_id  = typeof(data.workshop_id) !="undefined" ?  data.workshop_id:0  
        postParam.work_area_id  = typeof(data.work_area_id) !="undefined" ?  data.work_area_id:0  
        postParam.user_id  = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        postParam.name  = typeof(data.name) !="undefined" ?  data.name:''  
        postParam.serial_number  = typeof(data.serial_number) !="undefined" ?  data.serial_number:''  
        postParam.admin_id  = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 生产房间=>获取生产房间的详细信息，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer work_room_id query true 生产房间的标识 
    *
    **/
    Teaching_studio_apiWork_roomInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORK_ROOMINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWork_roomInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.work_room_id = data.work_room_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 生产房间=>获取详情
    * @param integer id query false noComment 
    * @param integer studio_id query false noComment 
    * @param integer workshop_id query false 生产车间id 
    * @param integer work_area_id query false 生产工区id 
    * @param integer user_id query false 负责人的用户id 
    * @param string name query false 名称 
    * @param string serial_number query false 房间编号 
    *
    **/
    Iot_autoWork_roomInfoGet(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOWORK_ROOMINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoWork_roomInfoGet');
        //相关参数
  
        getParam.id = typeof(data.id) !="undefined" ?  data.id:0  
        getParam.studio_id = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        getParam.workshop_id = typeof(data.workshop_id) !="undefined" ?  data.workshop_id:0  
        getParam.work_area_id = typeof(data.work_area_id) !="undefined" ?  data.work_area_id:0  
        getParam.user_id = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        getParam.name = typeof(data.name) !="undefined" ?  data.name:''  
        getParam.serial_number = typeof(data.serial_number) !="undefined" ?  data.serial_number:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 生产房间=>获取列表
    * @param integer studio_id query false noComment 
    * @param integer workshop_id query false 生产车间id 
    * @param integer work_area_id query false 生产工区id 
    * @param integer user_id query false 负责人的用户id 
    * @param string name query false 名称 
    * @param string serial_number query false 房间编号 
    * @param integer create_admin_id query false 记录的创建者 
    * @param integer update_admin_id query false 记录的更新者 
    * @param integer create_time_min query false 记录的创建时间开始 
    * @param integer create_time_max query false 记录的创建时间结束 
    * @param integer update_time_min query false 记录的更新时间开始 
    * @param integer update_time_max query false 记录的更新时间结束 
    * @param integer page query false 要读取第几页 
    * @param integer each_page query false 每页记录条数 
    * @param string order_field query false 排序字段；id: ID 
    * @param string order_type query false 排序方式；asc:升序,desc:降序 
    * @param string group_by query false 聚合方式 
    * @param string having query false 聚合后的筛选方式 
    *
    **/
    Iot_autoWork_roomListGet(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOWORK_ROOMLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoWork_roomListGet');
        //相关参数
  
        getParam.studio_id = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        getParam.workshop_id = typeof(data.workshop_id) !="undefined" ?  data.workshop_id:0  
        getParam.work_area_id = typeof(data.work_area_id) !="undefined" ?  data.work_area_id:0  
        getParam.user_id = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        getParam.name = typeof(data.name) !="undefined" ?  data.name:''  
        getParam.serial_number = typeof(data.serial_number) !="undefined" ?  data.serial_number:''  
        getParam.create_admin_id = typeof(data.create_admin_id) !="undefined" ?  data.create_admin_id:0  
        getParam.update_admin_id = typeof(data.update_admin_id) !="undefined" ?  data.update_admin_id:0  
        getParam.create_time_min = typeof(data.create_time_min) !="undefined" ?  data.create_time_min:0  
        getParam.create_time_max = typeof(data.create_time_max) !="undefined" ?  data.create_time_max:0  
        getParam.update_time_min = typeof(data.update_time_min) !="undefined" ?  data.update_time_min:0  
        getParam.update_time_max = typeof(data.update_time_max) !="undefined" ?  data.update_time_max:0  
        getParam.page = typeof(data.page) !="undefined" ?  data.page:0  
        getParam.each_page = typeof(data.each_page) !="undefined" ?  data.each_page:0  
        getParam.order_field = typeof(data.order_field) !="undefined" ?  data.order_field:''  
        getParam.order_type = typeof(data.order_type) !="undefined" ?  data.order_type:''  
        getParam.group_by = typeof(data.group_by) !="undefined" ?  data.group_by:''  
        getParam.having = typeof(data.having) !="undefined" ?  data.having:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 生产房间=>获取生产工区下的房间列表，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer workshop_id query true 生产车间的标识 
    * @param integer work_area_id query false 生产工区的标识 
    * @param string name query false 生产房间的名称 
    * @param string serial_number query false 生产房间的编号 
    * @param integer page query true 分页数 
    * @param integer each_page query true 分页读取数量 
    *
    **/
    Teaching_studio_apiWork_roomListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORK_ROOMLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWork_roomListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.workshop_id = data.workshop_id    
        getParam.work_area_id = typeof(data.work_area_id) !="undefined" ?  data.work_area_id:0  
        getParam.name = typeof(data.name) !="undefined" ?  data.name:''  
        getParam.serial_number = typeof(data.serial_number) !="undefined" ?  data.serial_number:''  
        getParam.page = data.page    
        getParam.each_page = data.each_page    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 生产房间=>删除
    * @param integer id query false noComment 
    * @param integer studio_id query false noComment 
    * @param integer workshop_id query false 生产车间id 
    * @param integer work_area_id query false 生产工区id 
    * @param integer user_id query false 负责人的用户id 
    * @param string name query false 名称 
    * @param string serial_number query false 房间编号 
    * @param integer admin_id query false 删除人ID 
    *
    **/
    Iot_autoWork_roomDelDelete(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOWORK_ROOMDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoWork_roomDelDelete');
        //相关参数
  
        getParam.id = typeof(data.id) !="undefined" ?  data.id:0  
        getParam.studio_id = typeof(data.studio_id) !="undefined" ?  data.studio_id:0  
        getParam.workshop_id = typeof(data.workshop_id) !="undefined" ?  data.workshop_id:0  
        getParam.work_area_id = typeof(data.work_area_id) !="undefined" ?  data.work_area_id:0  
        getParam.user_id = typeof(data.user_id) !="undefined" ?  data.user_id:0  
        getParam.name = typeof(data.name) !="undefined" ?  data.name:''  
        getParam.serial_number = typeof(data.serial_number) !="undefined" ?  data.serial_number:''  
        getParam.admin_id = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 生产房间=>删除生产房间，工作室内所支持人和创建人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer work_room_id query true 生产房间的标识 
    *
    **/
    Teaching_studio_apiWork_roomDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORK_ROOMDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWork_roomDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.work_room_id = data.work_room_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 生产房间=>更新生产房间的名字，工作室内所有人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer work_room_id formData true 生产房间的标识 
    * @param string name formData true 生产房间的名称 
    *
    **/
    Teaching_studio_apiWork_roomNameUpPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORK_ROOMNAMEUPPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWork_roomNameUpPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.work_room_id  = data.work_room_id    
        postParam.name  = data.name    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 生产房间=>更新生产房间的负责人，工作室内所有人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer work_room_id formData true 生产房间的标识 
    * @param string user_id formData true 生产房间的负责人 
    *
    **/
    Teaching_studio_apiWork_roomUserUpPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORK_ROOMUSERUPPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWork_roomUserUpPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.work_room_id  = data.work_room_id    
        postParam.user_id  = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 生产房间=>更新生产房间的编号，工作室内主持人、创建人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer work_room_id formData true 生产房间的标识 
    * @param string serial_number formData true 生产房间的名称 
    *
    **/
    Teaching_studio_apiWork_roomSerial_numberUpPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORK_ROOMSERIAL_NUMBERUPPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWork_roomSerial_numberUpPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.work_room_id  = data.work_room_id    
        postParam.serial_number  = data.serial_number    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 生产车间=>新增一个生产车间，工作室内所有人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string name formData true 生产车间的名称 
    * @param string icon formData true 车间的logo 
    *
    **/
    Teaching_studio_apiWorkshopAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORKSHOPADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWorkshopAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.name  = data.name    
        postParam.icon  = data.icon    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 生产车间=>获取生产车间列表，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer workshop_id query true 生产车间的标识 
    *
    **/
    Teaching_studio_apiWorkshopInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORKSHOPINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWorkshopInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.workshop_id = data.workshop_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 生产车间=>获取生产车间列表，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string name query false 生产车间的名称 
    *
    **/
    Teaching_studio_apiWorkshopListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORKSHOPLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWorkshopListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.name = typeof(data.name) !="undefined" ?  data.name:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 生产车间=>更新生产车间的名字，工作室内所有人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer workshop_id formData true 生产车间的标识 
    * @param string name formData true 生产车间的名称 
    *
    **/
    Teaching_studio_apiWorkshopNameUpPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORKSHOPNAMEUPPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWorkshopNameUpPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.workshop_id  = data.workshop_id    
        postParam.name  = data.name    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 生产车间=>删除生产车间，工作室内所有人员有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer workshop_id query true 生产车间的标识 
    *
    **/
    Teaching_studio_apiWorkshopDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORKSHOPDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWorkshopDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.workshop_id = data.workshop_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 生产车间=>更新生产车间的logo，工作室内所有人有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer workshop_id formData true 生产车间的标识 
    * @param string icon formData true 车间的logo 
    *
    **/
    Teaching_studio_apiWorkshopIconUpPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIWORKSHOPICONUPPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiWorkshopIconUpPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.workshop_id  = data.workshop_id    
        postParam.icon  = data.icon    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 用户与工作室=>工作室的视觉元素[名称、img、logo、banner]
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer id query true 工作室ID 
    *
    **/
    Teaching_studio_apiStudioVisionGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIOVISIONGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioVisionGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户与工作室=>用户当前登录状态的工作室
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiStudioLogin_studioGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIOLOGIN_STUDIOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioLogin_studioGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户与工作室=>切换到某个工作室
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer to_studio_id query true 要切换的工作室 
    * @param integer to_user_id query true 要切换的用户对象 
    *
    **/
    Teaching_studio_apiStudioToStudioGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIOTOSTUDIOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioToStudioGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.to_studio_id = data.to_studio_id    
        getParam.to_user_id = data.to_user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户与工作室=>在此微信公众号下面的用户所在的工作室的列表
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiStudioWechatStudioGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIOWECHATSTUDIOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioWechatStudioGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户的考核评价=>新增自评，工作室内成员有此权限，一旦提交不能修改，所以请提醒用户
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer form_id formData true 表单标识 
    * @param integer field_id formData true 字段标识 
    * @param string value_id formData false 对应的值；不一定有 
    * @param string my_value formData true 用户填的字段的值 
    * @param integer self_score formData true 户这个字段所获取的分值；自评 
    * @param string year formData true 自评年度；今年或者上一年度 
    *
    **/
    Teaching_studio_apiUser_appraise_formAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSER_APPRAISE_FORMADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUser_appraise_formAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.form_id  = data.form_id    
        postParam.field_id  = data.field_id    
        postParam.value_id  = typeof(data.value_id) !="undefined" ?  data.value_id:''  
        postParam.my_value  = data.my_value    
        postParam.self_score  = data.self_score    
        postParam.year  = data.year    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 用户的考核评价=>上级评价，主持人、小组长有此权限；小组长对学员评价，主持人对成员评价
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer form_id formData true 表单标识 
    * @param integer field_id formData true 字段标识 
    * @param string high_level_value_id formData false 对应的值；不一定有 
    * @param string high_level_my_value formData true 上级填的字段的值 
    * @param integer high_level_score formData true 上级这个字段所获取的分值；他评 
    * @param integer user_id formData true 评价对象 
    * @param string year formData true 考核年份 
    *
    **/
    Teaching_studio_apiUser_appraise_formHigh_levelUpPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSER_APPRAISE_FORMHIGH_LEVELUPPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUser_appraise_formHigh_levelUpPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.form_id  = data.form_id    
        postParam.field_id  = data.field_id    
        postParam.high_level_value_id  = typeof(data.high_level_value_id) !="undefined" ?  data.high_level_value_id:''  
        postParam.high_level_my_value  = data.high_level_my_value    
        postParam.high_level_score  = data.high_level_score    
        postParam.user_id  = data.user_id    
        postParam.year  = data.year    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 用户的考核评价=>当前登录用户，最后一次的考核信息
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string year query true 哪个年度的考核评价 
    *
    **/
    Teaching_studio_apiUser_appraise_formLoginInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSER_APPRAISE_FORMLOGININFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUser_appraise_formLoginInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.year = data.year    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户的考核评价=>获取考核评价，主持人可以看全部的，组长可以看组员的，所有人可以看自己的
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string year query true 哪个年度的考核评价 
    * @param integer user_id query true 要查看的用户ID 
    *
    **/
    Teaching_studio_apiUser_appraise_formFormInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSER_APPRAISE_FORMFORMINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUser_appraise_formFormInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.year = data.year    
        getParam.user_id = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户的考核评价=>某年度的成员的排名，注意如果未评分就不会有记录输出
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string year query true 哪个年度的考核评价 
    *
    **/
    Teaching_studio_apiUser_appraise_formMemberListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSER_APPRAISE_FORMMEMBERLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUser_appraise_formMemberListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.year = data.year    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户的考核评价=>某年度的某个小组的年度排名，注意如果未评分就不会有记录输出；这里不显示已经踢出的用户
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string year query true 哪个年度的考核评价 
    * @param integer group_id query true 小组ID 
    *
    **/
    Teaching_studio_apiUser_appraise_formGroupListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSER_APPRAISE_FORMGROUPLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUser_appraise_formGroupListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.year = data.year    
        getParam.group_id = data.group_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户的考核评价=>工作室内已自评的人，不包含主持人，工作室内开放
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string year query true 哪个年度的考核评价 
    *
    **/
    Teaching_studio_apiUser_appraise_formStudioSelfHaveGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSER_APPRAISE_FORMSTUDIOSELFHAVEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUser_appraise_formStudioSelfHaveGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.year = data.year    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户的考核评价=>工作室内已上一级评价的人，不包含主持人，工作室内开放
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string year query true 哪个年度的考核评价 
    *
    **/
    Teaching_studio_apiUser_appraise_formStudioHigh_levelHaveGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSER_APPRAISE_FORMSTUDIOHIGH_LEVELHAVEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUser_appraise_formStudioHigh_levelHaveGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.year = data.year    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户的考核评价=>年度人数统计，不包含主持人
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string year query true 哪个年度的考核评价 
    *
    **/
    Teaching_studio_apiUser_appraise_formNum_countGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSER_APPRAISE_FORMNUM_COUNTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUser_appraise_formNum_countGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.year = data.year    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户的考核评价=>组的年度人数统计，直接按组输出工作室所有组的统计，不包含主持人
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string year query true 哪个年度的考核评价 
    *
    **/
    Teaching_studio_apiUser_appraise_formGroupNum_countGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIUSER_APPRAISE_FORMGROUPNUM_COUNTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiUser_appraise_formGroupNum_countGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.year = data.year    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户端公众号菜单=>保存菜单，其实是新增一个菜单记录，注意需要单独调用接口使其在微信端生效
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string name formData true 菜单整体名称 
    * @param string content formData true 菜单的内容json格式的 
    *
    **/
    Teaching_studio_apiMediaMenuSavePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEDIAMENUSAVEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMediaMenuSavePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.name  = data.name    
        postParam.content  = data.content    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 用户端公众号菜单=>菜单的编辑历史
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiMediaMenuHistoryGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEDIAMENUHISTORYGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMediaMenuHistoryGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户端公众号菜单=>现在生效的菜单
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiMediaMenuNowGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEDIAMENUNOWGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMediaMenuNowGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户端公众号菜单=>发布菜单到微信
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string id query true 记录id 
    *
    **/
    Teaching_studio_apiMediaMenuReleasePut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEDIAMENURELEASEPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMediaMenuReleasePut');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 用户端公众号菜单=>删除菜单记录，注意本操作不会导致微信端的任何变化
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string id query true 记录id 
    *
    **/
    Teaching_studio_apiMediaMenuDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEDIAMENUDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMediaMenuDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 用户端在线会议=>添加一场会议，工作室内的人都有权限，只是只有主持人可以开启全部人会议，组长可以开启本组会议，组员只能进行person级别的会议添加
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string name formData true 会议名称 
    * @param string include_user_type formData true 会议揽括用户类型；all,group,person 
    * @param integer host_user_id formData true 会议主持人 
    * @param integer begin_time formData false 会议的开始时间 
    * @param integer guess_end_time formData false 会议的估计结束时间 
    * @param integer studio_group_id formData false 如果是组会议，则必须传入组id 
    * @param string user_ids formData false 如果是person会议，必须传入由英文逗号拼接的用户id，这些用户都必须在次工作室当中 
    *
    **/
    Teaching_studio_apiMeetingAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.name  = data.name    
        postParam.include_user_type  = data.include_user_type    
        postParam.host_user_id  = data.host_user_id    
        postParam.begin_time  = typeof(data.begin_time) !="undefined" ?  data.begin_time:0  
        postParam.guess_end_time  = typeof(data.guess_end_time) !="undefined" ?  data.guess_end_time:0  
        postParam.studio_group_id  = typeof(data.studio_group_id) !="undefined" ?  data.studio_group_id:0  
        postParam.user_ids  = typeof(data.user_ids) !="undefined" ?  data.user_ids:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 用户端在线会议=>发送会议聊天，会议中的人员可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer meet_id formData true 会议id 
    * @param string content formData true 会议内容 
    *
    **/
    Teaching_studio_apiMeetingPeopleLineSendPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGPEOPLELINESENDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingPeopleLineSendPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.meet_id  = data.meet_id    
        postParam.content  = data.content    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 用户端在线会议=>某次会议的详情，工作室成员可看
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer id query true 会议id 
    *
    **/
    Teaching_studio_apiMeetingInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户端在线会议=>我能看见的已经结束的会议，包含取消的，这里会有一定数量（估计）
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiMeetingDone_listGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGDONE_LISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingDone_listGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户端在线会议=>我可见的会议的列表记录，以时间范围进行查询，方便以月度视图，年度视图进行查看
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer begin_time query true 哪个时间段内的会议：起点 
    * @param integer end_time query false 哪个时间段内的会议：终点 
    * @param string name query false 会议名称的搜索 
    *
    **/
    Teaching_studio_apiMeetingListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.begin_time = data.begin_time    
        getParam.end_time = typeof(data.end_time) !="undefined" ?  data.end_time:0  
        getParam.name = typeof(data.name) !="undefined" ?  data.name:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户端在线会议=>获取某场会议的聊天记录，工作室人员都可以看（也就是不在做工作室内的权限验证了)
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer meet_id query true 会议id 
    *
    **/
    Teaching_studio_apiMeetingPeopleLineGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGPEOPLELINEGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingPeopleLineGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.meet_id = data.meet_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户端在线会议=>强制停止录制
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer id query true 会议ID 
    *
    **/
    Teaching_studio_apiMeetingStop_live_recordGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGSTOP_LIVE_RECORDGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingStop_live_recordGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户端在线会议=>会议的RTC，与会的人可拉取
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer id query true 会议ID 
    *
    **/
    Teaching_studio_apiMeetingRtcGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGRTCGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingRtcGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户端在线会议=>会议的RTM，与会的人可拉取
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer id query true 会议ID 
    *
    **/
    Teaching_studio_apiMeetingRtmGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGRTMGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingRtmGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户端在线会议=>我能看见的正在进行中的会议，考虑数量很少，不需要搜索
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiMeetingDoingGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGDOINGGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingDoingGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户端在线会议=>我能看见的等待中的会议，考虑数量很少，不需要搜索
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiMeetingWaitGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGWAITGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingWaitGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 用户端在线会议=>删除会议聊天，会议中的人员可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer meet_id query true 会议id 
    * @param integer id query true 记录id 
    *
    **/
    Teaching_studio_apiMeetingPeopleLineDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGPEOPLELINEDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingPeopleLineDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.meet_id = data.meet_id    
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 用户端在线会议=>更换会议主持人；主持人、创建人、会议主持人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer id formData true 会议id 
    * @param integer host_user_id formData true 会议主持人 
    *
    **/
    Teaching_studio_apiPeopleHostPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIPEOPLEHOSTPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiPeopleHostPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.id  = data.id    
        postParam.host_user_id  = data.host_user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 用户端在线会议=>开启一场会议，主持人、会议主持人、创建人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer id formData true 会议id 
    *
    **/
    Teaching_studio_apiMeetingBeginPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGBEGINPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingBeginPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.id  = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 用户端在线会议=>删除一场会议，主持人，会议主持人、创建人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer id query true 会议id 
    *
    **/
    Teaching_studio_apiMeetingDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 用户端在线会议=>取消一场会议，主持人，会议主持人、创建人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer id formData true 会议id 
    * @param string reason formData true 取消会议的理由 
    *
    **/
    Teaching_studio_apiMeetingCancelPut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGCANCELPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingCancelPut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.id  = data.id    
        postParam.reason  = data.reason    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 用户端在线会议=>结束一场会议，主持人、会议主持人、创建人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer id formData true 会议id 
    *
    **/
    Teaching_studio_apiMeetingDonePut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGDONEPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingDonePut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.id  = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 用户端在线会议=>添加会议的文件；注意这里是全量修改的模式；参与会议的人都有权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer id formData true 会议id 
    * @param integer files formData true 会议文件 
    *
    **/
    Teaching_studio_apiMeetingFilesChangePut(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEETINGFILESCHANGEPUT)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMeetingFilesChangePut');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.id  = data.id    
        postParam.files  = data.files    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = put(url,postParam,bodyData) 
        return result;
    },

    /**
    * 用户端工作情况=>当前登录的工作室统计数据；课题数量，成员数量，活动数量，学习资源数量，直播数量，心得总数量，公告数量，评价考核数量
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiStudioInfoCountGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APISTUDIOINFOCOUNTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiStudioInfoCountGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 直播录制=>视频会议=》设置是否录制，直播录制；在开始前，可以取消，也可以设置录制；主持人，会议主持人，会议创办人都可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer meet_id formData true 会议id 
    * @param string record formData true yes:开启录制|no：关闭录制 
    *
    **/
    Teaching_studio_apiLive_recordOnline_meetingSet_recordPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVE_RECORDONLINE_MEETINGSET_RECORDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLive_recordOnline_meetingSet_recordPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.meet_id  = data.meet_id    
        postParam.record  = data.record    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 直播录制=>视频会议=》获取录制记录
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer meet_id query true 会议id 
    *
    **/
    Teaching_studio_apiLive_recordOnline_meetingRecordGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVE_RECORDONLINE_MEETINGRECORDGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLive_recordOnline_meetingRecordGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.meet_id = data.meet_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 直播录制=>视频会议=》获取录制状态
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer meet_id query true 会议id 
    *
    **/
    Teaching_studio_apiLive_recordOnline_meetingStatusGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVE_RECORDONLINE_MEETINGSTATUSGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLive_recordOnline_meetingStatusGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.meet_id = data.meet_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 直播接口=>直播讲座的状态
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer live_id query true 直播id 
    *
    **/
    Teaching_studio_apiLiveStatusGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVESTATUSGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveStatusGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.live_id = data.live_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 直播接口=>拉取直播的RTM信息，只要信息正确，总能拉取对应rtm信息
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer live_id query true 直播id 
    *
    **/
    Teaching_studio_apiLiveRtmGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVERTMGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveRtmGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.live_id = data.live_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 直播接口=>停止录制
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer live_id query true 直播id 
    *
    **/
    Teaching_studio_apiLiveRecord_stopGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APILIVERECORD_STOPGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiLiveRecord_stopGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.live_id = data.live_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 研究课题，课题本身是全体成员可见=>添加课题，主持人可操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer user_id formData true 课题带头人 
    * @param string user_ids formData false 课题成员，英文逗号拼接，成员需是工作室成员，为空表示课题内只有带头人一人 
    * @param string title formData true 课题名称，工作室内不能重复 
    * @param string introduce_text formData false 课题简介 
    * @param integer begin_time formData false noComment 
    * @param integer end_time formData false noComment 
    *
    **/
    Teaching_studio_apiResearchAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIRESEARCHADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiResearchAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.user_id  = data.user_id    
        postParam.user_ids  = typeof(data.user_ids) !="undefined" ?  data.user_ids:''  
        postParam.title  = data.title    
        postParam.introduce_text  = typeof(data.introduce_text) !="undefined" ?  data.introduce_text:''  
        postParam.begin_time  = typeof(data.begin_time) !="undefined" ?  data.begin_time:0  
        postParam.end_time  = typeof(data.end_time) !="undefined" ?  data.end_time:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 研究课题，课题本身是全体成员可见=>更新课题基础信息，主持人和课题带头人可操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer research_id formData true 课题id 
    * @param integer user_id formData true 要更换的带头人 
    *
    **/
    Teaching_studio_apiResearchUserLeaderUpdatePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIRESEARCHUSERLEADERUPDATEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiResearchUserLeaderUpdatePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.research_id  = data.research_id    
        postParam.user_id  = data.user_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 研究课题，课题本身是全体成员可见=>更新课题的成员，主持人和课题带头人可操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer research_id formData true 课题id 
    * @param string user_ids formData false 课题成员，英文逗号拼接，成员需是工作室成员，为空清空掉课题成员 
    *
    **/
    Teaching_studio_apiResearchUserPartUpdatePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIRESEARCHUSERPARTUPDATEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiResearchUserPartUpdatePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.research_id  = data.research_id    
        postParam.user_ids  = typeof(data.user_ids) !="undefined" ?  data.user_ids:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 研究课题，课题本身是全体成员可见=>课题状态的变化，主持人和课题带头人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string to_status formData true 要变更的状态；begin:初立,working:研究中,done:完结 
    * @param integer research_id formData true 课题id 
    *
    **/
    Teaching_studio_apiResearchStatusUpdatePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIRESEARCHSTATUSUPDATEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiResearchStatusUpdatePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.to_status  = data.to_status    
        postParam.research_id  = data.research_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 研究课题，课题本身是全体成员可见=>更新课题基础信息，主持人和课题带头人可操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer research_id formData true 课题id 
    * @param string title formData true 课题名称，工作室内不能重复 
    * @param string introduce_text formData false 课题简介 
    * @param integer begin_time formData false noComment 
    * @param integer end_time formData false noComment 
    *
    **/
    Teaching_studio_apiResearchInfoUpdatePost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIRESEARCHINFOUPDATEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiResearchInfoUpdatePost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.research_id  = data.research_id    
        postParam.title  = data.title    
        postParam.introduce_text  = typeof(data.introduce_text) !="undefined" ?  data.introduce_text:''  
        postParam.begin_time  = typeof(data.begin_time) !="undefined" ?  data.begin_time:0  
        postParam.end_time  = typeof(data.end_time) !="undefined" ?  data.end_time:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 研究课题，课题本身是全体成员可见=>课题详情，工作室内开放
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer research_id query true 课题ID 
    *
    **/
    Teaching_studio_apiResearchInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIRESEARCHINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiResearchInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.research_id = data.research_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 研究课题，课题本身是全体成员可见=>工作室课题列表，工作室内开放
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiResearchListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIRESEARCHLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiResearchListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 研究课题，课题本身是全体成员可见=>删除一个课题，主持人和课题带头人可以操作
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer research_id query true 课题id 
    *
    **/
    Teaching_studio_apiResearchDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIRESEARCHDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiResearchDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.research_id = data.research_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 统计数据=>构建某个月度统计，主持人能操作；开始时间为工作室的创建的时间
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string year formData true 构建哪个年度，不能超过当前时间 
    * @param string month formData true 构建哪个月份，不能超过当前时间 
    *
    **/
    Teaching_studio_apiMonth_countBuildPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMONTH_COUNTBUILDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMonth_countBuildPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.year  = data.year    
        postParam.month  = data.month    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 统计数据=>工作室的月度统计数据，工作室的人能看
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string year query true 查询哪个年度，不能超过当前时间 
    * @param string month query true 查询哪个月份，不能超过当前时间 
    *
    **/
    Teaching_studio_apiMonth_countStudioGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMONTH_COUNTSTUDIOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMonth_countStudioGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.year = data.year    
        getParam.month = data.month    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 统计数据=>工作室内组的排名，各组组长和主持人能看
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string year query true 查询哪个年度，不能超过当前时间 
    * @param string month query true 查询哪个月份，不能超过当前时间 
    *
    **/
    Teaching_studio_apiMonth_countGroupSortGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMONTH_COUNTGROUPSORTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMonth_countGroupSortGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.year = data.year    
        getParam.month = data.month    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 统计数据=>某个组的月度统计，组员和主持人能看
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer group_id query true 查询哪个组 
    * @param string year query true 查询哪个年度，不能超过当前时间 
    * @param string month query true 查询哪个月份，不能超过当前时间 
    *
    **/
    Teaching_studio_apiMonth_countGroupGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMONTH_COUNTGROUPGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMonth_countGroupGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.group_id = data.group_id    
        getParam.year = data.year    
        getParam.month = data.month    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 统计数据=>某个用户的工作室月度统计，自己和组长和主持人能看
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer user_id query true 查询哪位用户 
    * @param string year query true 查询哪个年度，不能超过当前时间 
    * @param string month query true 查询哪个月份，不能超过当前时间 
    *
    **/
    Teaching_studio_apiMonth_countUserGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMONTH_COUNTUSERGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMonth_countUserGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.user_id = data.user_id    
        getParam.year = data.year    
        getParam.month = data.month    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 统计数据=>某个组内用户的排名，组内人员以及主持人能看，不做跨组比较
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string year query true 查询哪个年度，不能超过当前时间 
    * @param string month query true 查询哪个月份，不能超过当前时间 
    * @param integer group_id query true 查询哪个组 
    *
    **/
    Teaching_studio_apiMonth_countGroup_userSortGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMONTH_COUNTGROUP_USERSORTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMonth_countGroup_userSortGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.year = data.year    
        getParam.month = data.month    
        getParam.group_id = data.group_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 考核报表=>添加考核报表，主持人有此权限，同一时期，一个工作室同一种报表只能存在一种
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string user_type formData true 考核什么成员的也就是用户类型;member=>成员|study=>学员 
    * @param string form_name formData true 报表名称 
    *
    **/
    Teaching_studio_apiAppraise_formAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIAPPRAISE_FORMADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiAppraise_formAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.user_type  = data.user_type    
        postParam.form_name  = data.form_name    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 考核报表=>更新考核成员的类型：member=>成员，study=>学员，主持人和创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer id formData true 记录唯一标识 
    * @param string user_type formData true 考核什么成员的也就是用户类型;member=>成员|study=>学员 
    *
    **/
    Teaching_studio_apiAppraise_formUser_typeUpPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIAPPRAISE_FORMUSER_TYPEUPPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiAppraise_formUser_typeUpPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.id  = data.id    
        postParam.user_type  = data.user_type    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 考核报表=>考核下线：主持人和创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer id formData true 记录唯一标识 
    *
    **/
    Teaching_studio_apiAppraise_formEndUpPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIAPPRAISE_FORMENDUPPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiAppraise_formEndUpPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.id  = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 考核报表=>更新考核报表名称，主持人和创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer id formData true 记录唯一标识 
    * @param string form_name formData true 报表名称 
    *
    **/
    Teaching_studio_apiAppraise_formNameUpPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIAPPRAISE_FORMNAMEUPPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiAppraise_formNameUpPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.id  = data.id    
        postParam.form_name  = data.form_name    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 考核报表=>获取考核报表详细信息，工作室内成员有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer id query true 记录标识 
    *
    **/
    Teaching_studio_apiAppraise_formInfoGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIAPPRAISE_FORMINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiAppraise_formInfoGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 考核报表=>主持人获取工作室已经下线的考核报表
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiAppraise_formFormEndGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIAPPRAISE_FORMFORMENDGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiAppraise_formFormEndGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 考核报表=>根据用户身份获取其需要填写的表单，主持人获取的是未下线的两种表单
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    *
    **/
    Teaching_studio_apiAppraise_formFormGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIAPPRAISE_FORMFORMGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiAppraise_formFormGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 考核报表=>删除考核表单，只能删除下线表单，主持人和创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer id query true 记录唯一标识 
    *
    **/
    Teaching_studio_apiAppraise_formDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIAPPRAISE_FORMDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiAppraise_formDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 表单字段=>添加考核报表字段，主持人和报表创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param string form_id formData true 表单标识 
    * @param string field_key formData true 字段key 
    * @param string field_name formData true 字段名称 
    * @param string field_type formData true 字段类型;context|img|radio|checked|jude|date|files 
    * @param string field_tip formData true 字段提示 
    * @param string values formData false 字段对应的值，json格式[{ 
    *
    **/
    Teaching_studio_apiForm_fieldAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIFORM_FIELDADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiForm_fieldAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.form_id  = data.form_id    
        postParam.field_key  = data.field_key    
        postParam.field_name  = data.field_name    
        postParam.field_type  = data.field_type    
        postParam.field_tip  = data.field_tip    
        postParam.values  = typeof(data.values) !="undefined" ?  data.values:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 表单字段=>新增
    * @param integer form_id formData false 表单ID 
    * @param string field_key formData false 字段key 
    * @param string field_name formData false 字段名称 
    * @param string field_type formData false 字段类型;context|img|radio|checked|jude 
    * @param string field_tip formData false 字段提示 
    * @param integer admin_id formData false 新增人ID 
    *
    **/
    Iot_autoForm_fieldAddPost(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOFORM_FIELDADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoForm_fieldAddPost');
        //相关参数
  
        postParam.form_id  = typeof(data.form_id) !="undefined" ?  data.form_id:0  
        postParam.field_key  = typeof(data.field_key) !="undefined" ?  data.field_key:''  
        postParam.field_name  = typeof(data.field_name) !="undefined" ?  data.field_name:''  
        postParam.field_type  = typeof(data.field_type) !="undefined" ?  data.field_type:''  
        postParam.field_tip  = typeof(data.field_tip) !="undefined" ?  data.field_tip:''  
        postParam.admin_id  = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 表单字段=>更新字段名称、提示，主持人和创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer id formData true 记录唯一标识 
    * @param string field_name formData true 字段名称 
    * @param string field_tip formData true 字段提示 
    *
    **/
    Teaching_studio_apiForm_fieldUpPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIFORM_FIELDUPPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiForm_fieldUpPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.id  = data.id    
        postParam.field_name  = data.field_name    
        postParam.field_tip  = data.field_tip    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 表单字段=>更新
    * @param integer id formData false noComment 
    * @param integer form_id formData false 表单ID 
    * @param string field_key formData false 字段key 
    * @param string field_name formData false 字段名称 
    * @param string field_type formData false 字段类型;context|img|radio|checked|jude 
    * @param string field_tip formData false 字段提示 
    * @param integer admin_id formData false 更新人ID 
    *
    **/
    Iot_autoForm_fieldUpdatePost(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOFORM_FIELDUPDATEPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoForm_fieldUpdatePost');
        //相关参数
  
        postParam.id  = typeof(data.id) !="undefined" ?  data.id:0  
        postParam.form_id  = typeof(data.form_id) !="undefined" ?  data.form_id:0  
        postParam.field_key  = typeof(data.field_key) !="undefined" ?  data.field_key:''  
        postParam.field_name  = typeof(data.field_name) !="undefined" ?  data.field_name:''  
        postParam.field_type  = typeof(data.field_type) !="undefined" ?  data.field_type:''  
        postParam.field_tip  = typeof(data.field_tip) !="undefined" ?  data.field_tip:''  
        postParam.admin_id  = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 表单字段=>获取详情
    * @param integer id query false noComment 
    * @param integer form_id query false 表单ID 
    * @param string field_key query false 字段key 
    * @param string field_name query false 字段名称 
    * @param string field_type query false 字段类型;context|img|radio|checked|jude 
    * @param string field_tip query false 字段提示 
    *
    **/
    Iot_autoForm_fieldInfoGet(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOFORM_FIELDINFOGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoForm_fieldInfoGet');
        //相关参数
  
        getParam.id = typeof(data.id) !="undefined" ?  data.id:0  
        getParam.form_id = typeof(data.form_id) !="undefined" ?  data.form_id:0  
        getParam.field_key = typeof(data.field_key) !="undefined" ?  data.field_key:''  
        getParam.field_name = typeof(data.field_name) !="undefined" ?  data.field_name:''  
        getParam.field_type = typeof(data.field_type) !="undefined" ?  data.field_type:''  
        getParam.field_tip = typeof(data.field_tip) !="undefined" ?  data.field_tip:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 表单字段=>获取列表
    * @param integer form_id query false 表单ID 
    * @param string field_key query false 字段key 
    * @param string field_name query false 字段名称 
    * @param string field_type query false 字段类型;context|img|radio|checked|jude 
    * @param string field_tip query false 字段提示 
    * @param integer create_admin_id query false 记录的创建者 
    * @param integer update_admin_id query false 记录的更新者 
    * @param integer create_time_min query false 记录的创建时间开始 
    * @param integer create_time_max query false 记录的创建时间结束 
    * @param integer update_time_min query false 记录的更新时间开始 
    * @param integer update_time_max query false 记录的更新时间结束 
    * @param integer page query false 要读取第几页 
    * @param integer each_page query false 每页记录条数 
    * @param string order_field query false 排序字段；id: ID 
    * @param string order_type query false 排序方式；asc:升序,desc:降序 
    * @param string group_by query false 聚合方式 
    * @param string having query false 聚合后的筛选方式 
    *
    **/
    Iot_autoForm_fieldListGet(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOFORM_FIELDLISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoForm_fieldListGet');
        //相关参数
  
        getParam.form_id = typeof(data.form_id) !="undefined" ?  data.form_id:0  
        getParam.field_key = typeof(data.field_key) !="undefined" ?  data.field_key:''  
        getParam.field_name = typeof(data.field_name) !="undefined" ?  data.field_name:''  
        getParam.field_type = typeof(data.field_type) !="undefined" ?  data.field_type:''  
        getParam.field_tip = typeof(data.field_tip) !="undefined" ?  data.field_tip:''  
        getParam.create_admin_id = typeof(data.create_admin_id) !="undefined" ?  data.create_admin_id:0  
        getParam.update_admin_id = typeof(data.update_admin_id) !="undefined" ?  data.update_admin_id:0  
        getParam.create_time_min = typeof(data.create_time_min) !="undefined" ?  data.create_time_min:0  
        getParam.create_time_max = typeof(data.create_time_max) !="undefined" ?  data.create_time_max:0  
        getParam.update_time_min = typeof(data.update_time_min) !="undefined" ?  data.update_time_min:0  
        getParam.update_time_max = typeof(data.update_time_max) !="undefined" ?  data.update_time_max:0  
        getParam.page = typeof(data.page) !="undefined" ?  data.page:0  
        getParam.each_page = typeof(data.each_page) !="undefined" ?  data.each_page:0  
        getParam.order_field = typeof(data.order_field) !="undefined" ?  data.order_field:''  
        getParam.order_type = typeof(data.order_type) !="undefined" ?  data.order_type:''  
        getParam.group_by = typeof(data.group_by) !="undefined" ?  data.group_by:''  
        getParam.having = typeof(data.having) !="undefined" ?  data.having:''  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 表单字段=>获取表单的全部字段以及对应的值，工作室内成员有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer form_id query true 表单标识 
    *
    **/
    Teaching_studio_apiForm_fieldAllGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIFORM_FIELDALLGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiForm_fieldAllGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.form_id = data.form_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 表单字段=>删除
    * @param integer id query false noComment 
    * @param integer form_id query false 表单ID 
    * @param string field_key query false 字段key 
    * @param string field_name query false 字段名称 
    * @param string field_type query false 字段类型;context|img|radio|checked|jude 
    * @param string field_tip query false 字段提示 
    * @param integer admin_id query false 删除人ID 
    *
    **/
    Iot_autoForm_fieldDelDelete(data,bodyData){
        let url = thisReplaceUrl(IOT_AUTOFORM_FIELDDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Iot_autoForm_fieldDelDelete');
        //相关参数
  
        getParam.id = typeof(data.id) !="undefined" ?  data.id:0  
        getParam.form_id = typeof(data.form_id) !="undefined" ?  data.form_id:0  
        getParam.field_key = typeof(data.field_key) !="undefined" ?  data.field_key:''  
        getParam.field_name = typeof(data.field_name) !="undefined" ?  data.field_name:''  
        getParam.field_type = typeof(data.field_type) !="undefined" ?  data.field_type:''  
        getParam.field_tip = typeof(data.field_tip) !="undefined" ?  data.field_tip:''  
        getParam.admin_id = typeof(data.admin_id) !="undefined" ?  data.admin_id:0  
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 表单字段=>删除表单的字段，创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer id query true 记录唯一标识 
    *
    **/
    Teaching_studio_apiForm_fieldDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIFORM_FIELDDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiForm_fieldDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 表单字段的值=>添加字段对应的值，主持人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer form_id formData true 表单标识 
    * @param integer field_id formData true 字段标识 
    * @param string value formData true 值 
    * @param integer score formData true 对应的分值 
    *
    **/
    Teaching_studio_apiField_valuesAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIFIELD_VALUESADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiField_valuesAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.form_id  = data.form_id    
        postParam.field_id  = data.field_id    
        postParam.value  = data.value    
        postParam.score  = data.score    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 表单字段的值=>更新字段值的信息，创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param integer id formData true 记录唯一标识 
    * @param string value formData true 值 
    * @param integer score formData true 对应的分值 
    *
    **/
    Teaching_studio_apiField_valuesUpPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIFIELD_VALUESUPPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiField_valuesUpPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.id  = data.id    
        postParam.value  = data.value    
        postParam.score  = data.score    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 表单字段的值=>获取字段的全部值，工作室内成员有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer field_id query true 字段标识 
    *
    **/
    Teaching_studio_apiField_valuesAllGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIFIELD_VALUESALLGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiField_valuesAllGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.field_id = data.field_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 表单字段的值=>删除字段的值，创建人有此权限
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer id query true 记录唯一标识 
    *
    **/
    Teaching_studio_apiField_valuesDelDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIFIELD_VALUESDELDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiField_valuesDelDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.id = data.id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

    /**
    * 视频文件处理=>获取视频文件的封面，现在默认获取第一秒钟的截图
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string video_url formData true 视频文件的url 
    *
    **/
    Teaching_studio_apiMediaVideo_infoPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIMEDIAVIDEO_INFOPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiMediaVideo_infoPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.video_url  = data.video_url    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 财务记录=>新增工作室财务使用记录  主持人可以添加
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string computer_code formData true 前端生成的6位电脑识别码 
    * @param string salt formData true 盐值，使用一次即废弃 
    * @param number money_much formData true 金额；单位：元 
    * @param string title formData true 记录的标题 
    * @param string use_introduce formData true 使用的介绍 
    * @param integer use_time formData true 具体的时间 
    * @param integer use_user_id formData true 具体的人员 
    * @param string flow_type formData true 资金流动类型；in:入账||out:出账 
    *
    **/
    Teaching_studio_apiFinanceAddPost(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIFINANCEADDPOST)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiFinanceAddPost');
        //相关参数
  
        getParam.app_id = data.app_id    
        postParam.computer_code  = data.computer_code    
        postParam.salt  = data.salt    
        postParam.money_much  = data.money_much    
        postParam.title  = data.title    
        postParam.use_introduce  = data.use_introduce    
        postParam.use_time  = data.use_time    
        postParam.use_user_id  = data.use_user_id    
        postParam.flow_type  = data.flow_type    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
          const result = post(url,postParam,bodyData)  
        return result;
    },

    /**
    * 财务记录=>列表获取财务记录  主持人可以查看
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string flow_type query true 资金流动类型；in:入账||out:出账||all:全部 
    *
    **/
    Teaching_studio_apiFinanceListGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIFINANCELISTGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiFinanceListGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.flow_type = data.flow_type    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 财务记录=>财务的月记录  主持人可以查看
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param string year query true 哪年 
    * @param string month query true 哪月 
    * @param string flow_type query true 资金流动类型；in:入账||out:出账||all:全部 
    *
    **/
    Teaching_studio_apiFinanceMonthRecordGet(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIFINANCEMONTHRECORDGET)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiFinanceMonthRecordGet');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.year = data.year    
        getParam.month = data.month    
        getParam.flow_type = data.flow_type    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
        const result = get(url,null,bodyData) 
        return result;
    },

    /**
    * 财务记录=>删除工作室财务使用记录  主持人可以添加
    * @param string app_id query true 公众号APPID；微信环境的固定参数 
    * @param integer record_id query true 记录id 
    *
    **/
    Teaching_studio_apiFinanceRecordDelete(data,bodyData){
        let url = thisReplaceUrl(TEACHING_STUDIO_APIFINANCERECORDDELETE)
        let getParam  = new Object;
        let postParam = new Object;
        setHeaderClear('Teaching_studio_apiFinanceRecordDelete');
        //相关参数
  
        getParam.app_id = data.app_id    
        getParam.record_id = data.record_id    
        
        
        //请求服务
        let arr = Object.keys(getParam);
        if(arr.length != 0){
            let g = 0
            for(let key in getParam){
                if(g == 0){
                    url = url +"?"+key+"="+getParam[key]
                }else{
                    url = url +"&"+key+"="+getParam[key]
                }
                g++
            }
        }
         const result = delFun(url,null,bodyData) 
        return result;
    },

}
export default  teaching_studio_teaching_studio_api;