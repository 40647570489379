import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import 'amfe-flexible'
import 'amfe-flexible/index.js'
Vue.config.productionTip = false
import Vant from 'vant';
import 'vant/lib/index.css';
import './assets/font/iconfont.css'
Vue.prototype.$EventBus = new Vue();
Vue.use(Vant);
new Vue({
	router,
	store,
	data(){
		return{
			// app_id:'wxd030b2a5f17fdbe1',
			app_id:"wx0154d46e98975549",
			img_small:"?x-oss-process=style/small",
			img_middle:"?x-oss-process=style/middle",
			img_list:"?x-oss-process=style/list_display",
			userInfo:{},
			id:"",
			isLoading:false,
		}
	},
	render: h => h(App)
}).$mount('#app')
