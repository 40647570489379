<template>
	<div id="app">




		<!-- <transition name="van-slide-right"> -->
		<router-view>
		
		</router-view>

		
	
	</div>
</template>
<script>
import until from '@/api/until.js'
import studio_api from '@/api/teaching_studio_teaching_studio_api.js'
import studio_login_api from '@/api/teaching_studio_no_login.js'
import tool_api from '@/api/teaching_studio_tool.js'
import { mapState } from 'vuex';

export default {
	data() {
		return {
			
		}
	},
	computed: {
		
	},
	components: {
		
	},
	mounted() {
		if (process.env.NODE_ENV != "development") {
			// Pack_change
			let app_id = until.getParameter("app_id")
			this.$root.app_id = app_id;
		}
		let token=until.getCookie("jwt_token")
		let we = until.getParameter("we")
		let login_stuio=until.getCookie("login_stuio")
		this.$root.id=login_stuio;
		if(token){
			
		}
		else{
			if (we != 'ok') {
				setTimeout(() => {
				
					if (process.env.NODE_ENV == "development") {
						this.$router.push("/login")
					} 
					
					
				}, 1000)
			}
		}

		let guid = until.getCookie("guid")
		if (!guid) {
			guid = until.guid();
			until.setCookie("guid", guid)
		}

		this.linkSse();

	},
	methods: {
	
		
		linkSse(){
			const eventSource = new EventSource('/request/teaching_studio_api/sse/send?app_id='+this.$root.app_id);  //创建一个EventSource对象，并指向API的stream端点

				//监听事件源的消息事件
				eventSource.onmessage = (event) => {
				console.log(`Received message: ${event.data}`);  
			};

				//监听事件源的打开事件
			eventSource.onopen = (event) => {
				console.log('Connection opened');
			};

				//监听事件源的错误事件
			eventSource.onerror = (event) => {
				console.log(`Error occurred: ${event}`);
			};
		}

	
		


	},
	watch: {
		$route(to, from) {
			this.active_path = to.path;

		},

		
		
	},


}
</script>

<style>
* {
	padding: 0;
	margin: 0;
}

html,
body {
	width: 100%;
	height: 100%;
	font-family: "Microsoft YaHei", "微软雅黑", "宋体";
	overflow: hidden;
}

#app {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
* {
	padding: 0;
	margin: 0;
}

html,
body {
	width: 100%;
	height: 100%;
	font-family: "Microsoft YaHei", "微软雅黑", "宋体";
	overflow: hidden;
}



.nodata_div {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 240px;
}

.nodata_div img {
	width: 387px;
	height: 287px;
}

.nodata_div .ndata_desc {
	font-size: 24px;
	line-height: 33px;
	margin-top: 60px;
	color: #999999;
}
.ni_head {
	width: 100%;
	height: 138px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0 30px;
	box-sizing: border-box;
	overflow-x: auto;
	scrollbar-width: none;
	position: relative;
}

.nih_div {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 28px;
	color: #999999;
	line-height: 40px;
	margin-left: 48px;
	position: relative;
}

.nih_div:first-child {
	margin-left: 30px;
}

.selectnih_div {
	font-size: 36px;
	color: #333333;
	font-weight: bold;
	line-height: 50px;
}

.nih_xian {
	width: 100%;
	height: 4px;
	background: #75C969;
	border-radius: 2px;
	position: absolute;
	left: 0;
	bottom: -8px;

}

.ni_eidte {
	width: 56px;
	height: 56px;
	background: #F5F5F5;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 60px;
}

.ni_eidte .iconfont {
	font-size: 28px;
	color: #5F84FC;
}

.ni_del {
	width: 56px;
	height: 56px;
	background: #F5F5F5;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 0px;
}

.ni_del .iconfont {
	font-size: 28px;
	color: #FBA837;
}

.dialogTitleTig {
	font-size: 32px;
	font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
	font-weight: bold;
	color: #62A73A;
}

.dialogTitleTip {
	font-size: 32px;
	font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
	font-weight: bold;
	color: #EF6F38;
	width: 100%;
	text-align: center;
	margin: 0 auto;
}

.dialogTitleText {
	font-size: 0.35rem;
	color: #333333;
	margin: 1rem 0.6rem 1rem 0.6rem;
}

.tabItemT {
	height: 1.28rem !important;
	display: flex;
	align-items: center;
	justify-content: flex-start;

}

.tabItemT span {
	margin: 0 0px 0 64px;
	display: inline-block;
	box-sizing: border-box;
	height: 52px;
	line-height: 42px;
}

.scButton {
	width: 180px;
	height: 64px;
	background: #FF5C31;
	border: #FF5C31;
	border-radius: 8px;
	color: #FFFFFF;
	line-height: 37px;
	outline: none;
	font-size: 28px;
}

.returnButton {
	width: 180px;
	height: 64px;
	margin-left: 10px;
	background: #FFFFFF;
	border-radius: 8px;
	border: 1px solid #FF5C31 !important;
	font-size: 28px;
	color: #EF6F38;
	line-height: 37px;
	margin-right: 40px;
	outline: none;
}

.dialogTitleTig {
	font-size: 32px;
	font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
	font-weight: bold;
	color: #62A73A;
}

.dialogmain {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-bottom: 40px;
}

.sure_add {
	width: calc(100% - 120px);
	margin-left: 60px;
	height: 88px;
	background: #CCCCCC;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 32px;
	color: #FFFFFF;
	margin-top: 60px;
}


.addClass {
	width: 120px;
	height: 120px;
	color: #FFFFFF;
	text-align: center;
	font-size: 100px;
	line-height: 120px;
	border-radius: 50%;
	background: linear-gradient(180deg, #75C969 0%, #D9FA76 100%);
	box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.08), -4px -4px 8px 0px rgba(0, 0, 0, 0.08);
	position: fixed;
	bottom: 5%;
	right: 10%;
	z-index: 99;
}

.add_div {
	width: 120px;
	height: 120px;
	background: linear-gradient(180deg, #75C969 0%, #D9FA76 100%);
	box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.08), -4px -4px 8px 0px rgba(0, 0, 0, 0.08);
	position: fixed;
	right: 30px;
	bottom: 30px;
	border-radius: 50%;
}

.gu_main {
	background: #FFFFFF !important;
	height: calc(100vh - 130px) !important;
	width: 100%;
	border-radius: 20px 20px 0px 0px !important;
	margin-top: -20px !important;
	position: relative !important;
	display: inline-block !important;
	box-sizing: border-box;
	overflow: auto;
	position: relative;
}

.maincontent {
	background: #FFFFFF !important;
	height: calc(100vh - 130px) !important;
	width: 100%;
	border-radius: 20px 20px 0px 0px !important;
	margin-top: -20px !important;
	position: relative !important;
	display: inline-block !important;
	box-sizing: border-box;
	overflow: auto;
	position: relative;
}

/* 父级富文本 */
.quill-editor {
	display: inline-block;
	box-sizing: border-box;
	width: 100%;
}

.ql-container {
	width: 100%;
	height: auto !important;
	font-size: 18px !important;
}
.ql-editor .ql-size-small {
  font-size: 14px !important;
}
.ql-editor .ql-size-large {
  font-size: 18px !important;
}
.ql-editor .ql-size-huge {
  font-size: 32px !important;
}

.ql-editor {
	min-height: 200px !important;
}

.vue-quill-editor {
	width: 100%;
}

.mc_div {
	width: 100%;
	display: inline-block;
	margin-top: 40px;
	padding: 0 60px;
	box-sizing: border-box;
}

.mcd_title {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 26px;
	color: #666666;
}

.mcd_title span:first-child {

	margin-right: 8px;
}

.mcd_content {
	width: 100%;
	margin-top: 16px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

}

.mcd_content input {
	width: 100%;
	height: 88px;
	border: 1px solid #E6E6E6;
	background: #ffffff;
	border-radius: 8px;
	padding-left: 30px;
	box-sizing: border-box;
	font-size: 26px;
}

.mcd_content .select_div {
	width: 100%;
	height: 88px;
	border: 1px solid #E6E6E6;
	background: #ffffff;
	border-radius: 8px;
	padding-left: 30px;
	box-sizing: border-box;
	font-size: 26px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.mcd_content textarea {
	width: 100%;
	height: 200px;
	border: 1px solid #E6E6E6;
	background: #ffffff;
	border-radius: 8px;
	padding-left: 30px;
	box-sizing: border-box;
	font-size: 26px;
	padding-top: 15px;
	resize: none;
}

.code {
	font-size: 28px;
	font-family: MicrosoftYaHei;
	color: #75C969;
	line-height: 37px;
	position: absolute;
	right: 10%;
	border-left: 1px solid #E6E6E6;
	padding-left: 30px;
}

.mcdc_addimg {
	width: 145px;
	height: 145px;
	background: #FFFFFF;
	border-radius: 8px;
	border: 1px solid #E6E6E6;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mcdc_addimg img {
	height: 100%;
	width: 100%;
}


.mcdc_addimg .iconfont {
	font-size: 48px;
	color: #999999;
}

.cansure {
	background: #79CA6A;
}

.avataruploaderTip {
	position: fixed;
}

.van-ellipsis {
	font-size: 18px;
}

.changediv {
	width: 296px;
	height: 64px;
	background: #F5F5F5;
	border-radius: 43px;
	border: 1px solid #E6E6E6;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	left: 0;
	right: 0;
	margin: 0 auto;
	bottom: 30px;
	z-index: 999;
}

.changediv .iconfont {
	font-size: 28px;
	color: #333333;
}

.changediv span {
	font-size: 28px;
	color: #333333;
	margin-left: 8px;
}
.fileMess .fileName {
	font-size: 32px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 42px;
    margin-right: 24px;
}
.fileName {
	font-size: 32px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 42px;
    margin-right: 24px;
}
.fileMess{
	flex: 1;
}
.audiocicle {
	position: fixed;
	right: 0;
	bottom: 50%;
	height: auto;
	width: auto;
	z-index: 999;
}
.vjs-big-play-button {
	background-color: transparent !important;
	border-radius: 50% !important;
	height: 70px !important;
	width: 70px !important;
	margin-left: -35px !important;
}
.vjs-custom-skin>.video-js .vjs-progress-control.vjs-control {
	left: 15px !important;
}
.vjs-custom-skin {
	width: 100%;
	height: 100%;
}

.video-js.vjs-fluid,
.video-js.vjs-16-9,
.video-js.vjs-4-3 {
	width: 100%;
	max-width: 100%;
	height: 100% !important;
}

.audioBottom {

}

.fileIcon {
	height: 60px;
	width: 60px;
	float: left;
	margin-right: 14px;
	display: flex;
	align-items: center;
}
.edit_div{
	width: 80px;
	height: 80px;
	background: #F5F5F5;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	right: 10px;
	top: 600px;
}
.edit_div .iconfont{
	font-size: 36px;
	color: #999999;
}
.paddingbottom{
	padding-bottom: 120px;
	
}
.buleReturnButton{
	border: 1px solid #79CA6A !important;
	color: #79CA6A;
}
.BuleScButton{
	background: #79CA6A;
}


.mc_tips{
    width: calc(100% - 120px);
    margin-left: 60px;
    padding: 16px;
    box-sizing: border-box;
    height: 186px;
    background: #F6F6F6;
    border-radius: 8px;
    margin-top: 34px;
}
.mct_top{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.mct_iconfont{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mct_iconfont .iconfont{
    font-size: 24px;
    color: #999999;
    margin-top: 10px;
}
.mct_txt{
    font-size: 24px;
    color: #999999;
    line-height: 31px;
    text-align: left;
    margin-left: 8px;
}
.mct_bottom{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

}
.mctb_btn{
    width: 192px;
    height: 48px;
    background: #E8ECFF;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #3F5EFF;
    line-height: 31px;
}
#txt{
    position: fixed;
    top: -10000px;
}


.link_main{
	width: 480px;
	display: inline-block;
}
.linkm_title{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
}
.linkm_title .iconfont{
	font-size: 32px;
	color: #79CA6A;
	line-height: 32px;
}
.linkm_title span{
	font-size: 32px;
	color: #333333;
	font-weight: bold;
	margin-left: 15px;
}
.linkm_list{
	width: 100%;
	padding: 0 40px;
	box-sizing: border-box;
	
}
.ll_div{
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 60px;
}
.lld_img{
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.lld_img img{
	width: 100%;
	height: 100%;
}
.lld_content{
	flex: 1;
	margin-left: 24px;

}
.ldc_title{
	font-size: 28px;
	color: #333333;
	text-align: left;
	font-weight: bold;
	line-height: 37px;
}
.ldc_desc{
	width: 100%;
	text-align: left;
	font-size: 24px;
	margin-top: 8px;
}
.ldc_imgtop{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 16px;
}
.ldci_div{
	width: 140px;
	height: 120px;
}
.ldci_div img{
	width: 100%;
	height: 100%;
}
.ldci_div:last-child{
	margin-left: 16px;
}
.ldc_imgnext{
	width: 100%;
	display: inline-block;
}
.ldcinext_div{
	width: 100%;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 16px;
}
.ldcinext_div img{
	width: 100%;
	height: 100%;
}
.ldcinext_div_max{
	width: 100%;
	height: 120px;
	margin-top: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.ldcinext_div_max img{
	width: 100%;
	height: 100%;
}
.know_btn{
	width: calc(100% - 60px);
	margin-left: 30px;
	height: 88px;
	background: #FFFFFF;
	border-radius: 44px;
	border: 1px solid #79CA6A;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 32px;
	color: #79CA6A;
	line-height: 42px;
	margin-top: 60px;

}


</style>
