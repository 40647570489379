import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import( '../views/Home.vue'),
		
	},
	{
		path: '/login',
		name: 'login',
		component: () => import( '../views/Login.vue'),
		
	},
	{
		path: '/room',
		name: 'Room',
		component: () => import( '../views/Room.vue'),
		
	},
	{
		path: '/data',
		name: 'Data',
		component: () => import( '../views/Data.vue'),
		
	},
	{
		path: '/addwarning',
		name: 'AddWarning',
		component: () => import( '../views/addWarning.vue'),
		
	},
	{
		path: '/editwarning',
		name: 'EditWarning',
		component: () => import( '../views/editWarning.vue'),
		
	},
	{
		path: '/mine',
		name: 'Mine',
		component: () => import( '../views/Mine.vue'),
		
	},
	{
		path: '/edituser',
		name: 'edituser',
		component: () => import( '../views/editUser.vue'),
		
	},
	{
		path: '/userlist',
		name: 'userlist',
		component: () => import( '../views/UserList.vue'),
		
	},
	{
		path: '/usercode',
		name: 'usercode',
		component: () => import( '../views/UserCode.vue'),
		
	},
	{
		path: '/adduser',
		name: 'adduser',
		component: () => import( '../views/addUser.vue'),
		
	},
	{
		path: '/adduser',
		name: 'adduser',
		component: () => import( '../views/addUser.vue'),
		
	},
	{
		path: '/addshortselect',
		name: 'addshortselect',
		component: () => import( '../views/addShortSelect.vue'),
		
	},
	{
		path: '/swim',
		name: 'swim',
		component: () => import( '../views/Swim.vue'),
		
	},
	{
		path: '/devicelist',
		name: 'devicelist',
		component: () => import( '../views/deviceList.vue'),
		
	},


]
const router = new VueRouter({
	base: process.env.BASE_URL,
	routes
})
export default router
