import axios from 'axios'
import qs from 'qs'
import 'es6-promise'
import Vue from 'vue'

import until from './until.js'
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;


let errcodeList=until.getErrCode();

import Vant, { Toast } from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
let app_id=until.getParameter("app_id")


let app_power_sign=""
export function setHeader(methods,key,value){
    app_power_sign=value;
}
export function setHeaderClear(methods){
    app_power_sign=""
}

export function get(url, params) {
    var signature=until.getCookie("jwt_token")
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
            headers: {
                'Authorization':'Bearer '+ signature,
                'Content-Type': 'application/x-www-form-urlencoded',
                'signature':signature,
                'AppPower':app_power_sign,
            }
        }).then(res => {
          
            if(res.data.errcode==0){
                resolve(res.data)
            } 
        }).catch(err => {
          
            console.log(err)
            until.delCookie("qrtoken")
            if(err.response.status==401){
                if (process.env.NODE_ENV == "development"){
                    window.location.href="#/login"
                }else{
                    // Pack_change
                  window.location.href="/api_nologin/login/wechat_platform/get_url?app_id="+app_id
                }
            }
            else{
                let mesasageList=err.response.data
                errcodeList.map(res=>{
                    mesasageList=mesasageList.replace(res,"")
                })
                let msg="程序出错了"
                try{
                    msg=JSON.parse(mesasageList).msg
                    Toast(msg)
                }
                catch{
                    Toast("程序出错了")
                   
                }
            }
            resolve({errcode:1})
        })
    })
}

export function post(url, data) {
    var signature=until.getCookie("jwt_token")
    return new Promise((resolve, reject) => {
        axios.post(url, qs.stringify(data), {
            headers: {
                'Authorization':'Bearer '+ signature,
                'Content-Type': 'application/x-www-form-urlencoded',
                'signature':signature,
                'AppPower':app_power_sign,
            }
        }
        ).then(res => {
          
         
            if(res.data.errcode==0){
                resolve(res.data)
            }
            else{
                
            }
        }).catch(err => {
            until.delCookie("qrtoken")
            if(err.response.status==401){
                window.location.href="/api_nologin/login/wechat_platform/get_url?app_id="+app_id
                
            }
            else{
                let mesasageList=err.response.data
                errcodeList.map(res=>{
                    mesasageList=mesasageList.replace(res,"")
                })
                let msg="程序出错了"
                try{
                    msg=JSON.parse(mesasageList).msg
                    Toast(msg)
                }
                catch{
                    Toast("程序出错了")
                   
                }
            }
            resolve({errcode:1})
        })
    })
}


export function put(url, data) {
    var signature=until.getCookie("jwt_token")
    return new Promise((resolve, reject) => {
        axios.put(url, qs.stringify(data), {
            headers: {
                'Authorization':'Bearer '+ signature,
                'Content-Type': 'application/x-www-form-urlencoded',
                'signature':signature,
                'AppPower':app_power_sign,
            }
        }
        ).then(res => {
          
         
            if(res.data.errcode==0){
                resolve(res.data)
            }
            else{

            }
        }).catch(err => {
            until.delCookie("qrtoken")
            if(err.response.status==401){
                window.location.href="/api_nologin/login/wechat_platform/get_url?app_id="+app_id
            }
            else{
                let mesasageList=err.response.data
                errcodeList.map(res=>{
                    mesasageList=mesasageList.replace(res,"")
                })
                let msg="程序出错了"
                try{
                    msg=JSON.parse(mesasageList).msg
                    Toast(msg)
                }
                catch{
                    Toast("程序出错了")
                   
                }
            }
            resolve({errcode:1})
        })
    })
}
export function upload(url, data) {
    var signature=until.getCookie("jwt_token")

    return new Promise((resolve, reject) => {
        axios.post(url, data, {
            headers: {
                'Authorization':'Bearer '+ signature,
               
                'signature':signature,
                'AppPower':app_power_sign,
            }
        }
        ).then(res => {
          
         
            if(res.data.errcode==0){
                resolve(res.data)
            }
            else{
                
            }
        }).catch(err => {
            until.delCookie("qrtoken")
            if(err.response.status==401){
                window.location.href="/api_nologin/login/wechat_platform/get_url?app_id="+app_id
            }
            else{
                let mesasageList=err.response.data
                errcodeList.map(res=>{
                    mesasageList=mesasageList.replace(res,"")
                })
                let msg="程序出错了"
                try{
                    msg=JSON.parse(mesasageList).msg
                    Toast(msg)
                }
                catch{
                    Toast("程序出错了")
                   
                }
            }
            resolve({errcode:1})
        })
    })
}
export function delFun(url,params){
    var signature=until.getCookie("jwt_token")
   
   
    return new Promise((resolve, reject) => {

     
        axios.delete(url,{
			params: params,
			headers: {
                'Authorization':'Bearer '+ signature,
                'signature':signature,
			}
            }).then(res => {
           
				if(res.data.errcode==0){
					if(res.data.data==null){
						res.data.data=[]
					}
					
					resolve(res.data)
					
				}
			}).catch(err => {   
                until.delCookie("qrtoken")
                if(err.response.status==401){
                    window.location.href="/api_nologin/login/wechat_platform/get_url?app_id="+app_id
                }
                else{
                    let mesasageList=err.response.data
                    errcodeList.map(res=>{
                        mesasageList=mesasageList.replace(res,"")
                    })
                    let msg="程序出错了"
                    try{
                        msg=JSON.parse(mesasageList).msg
                        Toast(msg)
                    }
                    catch{
                        Toast("程序出错了")
                    
                    }
                }
                resolve({errcode:1})
            }
        )
    })
}

export function replaceUrl(url,type){
    if(type=="getway"){
        return 'https://gateway.kyunmao.com'+url

        // return '/next'+url
    }
    else{
        // return '/request'+url
        return url
    }
    
}
